.c-orange-bar {
    display: flex;
    flex-flow: row nowrap;
    position: absolute;
    width: 75%;
    left: -20px;
    bottom: 0;
    height: 200px;
    transform: translateY(50%);

    @include mq('medium') {
        height: 150px;
        width: 85%;
    }

    @include mq('small') {
        height: 100px;
        width: 85%;
    }

    &.flipped {
        transform: translateY(50%) scale(-1, 1);
        left: initial;
        right: -20px;
    
    }

    &.animated {
        div {
            transform: skewX(10deg) scale(1);
        }
    }
    

    div {
        height: 100%;
        border-top: 10px solid $white;
        border-right: 10px solid $white;
        background: $orange;
        transform: skewX(10deg) scale(0);
        transition: all 500ms ease;
        transform-origin: bottom left;

        &:nth-of-type(1) {
            flex-grow: 1;
        } 

        &:nth-of-type(2),
        &:nth-of-type(3) {
            flex-basis: 25%;
            margin-left: 30px;

            @include mq('small') {
                margin-left: 15px;
            }
        }  
    }
}