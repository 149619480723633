body.modal-is-active {
    overflow: hidden;

    .modal {
        opacity: 1;
        visibility: visible;
        pointer-events: initial;
    }
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border: 10px solid $orange;
    z-index: 9999999;
    background: $white;
    color: $black;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 300ms ease;

    &__submit {
        justify-content: center !important;
        margin-top: 50px !important;

        input { 
            font-weight: 600 !important;
            font-size: 1.8rem !important;
            padding: 18px 50px !important;
            max-width: 350px;
            width: 100% !important;
            text-align: center;
            border: 2px solid $orange !important;
            transition: all 300ms ease;

            &:hover {
                background: rgba($orange, 0.7) !important;
            }
        }
    }

    &__close {
        position: absolute;
        top: 80px;
        left: 0;
        right: 0;
        margin: 0 auto;
        width: 40px;
        height: 40px;
        background: $orange;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        z-index: 99999;
        cursor: pointer;
        border: 2px solid $orange;
        transition: all 300ms ease;

        &:hover {
            background: rgba($orange, 0.7);
        } 

        &:before,
        &:after {
            content: '';
            position: absolute;
            width: 20px;
            height: 2px;
            background: $white;
        }

        &:before {
            transform: rotate(45deg);
        }

        &:after {
            transform: rotate(-45deg);
        }
    }

    &__scroll {
        position: absolute;
        bottom: 5rem;
        left: 0;
        right: 0;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        color: $black;

        img {
            filter: invert(1);
            margin-bottom: 10px;
        }
    }

    &__form {
        padding-top: 75px;
    }

    &__content {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        overflow-y: auto;

        &-intro {
            display: flex;
            flex-flow: column wrap;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 5rem;
            position: relative;

            .subtitle {
                color: $orange;
                font-size: 2.5rem;
                line-height: 3rem;
            }

            h2 {
                text-align: center;
            }

            p {
                text-align: center;
                margin-bottom: 0;
            }
        }


        form {
            max-width: 1200px;
            margin: 0 auto;
            padding-bottom: 10rem;

            h2 {
                text-transform: none;
            }

            p {
                display: none;
            }

            .form__row + h2 {
                margin-top: 100px;
                margin-bottom: 50px;
            }

            .bottom-spacer {
                margin-bottom: 50px;
            }
        }
    }
}

.modal-close-bottom {
    display: block;
    width: 100%;
    text-align: center;
    color: $grey;
    font-weight: 600;
    font-size: 1.8rem;
    margin-top: 30px;
    cursor: pointer;
}