.soumission__banner {
    height: 650px;
    background-color: $black;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    .container {
        height: 100%;

        h1 {
            text-align: center;
        }

        p {
            max-width: 750px;
            text-align: center;
        }

        .pre-title {
            display: block;
            font-size: 2.4rem;
            line-height: 3rem;
            font-weight: 700;
            margin-bottom: 20px;
            color: $grey;
            text-align: center;

            @include mq(medium) {
                font-size: 2rem;
                line-height: 2.5rem;
            }
        }

        button {
            position: absolute;
            bottom: 6rem;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 40px;
            height: 40px;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;
            outline: 0;
            cursor: pointer;
            background: transparent;
            border: 0;
        }
    }
}

.soumission__form {
    background: white;
    color: $black;
    padding: 10rem 0;
}