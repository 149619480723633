.c-contact {
  .c-contact_hero {
    padding: 15rem 15rem;
    position: relative;

    @media (max-width: 900px) {
      padding: 15rem 0rem;
    }

    @media (max-width: 768px) {
      padding: 15rem 2rem 10rem;
    }

    h1 {
      padding-bottom: 3rem;
      width: 80%;

      @media (max-width: 768px) {
        width: 100%;
      }
    }

    .c-contact_description {
      p {
        font-weight: 400;
        font-size: 1.7rem;
      }
    }

    .c-contact_media {
      position: absolute;
      bottom: 0;
      right: 50px;
      height: 80%;
      z-index: -1;

      @media (max-width: 1300px) {
        display: none;
      }

      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
        opacity: 0.5;

        @media (max-width: 1300px) {
          width: 75%;
        }
      }
    }
  }
}
