.services {
    background: $white;

    &--black {
        background: $black;

        .col_1x2 {

            &:last-of-type {
                width: 100%;
            }
        }
    }

    &--puzzle {

        .services__list {
            padding-right: 10rem;

            @include mq('large') {
                padding-right: 0;
            }
        }

        .services__list {

            .col_1x2 {

                &:nth-child(2) {
                    margin-right: -10rem;
                    width: calc(50% + 10rem);

                    @include mq('large') {
                        margin-right: 0;
                        width: 50%;
                    }

                    @include mq('medium') {
                        width: 100%;
                    }
                }

                &:nth-of-type(2) {
                    margin-top: 100px;

                    @include mq('large') {
                        margin-top: 0;
                    }
                }

                &:nth-of-type(odd):not(:first-child) {
                    margin-top: -100px;
                    
                    @include mq('large') {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .content {

        @include mq('medium') {
            padding: 10rem 0;
        }
    }

    h2 {
        color: $black;

        &.has-orange-separator {

            &:before {
                border-color: $white;
                bottom: 20px;
            }
        }
    }

    h4 {
        color: $grey-dark;
        max-width: 450px;
    }

    p {
        color: $grey-dark;
    }

    .col_1x2 {
        width: 50%;

        @include mq('medium') {
            width: 100%;
        }

        &.ju-center {

            @include mq('medium') {
                justify-content: flex-start;
            }
        }

        &.pushed-down {
            @include mq('medium') {
                margin-top: 0;
            }
        }

    }

    &__list {
        display: flex;
        flex-flow: row wrap;

        .col_1x2 {
            width: 50%;

            @include mq('medium') {
                width: 100%;
            }
        }
    }

    &__item {
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        height: 450px;
        position: relative;
        overflow: hidden;
        cursor: pointer;
        padding: 30px;
        h2{
            color: white;
        }
        &:hover {

            &:after {
                transform: translateY(0);
            }

            .c-button--transparent {
                &:after {
                    width: 100%;
                }
            }

            i {
                color: $orange;
            }
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: radial-gradient(ellipse at center, rgba($black, .7) 0%,rgba($black, .9) 100%);
            border: 1px solid rgba($grey, 0.4);
        }

        &:after {
            content: '';
            position: absolute;
            right: 0;
            bottom: 0;
            left: 0;
            height: 10px;
            z-index: 9999;
            background: $orange;
            transition: all 300ms ease;
            transform: translateY(10px);
        }

        & > * {
            position: relative;
            z-index: 20;
        }

        h3 {
            color: $white;
            margin-bottom: 0;
            text-align: center;
            text-transform: initial;
        }

    }

    &__subtitle {
        color: rgba($white, 0.5) !important;
        font-weight: 600 !important;
        margin-bottom: 0;
        font-size: 1.8rem;
        line-height: 2.2rem;
    }
}