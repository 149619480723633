.c-info {
	padding: 15rem 20rem 0;
	background-color: $white;

	@media (max-width: 1300px) {
		padding: 15rem 10rem 0;
	}

	@media (max-width: 768px) {
		padding: 10rem 2rem 0;
	}

	.c-info_title {
		position: relative;
		margin-bottom: 80px;
		color: $black;

		&::before {
			content: "";
			position: absolute;
			top: -48px;
			left: 0;
			width: 50px;
			height: 10px;
			background-image: url("../images/line.svg");
			background-repeat: no-repeat;
			background-position: center;
			background-size: contain;
		}
	}

	.c-info_content {
		display: flex;
		background-color: $black;

		@media (max-width: 768px) {
			flex-direction: column;
		}

		.c-info_media {
			width: 45%;

			@media (max-width: 768px) {
				width: 100%;
			}
		}

		.c-info_information {
			width: 55%;

			@media (max-width: 768px) {
				width: 100%;
			}
		}

		.c-info_information {
			display: flex;
			gap: 40px;
			padding: 48px 40px;

			@media (max-width: 1355px) and (min-width: 1300px) {
				padding: 48px 25px;
			}

			@media (max-width: 1045px) {
				flex-direction: column;
				padding: 4rem 1.5rem;
			}

			@media (max-width: 768px) {
				padding: 4rem;
			}

			h4 {
				font-size: 15px;
			}

			.c-info_address,
			.c-info_contact {
				width: 50%;

				@media (max-width: 1000px) {
					width: 100%;
				}
			}

			.c-info_contact {
				display: flex;
				flex-direction: column;
				gap: 4rem;

				h4 {
					margin-bottom: 10px;
				}
			}

			.c-info_address {
				display: flex;
				flex-direction: column;

				h4 {
					margin-bottom: 10px;
				}

				div {
					padding-bottom: 4rem;
					font-weight: 400;
					line-height: 2;
				}

				a {
					color: $orange;
					text-decoration: underline;
					font-size: 15px;
					font-weight: 400;
				}
			}

			.c-info_contact {
				div {
					a {
						color: #fff;
						font-weight: 400;
					}
				}

				.c-info_socials {
					display: flex;
					gap: 20px;
				}
			}
		}

		.c-info_media {
			display: flex;
			align-items: center;
			justify-content: center;
            padding: 72px;
			background-color: #f5f5f5;
		}
	}
}
