.page-content {
    margin-left: 110px;
    height: 100%;
    position: relative;

    @include mq('xlarge') {
        margin-left: 0;
    }
}

.page-header {
    position: fixed;
    top: 0;
    left: 110px;
    right: 0;
    height: 90px;
    z-index: 999999;
    padding: 20px 30px;
    transition: all 300ms ease;

    @include mq('xlarge') {
        left: 0;
    }

    @include mq(small) {
        padding: 20px;
    }

    &.has-background {
        background: rgba($black, 1);
        border-bottom: 1px solid rgba($grey, 1);
    }

    &.no-background {
        background: rgba($black, 0);
        border-bottom: 1px solid rgba($grey, 0);        
    }

    .c-button {
        
        @include mq('xlarge') {
            display: none;
        }
    }

    &__logo {
        img{
            max-width: 120px;
        }
        @include mq(xlarge) {
            text-align: center;
        }

        img {
            @include mq(xsmall) {
                width: 90px;
            }
        }
    }
}

.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 30px;
    position: relative;


    &--left {
        margin-left: 0;
    }
}

.wrapper {
    padding-top: 20rem;
    padding-bottom: 20rem;

    @include mq('large') {
        padding-top: 15rem;
        padding-bottom: 15rem;
    }

    @include mq('medium') {
        padding-top: 10rem;
        padding-bottom: 10rem;
    }

    @include mq('small') {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
    
}

.content {
    padding: 10rem;
    position: relative;

    @include mq('large') {
        padding: 5rem 8rem;
    }

    @include mq('medium') {
        padding: 5rem;
    }

    @include mq('small') {
        padding: 3rem 0;
    }

    &.limited-big > * {
        max-width: 750px;
    }

    &.limited > * {
        max-width: 550px;
    } 

    &.half {
        padding: 10rem 5rem;

        @include mq(medium) {
            padding: 5rem 0;
        }
    }
}

.pushed-down {
    margin-top: 100px;
}

.orange-sidebar {
    position: relative;

    &:after {
        content: '';
        position: absolute;
        left: calc(100% - 30px);
        width: 2500px;
        top: 0;
        bottom: 0;
        background: $orange;

        @include mq(large) {
            display: none;
        }
    }

    &.pull-top {

        &:after {
            top: -200px;
        }
    }

    &.pull-top {

        &:after {
            bottom: -100px;
        }
    }
}
.embed-responsive {
    position: relative;
    display: block;
    width: 100%;
    padding: 0;
    overflow: hidden;
    &:before{
        padding-top: 56.25%;
        display: block;
        content: "";
    }
    &-item{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}