.contact-info {
    display: flex;
    flex-flow: row wrap;
    margin-top: 20px;

    @include mq(medium) {
        width: 100%;
    }

    &__col {
        display: flex;
        flex-flow: column wrap;
        min-width: 200px;
        margin-top: 30px;

        @include mq(small) {
            min-width: 100%;
        }
        
        &:first-of-type {
            margin-right: 40px;
        }

        a {
            margin-bottom: 20px;
            color: $white;

            &.contact-address {
                color: $orange;
                text-decoration: underline;
            }

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        strong {
            margin-bottom: 10px;
        }

        p {
            font-size: 15px;
            line-height: 22px;
            margin: 0 0 10px;
        }
    }
}

.contact {
    background: $black;
    color: $white;

    h2 {
        margin-top: 100px;
    }
    
    .wrapper {
        padding: 10rem 0 5rem;
    }

    .content {
        padding-bottom: 0;
    }

    &__row {
        display: flex;
        flex-flow: row wrap;
    }

    &__col {
        width: 50%;

        @include mq(large) {
            width: 100%;
        }

        &:first-of-type {
            padding: 15rem 10rem;

            @include mq(xlarge) {
                padding: 10rem 5rem;
            }

            @include mq(large) {
                padding: 10rem 0rem;
            }
        }

        #map {
            height: 100%;
            width: 100%;

            @include mq(large) {
                height: 60vw;
            }

            @include mq(small) {
                height: 100vw;
            }

            
            &:before {
                content:'';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                height: 50%;
                z-index: 9999;
                background: linear-gradient(to bottom, rgba(9,12,16,1) 0%,rgba(9,12,16,1) 5%,rgba(9,12,16,0) 100%);
                pointer-events: none;
            }
        }
    }
}