.c-soumission {
  .c-soumission_hero {
    padding: 15rem;
    position: relative;

    @media (max-width: 900px) {
      padding: 15rem 0rem;
    }

    @media (max-width: 768px) {
      padding: 15rem 2rem 10rem;
    }

    h1 {
      margin: 0 auto;
      width: 70%;
      text-align: center;

      @media (max-width: 768px) {
        width: 100%;
      }
    }
  }

  .c-soumission_media {
    position: absolute;
    inset: 0;
    z-index: -1;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 0.5;
    }
  }

  .c-soumission_description {
    padding: 10rem 15rem;

    p {
      font-weight: 400;
      font-size: 1.7rem;
    }

    @media (max-width: 768px) {
      padding: 10rem 2rem;
    }
  }
}
