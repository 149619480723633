.fpn {
	position: fixed;
	left: 110px;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 9999999;
	background-color: $black;
	display: flex;
	flex-flow: column nowrap;
	opacity: 0;
	visibility: hidden;
	transition: all 500ms ease;

	@include mq(xlarge) {
		left: 0;
		top: 90px;
	}

	&.active {
		opacity: 1;
		visibility: visible;
	}

	&:before {
		content: "";
		background-image: url(../images/soil-dark-back.png);
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 5;
		opacity: 0.4;
	}

	&__top-row {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: center;
		padding: 20px 30px;
		height: 90px;
		width: 100%;
		position: relative;
		z-index: 6;

		@include mq(xlarge) {
			display: none;
		}

		.fpn-logo {
			width: 120px;
		}

		.fpn-cta {
			display: flex;
			align-items: center;
		}

		.fpn-email {
			display: flex;
			flex-flow: row wrap;
			justify-content: center;

			@include mq(medium) {
				display: none;
			}

			span {
				font-weight: 700;
				margin-right: 20px;
				font-size: 16px;
			}

			a {
				font-size: 16px;
				color: $white;
				transition: all 300ms ease;

				&:hover {
					color: $orange;
				}
			}
		}

		.fpn-phone {
			position: relative;
			margin-right: 50px;
			margin-top: -1px;

			span {
				position: absolute;
				top: 0;
				right: 0;
				transform: translateY(-100%);
				color: $grey;
			}

			a {
				font-weight: 600;
				color: $white;
			}
		}
	}

	&__bottom-row {
		width: 100%;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		position: relative;
		z-index: 6;

		@include mq(medium) {
			flex-flow: column wrap;
			align-items: flex-start;
		}

		.fpn__wrapper {
			width: 100%;

			&.desktop-fpn {
				display: flex;
				flex-flow: row nowrap;
				padding: 40px 100px;

				@media (max-width: 1440px) {
					padding: 40px 60px;
				}

				@include mq(large) {
					padding: 40px;
				}

				@include mq(medium) {
					display: flex;
				}

				@include mq(medium) {
					display: none;
				}
			}

			&.mobile-fpn {
				display: none;
				flex-flow: column nowrap;
				justify-content: flex-start;
				align-items: flex-start;
				height: 100%;
				overflow: auto;

				@include mq(medium) {
					display: flex;
				}

				.fpn-main-menu {
					display: flex;
					flex-flow: column nowrap;
					align-items: flex-start;
					padding: 80px 30px 30px;
					width: 100%;

					@include mq(small) {
						padding: 20px 30px;
					}
				}

				.c-button {
					margin-top: auto;
					width: 100%;
					text-align: center;
					padding: 20px;
				}
			}

			ul {
				list-style-type: none;
				padding: 0;
				margin: 0;

				&.fpn-main-menu {
					padding-right: 80px;

					li {
						margin: 0 0 20px;

						@include mq(small) {
							margin: 0 0 10px;
						}

						&:first-of-type {
							display: flex;
							flex-flow: row nowrap;
							align-items: center;

							@include mq(medium) {
								flex-flow: column nowrap;
								align-items: flex-start;
							}

							i {
								@include mq(medium) {
									display: none;
								}
							}
						}

						a {
							color: $grey;
							font-size: 5rem;
							line-height: 5.5rem;
							font-weight: 700;
							font-family: $main-font;
							transition: all 300ms ease;

							@media (max-width: 1340px) {
								font-size: 4rem;
								line-height: 4.5rem;
							}

							@include mq(large) {
								font-size: 3rem;
								line-height: 3.5rem;
							}

							@include mq(medium) {
								color: white;
							}

							@include mq(small) {
								font-size: 2rem;
								line-height: 2.5rem;
							}

							&:hover {
								color: $white;
							}
						}
					}
				}

				&.fpn-services-list {
					padding-top: 0;

					li {
						margin: 0 0 20px;

						@include mq(xlarge) {
							margin: 0 0 10px;
						}

						@include mq(medium) {
							width: 100%;
						}

						&:last-of-type {
							@include mq(medium) {
								margin-bottom: 0px;
							}
						}

						a {
							color: $white;
							font-size: 30px;
							line-height: 35px;
							font-weight: 700;
							font-family: $main-font;

							@media (max-width: 1340px) {
								font-size: 25px;
								line-height: 30px;
							}

							@include mq(large) {
								font-size: 20px;
							}

							@include mq(medium) {
								font-size: 16px;
								line-height: 22px;
							}

							i {
								color: $orange;
								font-size: 15px;
								transition: all 300ms ease;
							}

							&:hover {
								i {
									transform: translateX(10px);
								}
							}
						}
					}
				}
			}

			.fpn-quick-links {
				min-width: 175px;

				&--mobile {
					@extend .fpn-quick-links;
					display: none;
					flex-flow: column nowrap;
					align-items: flex-start;
					padding: 0px 30px;
					width: 100%;

					@include mq(medium) {
						display: flex;
					}

					h3,
					span.h3 {
						font-size: 25px;
						line-height: 30px;
						font-weight: bold;
						display: block;
						margin-bottom: 10px;

						@include mq(small) {
							font-size: 18px;
							line-height: 24px;
						}
					}
				}

				ul {
					@include mq(medium) {
						display: flex;
						flex-flow: row wrap;
					}
					li {
						margin: 0 0 10px;

						@include mq(medium) {
							margin: 0 30px 20px 0px;
						}

						@include mq(small) {
							margin: 0 20px 10px 0px;
						}

						a {
							color: $white;
							font-size: 20px;
							line-height: 25px;
							font-weight: 600;
							font-family: $main-font;

							@include mq(medium) {
								font-size: 16px;
								line-height: 22px;
							}

							i {
								color: $orange;
								font-size: 12px;
								transition: all 300ms ease;

								@include mq(medium) {
									display: none;
								}
							}

							&:hover {
								i {
									transform: translateX(10px);
								}
							}
						}
					}
				}
			}

			.fpn-email--mobile {
				padding: 10px 30px;

				span {
					font-size: 16px;
					line-height: 22px;
					font-weight: 700;
					margin-right: 20px;
				}

				a {
					color: $orange;
				}
			}

			.fpn-address {
				margin-top: 50px;

				h4 {
					&:not(:first-of-type) {
						margin-top: 40px;
					}
				}

				&--mobile {
					display: flex;
					flex-flow: row wrap;
					align-items: center;
					padding: 10px 30px;

					@include mq(small) {
						display: none;
					}

					span {
						font-size: 16px;
						line-height: 22px;
						font-weight: 700;
						margin-right: 20px;
					}

					a {
						color: $white;
						text-decoration: underline;
					}
				}

				p {
					font-size: 16px;
					line-height: 22px;
					margin-bottom: 10px;
				}

				a {
					color: $orange;
					text-decoration: underline;
				}
			}

			h3,
			.h3 {
				font-size: 30px;
				line-height: 35px;
				font-weight: bold;
				display: block;
				margin-bottom: 10px;
			}

			h4 {
				font-size: 20px;
				line-height: 25px;
				margin-bottom: 10px;
			}
		}

		.fpn-col {
			flex-grow: 1;

			&.fpn-services-sub-menu {
				padding-right: 80px;
			}
		}
	}
}
