.equipements {
    background: $white;
    color: $black;

    .has-orange-separator {

        &:before {
            border-color: $white !important;
        }
    }

    &__list {
        display: flex;
        flex-flow: row wrap;
        margin-right: -20px;
        width: 100%;
        

        &-item {
            width: calc(33.333% - 10px);
            margin-bottom: 30px;
            margin-right: 10px;
            

            @include mq(large) {
                width: calc(50% - 10px);
            }

            @include mq(small) {
                width: 100%;
                margin-right: 0;
            }

            .equipement-image {
                height: 20vw;
                max-height: 450px;
                background-size: cover;
                background-repeat: no-repeat;
                background-position: center;
                margin-bottom: 10px;

                @include mq(large) {
                   height: 30vw;
                }

                @include mq(small) {
                   height: 50vw;
                }
            }

            h3 {
                font-size: 25px;
                line-height: 30px;
                margin-bottom: 0;

                @include mq(large) {
                    font-size: 20px;
                    line-height: 25px;
                }
            }

            .type {
                font-weight: 700;
                font-size: 1.6rem;
                line-height: 2.2rem;
                color: $grey;
            }
        }
    }
}