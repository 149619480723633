.blogue {
    background: $white;
    padding-bottom: 10rem;

    .wrapper {
        padding: 10rem 0 5rem;
    }

    .content {
        padding-bottom: 0;

        &.flex--row.ju-sb {

            @media (max-width: 768px) {
                flex-flow: column wrap;
                justify-content: flex-start;
            }
        }

        .c-button--skewed-2 {
            @media (max-width: 768px) {
            }
        }
    }

    h3.h2 {
        text-transform: uppercase;
    }

    .h2,
    h4 {
        color: $black;

        &.has-orange-separator {

            &:before {
                border-color: $white;
            }
        }
    }

    &__listing {
        display: flex;
        flex-flow: row wrap;
        margin-right: -20px;

        @include mq('medium') {
            margin-right: 0;
        }
    }

    &__item {
        width: calc(33.333% - 20px);
        margin-right: 20px;
        margin-bottom: 40px;

        @include mq('medium') {
            width: 100%;
            margin-right: 0;
        }

        & > a {
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;
            min-height: 300px;
            position: relative;

            @include mq('large') {
                min-height: initial;
                height: 25vw;
            }

            @include mq('medium') {
                height: 40vw;
            }

            &:hover {

                &:before {
                    background: rgba($black, 0.5); 
                }

                span {
                    transform: translateY(0);
                    opacity: 1;
                    visibility: visible;
                }
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                background: rgba($black, 0);
                transition: all 200ms ease;
            }

            span {
                position: relative;
                z-index: 20;
                color: $white;
                font-size: 1.8rem;
                line-height: 2.4rem;
                transform: translateY(20px);
                transition: all 200ms ease;
                opacity: 0;
                visibility: hidden;

                i {
                    color: $orange;
                    margin-left: 5px;
                    font-size: 14px;
                }
            }
        }

    }

    h3:not(.h2) {
        font-size: 2.2rem;
        margin-top: 20px;
        margin-bottom: 10px;

        @include mq('large') {
            font-size: 1.8rem;
        }

        &:hover {

            a {
                color: $orange;
            }
        }

        a {
            color: $black;
            transition: all 200ms ease;
        }
    }

    .date {
        color: $black;
        font-size: 1.8rem;
        line-height: 2.4rem;
    }
}