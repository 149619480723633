.careers {
    background: $white;
    color: $black;

    .wrapper {
        padding-top: 10rem;
        padding-bottom: 10rem;
    }

    .content {
        @include mq(medium) {
            padding: 0;
        }
    }
}

.career {
    

    &__cta {
        margin-top: 50px;
        display: flex;
        flex-flow: column wrap;
        align-items: center;

        a {
            width: 100%;
        }
    }

    &__submit {
        background: $orange;
        color: $white;
        text-align: center;
        padding: 20px 30px;
        max-width: 400px;

        i {
            font-size: 12px;
            margin-left: 10px;
        }
    }

    &__close {
        color: $black;
        text-align: center;
        margin-top: 20px;
    }
}