.skewed-images {
    display: flex;
    flex-flow: row wrap;

    h3 {
        font-size: 4rem;
        line-height: 4.5rem;
        margin-bottom: 0px;

        @include mq(large) {
            font-size: 3rem;
            line-height: 3.5rem;
        }
    }

    .job-title {
        color: $orange;
    }

    .skewed-image-container {
        width: 50%;
        margin-bottom: 120px;

        @include mq(large) {
            width: 100%;
            margin-bottom: 30px;
            max-width: 600px;
            margin-left: auto;
            margin-right: auto;
        }

        .skewed-image {
            height: 40vw;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: top center;
            transform: skewY(-10deg);
            margin-bottom: 60px;

            @include mq(large) {
                height: 70vw;
                transform: skewY(-5deg);                
            }

            @include mq(small) {
                height: 100vw;              
            }
        }

        .skewed-content {
            padding-right: 80px;

            @include mq(large) {
                padding-right: 0;
            }
        }

        &:nth-child(even) {
            margin-top: 80px;
            margin-left: -1px;

            @include mq(large) {
                margin-left: auto;
            }
        }
    }
}