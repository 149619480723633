.single-article {

    img {
        transform: scale(1.2);
        margin: 80px 0;
    }

    a {
        color: $orange;
    }

    ul, ol {
        
        li {
            font-size: 20px;
            line-height: 28px;
        }
    }
}

.blogue.single-article-blog {

    .content {
        padding-top: 0;
    }

    .wrapper {
        padding-top: 0 !important;
    }
}