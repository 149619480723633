.c-button {
    padding: 12px 30px;
    color: white;
    position: relative;
    font-size: 1.5rem;
    cursor: pointer;
}

.c-button--style-1 {
    @extend .c-button;
    border: 1px solid $orange;
    background: rgba($orange, 0.4);

    &:before {
        content:'';
        position: absolute;
        bottom: 0;
        left: 30px;
        right: 30px;
        height: 3px;
        background: $orange;
        z-index: -1;
        
    }

    &.animation-in {

        &:before {
            animation: style-1-in 400ms forwards ease;        
        }
    }

    &.animation-out {

        &:before {
            animation: style-1-out 400ms forwards ease;    
        }        
    }

}

.c-button--style-2 {
    @extend .c-button;
    border: 1px solid $orange;
    background: $orange;
    font-weight: 400;
    display: inline-flex;

    &:before {
        content: url('../images/heart.svg');
        margin-right: 10px;
        width: 20px;
        height: 20px;
        padding-top: 2px;
    }
}

.c-button--skewed {
    @extend .c-button;
    border: 1px solid $orange;
    background: rgba($orange, 0.4);
    transform: skewX(-10deg);
    position: relative;
    display: inline-block;

    &:before {
        content:'';
        position: absolute;
        top: -1px;
        bottom: -1px;
        right: 0;
        width: 0;
        background: $orange;
        z-index: -1;
        transition: all 300ms ease;
    }

    &:after {
        content:'+';
        font-size: 2rem;
        position: absolute;
        top: -1px;
        bottom: -1px;
        right: -50px;
        width: 50px;
        background: $orange;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        z-index: -1;
        border-right: 1px solid $orange;        
    }

    span {
        display: inline-block;
        transform: skewX(10deg);  
    }

    &:hover {

        &:before {
            width: 100%;
        }
    }
    
}

.c-button--skewed-2 {
    @extend .c-button;
    transform: skewX(-10deg);
    position: relative;
    display: inline-block;
    color: $black;
    transition: all 200ms ease;

    @include mq(medium) {
        color: $white;
    }

    &:before {
        content:'';
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        width: 0;
        background: $orange;
        z-index: -1;
        transition: all 200ms ease;

        @include mq(medium) {
            width: 100%;
        }
    }

    &:after {
        content: "\f009";
        font-family: "Font Awesome 5 Free";
        font-weight: 600;
        color: $white;
        font-size: 14px;
        position: absolute;
        top: 0;
        bottom: 0;
        right: -50px;
        width: 50px;
        background: $orange;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        z-index: -1;
        border-right: 1px solid $orange;
        
    }

    span {
        display: inline-block;
        transform: skewX(10deg);  
    }

    &:hover {
        color: $white;

        &:before {
            width: 100%;
        }
    }
}

.c-button--skewed-3 {
    @extend .c-button;
    border: 1px solid $orange;
    background-color: $orange ;
    transform: skewX(-25deg);
    position: relative;
    display: inline-block;
    padding: 15px 170px;
    transition: 200ms;
    font-family: 'futura-pt';

    @media (max-width: 768px) {
        padding: 12px 0;
    }

    &:before {
        content:'';
        position: absolute;
        top: -1px;
        bottom: -1px;
        right: 0;
        width: 0;
        background: $orange;
        z-index: -1;
        transition: all 300ms ease;
    }

    &:after {
        content:'+';
        font-size: 2rem;
        position: absolute;
        top: -1px;
        bottom: -1px;
        right: -50px;
        width: 50px;
        background: $orange;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        z-index: -1;
        border-right: 1px solid $orange;        
    }

    span {
        display: inline-block;
        transform: skewX(10deg);  
    }

    &:hover {
        background-color: #fff;
        border-color: 1px solid $orange;
        color: $orange;

        &:before {
            width: 100%;
        }
    }
}

.c-button--transparent {
    @extend .c-button;
    position: relative;
    padding: 8px 0;

    &:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        border-bottom: 3px solid $orange;
        width: 15px;
        transition: all 300ms ease;
    }

    i {
        font-size: 12px;
        margin-left: 5px;
        transition: all 300ms ease;        
    }

    &:hover {

        &:after {
            width: 100%;
        }

        i {
            color: $orange;
        }
    }
}

.c-button--filter {
    border: 1px solid $grey;
    display: none;
    flex-flow: row wrap;
    align-items: center;

    .filter-icon {
        display: block;
        width: 12px;
        height: 6px;
        border-top: 2px solid $orange;
        border-bottom: 2px solid $orange;
        margin-left: 10px;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: -3px;
            left: 3px;
            height: 8px;
            border-right: 2px solid #090c10;
        }
    }
}

@keyframes style-1-in {

    0% {
        left: 30px;
        right: 30px;
        height: 3px;
    }

    50% {
        left: 0px;
        right: 0px;
        height: 3px;
    }

    100% {
        left: 0px;
        right: 0px;
        height: 100%;
    }
}

@keyframes style-1-out {

    0% {
        left: 0px;
        right: 0px;
        height: 100%;
    }

    50% {
        left: 0px;
        right: 0px;
        height: 3px;
    }

    100% {
        left: 30px;
        right: 30px;
        height: 3px;
    }
}