.carousel {
    
    &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 150px;
        background: $white;
        z-index: -1;
    }

    .container {

        @include mq('medium') {
            padding-left: 0;
            padding-right: 0;
        }

        .content {

            @include mq('medium') {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .content {
        padding-bottom: 0;
    }

    &-slide {
        height: 40vw;
        background-size: cover;
        background-repeat: no-repeat;

        @include mq('medium') {
            height: 500px;
        }

        @include mq('small') {
            height: 380px;
        }
    }

    &__dots {
        margin-bottom: 30px;

        ul {
            display: flex;
            flex-flow: row wrap;
            list-style-type: none;
            padding: 0;
            margin: 0;

            @include mq('medium') {
               justify-content: center;
            }

            li {
                margin-right: 8px;

                button {
                    appearance: none;
                    border: 0;
                    background-color: transparent;
                    border-bottom: 4px solid $grey;
                    width: 60px;
                    text-align: left;
                    padding: 0;
                    color: $grey;
                    font-family: $main-font;
                    font-weight: 700;
                    font-size: 18px;
                    padding-bottom: 5px;
                    outline: 0;
                    transition: all 300ms ease;
                    cursor: pointer;

                    &:before {
                        content: '0';
                    }
                }

                &.slick-active {
                    button {
                        border-bottom: 4px solid $orange;
                        color: $white;
                    }
                }
            }
        }
    }
}

.five-lines {
    width: 200px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: -100px;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    opacity: 0.7;

    @include mq('large') {
        display: none;
    }

    div {
        height: 1px;
        border-bottom: 1px solid $grey;
        margin: 20px 0;
    }
}

.testimonial-slider {
    padding-left: 200px;
    margin-top: 10rem;

    @include mq(large) {
        padding-left: 120px;
    }

    @include mq(medium) {
        padding-left: 0;
        margin-top: 5rem;
    }

    h4 {
        font-size: 3rem;
        line-height: 3.5rem;
        margin-bottom: 0;
    }

    .city {
        color: $orange;
        font-weight: 700;
        font-size: 1.8rem;
        display: block;
        margin-bottom: 20px;
    }

    .testimonial-index {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        width: 30px;
        display: flex;
        flex-flow: column wrap;

        @include mq(large) {
            display: none;
        }
    }

    .testimonial-numbers {
        width: 100%;
    }

    .testimonial-number {
        text-align: center;
        font-weight: bold;
    }

    .testimonial-total {
       width: 100%;
       border-top: 2px solid $black;
       margin-top: 10px;
       padding-top: 10px;
       font-weight: bold;
       text-align: center;
    }

    .testimonial-arrows {
        display: flex;
        flex-flow: row wrap;
        align-items: center;

        i {
            color: $orange;
            margin-right: 20px;
            font-size: 12px;
            cursor: pointer;
        }
    }
}