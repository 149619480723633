.banner-levels {
    height: 650px;
    padding-top: 150px;
    padding-bottom: 50px;
    position: fixed;
    top: 0;
    left: 110px;
    right: 0;
    bottom: 0;
    z-index: 2;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    @include mq(xlarge) {
        left: 0;
    }

    @include mq(medium) {
        padding-top: 150px;
        height: 400px;
    }

    &--contact {
        @include mq(medium) {
            height: 600px;
        }

        .scrollTo {
            @include mq(medium) {
                display: none;
            }
        }
    }

    &.single-projet-banner {

        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba($black, 0.8);
        }

        .banner-levels__row {
            padding-bottom: 50px;

            .date {
                display: inline-block;
                margin-bottom: 5px;
                font-weight: bold;
                font-size: 1.6rem;
                position: relative;
                text-transform: capitalize;

                @include mq(medium) {
                    margin-top: 30px;
                }

                &:before {
                    content: '';
                    position: absolute;
                    top: -10px;
                    left: 0;
                    width: 30px;
                    height: 1px;
                    border-top: 3px solid $orange;
                }
            }
        }
    }

    .container {
        height: 100%;
    }

    &__wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-flow: column wrap;

        button {
            background: transparent;
            border: 0;
            appearance: none;
            align-self: flex-start;
            outline: 0;
            cursor: pointer;
        }

    }

    &__row {
        flex-grow: 1;
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 100px;

        @include mq(medium) {
            flex-flow: column wrap;
            justify-content: center;
            align-items: flex-start;
            padding-bottom: 50px;
        }

        h1 {
            margin-bottom: 0;

            @include mq(medium) {
                margin-bottom: 20px;
            }
        }

        .c-button {
            margin-bottom: 20px;

            @include mq(xlarge) {
                margin-bottom: 5px;
            }
        }
    }

    &__spacer {
        position: relative;
        background: $black;
        z-index: 3;
        margin-top: 650px;

        @include mq(medium) {
            margin-top: 400px;
        }

        &.white-layout {
            background: $white;
            color: $black;

            .wrapper {
                padding: 10rem 0;
            }
        }

        &--contact {

            @include mq(medium) {
                margin-top: 600px;
            }
        }
    }
}