.home {

    &-about {

        .wrapper {
            padding-top: 50rem;
            
            @include mq('large') {
                padding-top: 40rem;
            }

            @include mq('medium') {
                padding-top: 30rem;
            }

            @include mq('small') {
                padding-top: 20rem;
            }
        }

        &__img {
            position: absolute;
            top: 25rem;
            bottom: 0;
            right: 0;
            width: 55%;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
            z-index: $z-back;

            @include mq('small') {
                position: relative;
                height: 400px;
                width: 100%;
                background-size: cover;
                top: initial;
                margin-bottom: 5rem;
            }
        }

    }

    &-10-years {
        overflow: hidden;

        .wrapper {
            padding-top: 10rem;
            padding-bottom: 10rem;
            text-align: center;
        }

        &__orange-bar {
            background: $orange;
            position: relative;
            padding: 40px 0;
            font-size: 30px;
            line-height: 35px;
            font-weight: bold;
            transform: translateY(-20px);
            text-transform: uppercase;

            @include mq(large) {
                font-size: 25px;
                line-height: 30px;
            }

            @include mq(medium) {
                font-size: 20px;
                line-height: 25px;
                padding: 30px 0;
            }

            @include mq(xsmall) {
                font-size: 16px;
                line-height: 21px;
            }

            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                width: 2000px;
                background: $orange;
            }

            &:before {
                right: 100%;
            }

            &:after {
                left: 100%;
            }
        }
    }


    &-partenaires {
        background: $white;

        .content {
            padding-top: 10rem;
            padding-bottom: 0;
        }

        h2 {
            color: $black;

            &.has-orange-separator {

                &:before {
                    border-color: $white;
                }
            }
        }

        &__list {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            align-items: center;
            list-style-type: none;
            padding: 0;
            margin: 0 -40px 0 0;

            @include mq('large') {
                margin: 0;
                flex-flow: row wrap;
                justify-content: flex-start;
            }

            @include mq('small') {
                flex-flow: column wrap;
                align-items: center;
            }


            
            li {
                margin-right: 40px;

                @include mq('small') {
                    margin-right: 0;
                }

                img {
                    backface-visibility: hidden;
                }
            }
        }
    }
}