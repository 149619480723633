.burger-icon {
    width: 50px;
    height: 50px;
    border: 1px solid $grey;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &.mobile {
        display: none;

        @include mq(xlarge) {
            display: flex;
        }

        @include mq(small) {
            width: 40px;
            height: 40px;
        }
    }

    &.active {

        &:before {
            transform: translateY(0) rotate(45deg);
            
        }

        &:after {
            transform: translateY(0) rotate(-45deg);   
        }
    }

    &:before,
    &:after {
        content: '';
        width: 30px;
        height: 2px;
        background: $white;
        position: absolute;
        transition: all 300ms ease;
        
        @include mq(small) {
            width: 20px;
        }
    }

    &:before {
        transform: translateY(-4px);
    }

    &:after {
        transform: translateY(4px);
    }
}