.licenses {
    background: $white;
    color: $black;
    
    .wrapper {
        padding-top: 10rem;
        padding-bottom: 10rem;
    }

    h2 {
        margin-top: 100px;
    }

    p {
        max-width: 650px;
    }

    .bullet-list {
        list-style-type: none;
        padding: 0 0 0 30px;

        li {
            padding: 0px;
            margin: 15px 0;
            font-size: 16px;
            line-height: 24px;

            &:before {
                content: '•';
                color: $orange;
                margin-left: -26px;
                margin-right: 10px;
            }

            &:after {
                display: none;
            }
        }
    }
}