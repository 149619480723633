.sidebar {
    width: 110px;
    background: $black;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    border-right: 1px solid $grey;
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    z-index: 99999999;

    @include mq('xlarge') {
        display: none;
    }

    &__socials {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 30px;
        display: flex;
        flex-flow: column wrap;
        justify-content: center;
        align-items: center;

        a {
            color: $white;
            margin-bottom: 20px;
        }

        span {
            color: $grey;
        }
    }
}