footer {
    background-image: url(../images/soil-dark-back.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    background-color: $black;
    z-index: 5;
    
    &:before {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 5;
        background: rgba($black, 0.75);
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-image: url(../images/soil-dark-front.png);   
        z-index: 6;
        opacity: 0.8;
        background-size: cover;
        background-repeat: no-repeat;
    }

    & > * {
        position: relative;
        z-index: 9999;
    }
}

.footer {

    &__soumission {
        padding: 200px 40px;
        position: relative;
        text-align: center;

        @media (max-width: 550px) {
            padding: 150px 40px;
        }

        &-border {
            position: absolute;
            top: 40px;
            right: 40px;
            bottom: 40px;
            left: 40px;
            border: 1px solid $grey;
            pointer-events: none;

            @media (max-width: 550px) {
                top: 30px;
                right: 30px;
                bottom: 30px;
                left: 30px;
            }

            @include mq(small) {
                top: 20px;
                right: 20px;
                bottom: 20px;
                left: 20px;
            }
        }

        &:before {
            content: '';
            position: absolute;
            top: 40px;
            left: 0;
            right: 0;
            margin: 0 auto;
            height: 5px;
            width: 30px;
            background: $white;

            @media (max-width: 550px) {
                top: 30px;
            }

            @include mq(small) {
                top: 20px;
            }
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 40px;
            left: 0;
            right: 0;
            margin: 0 auto;
            height: 5px;
            width: 30px;
            background: $white;

            @media (max-width: 550px) {
                bottom: 30px;
            }

            @include mq(small) {
                bottom: 20px;
            }
        }

        .h2 {
            font-size: 50px;
            line-height: 55px;
            text-transform: initial;

            @include mq(medium) {
                font-size: 40px;
                line-height: 45px;
            }

            @media (max-width: 550px) {
                font-size: 35px;
                line-height: 40px;
            }

            @include mq(small) {
                font-size: 28px;
                line-height: 34px;
            }

            @media (max-width: 375px) {
                font-size: 22px;
                line-height: 26px;
            }
        }

        .h3 {
            font-size: 25px;
            line-height: 30px;
            color: $orange;
            margin-bottom: 40px;

            @include mq(medium) {
                font-size: 20px;
                line-height: 25px;
            }

            @media (max-width: 550px) {
                font-size: 18px;
                line-height: 23px;
            }

            @include mq(small) {
                font-size: 16px;
                line-height: 22px;
            }

            @media (max-width: 375px) {
                font-size: 14px;
                line-height: 20px;
            }
        }

        a {
            font-weight: 400;
        }

        .c-button--skewed {
            @include mq(small) {
                margin-left: -50px;
            }
        }
    }

    &__content {
        width: 100%;
        padding: 80px 40px;
        display: flex;
        flex-flow: row nowrap;
        margin-right: -20px;
        img{
            max-width: 122px;
        }
        @include mq(large) {
            flex-flow: column wrap;
        }

        @include mq(small) {
            padding: 60px 30px;
        }

        .col_1x4 {
            width: calc(25% - 20px);
            margin-right: 20px;

            @include mq(large) {
                width: 100%;
                margin: 20px 0;
            }
        }

        h4 {
            font-size: 20px;
            line-height: 25px;
        }

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;

            &.quick-links {
                @include mq(large) {
                    display: flex;
                    flex-flow: row wrap;
                }
            }

            li {
                margin-bottom: 10px;

                a {
                    color: $white;
                    font-size: 1.6rem;

                    i.fa-arrow-right {
                        font-size: 12px;
                        color: $orange;
                        transition: all 200ms ease;
                        opacity: 0;
                        visibility: hidden;
                    }

                    &:hover {

                        i.fa-arrow-right {
                            opacity: 1;
                            visibility: visible;
                            transform: translateX(8px);
                        }
                    }
                }
            }
        }

        .columns-list {
            display: flex;
            flex-direction: column !important;
            flex-flow: row wrap;
            
            li {
                width: 100%;

                @include mq(large) {
                    width: auto;
                }

                @include mq(small) {
                    width: 100%;
                }
            }
        }

        .coordinates-list {

            li {

                a {
                    transition: all 200ms ease;
                }

                a:hover {
                    color: $orange;
                }

                i {
                    font-size: 14px;
                    color: $white;
                    transition: all 200ms ease;
                    margin-left: 10px;

                    &:hover {
                        color: $orange;
                    }
                }
            }
        }
    }

    &__copyright {
        padding: 40px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;

        @include mq(small) {
            padding: 30px;
        }

        button {
            outline: 0;
            border: 0;
            background: transparent;
            color: $white;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;
            font-size: 1.5rem;
            font-weight: 500;
            cursor: pointer;

            &:after {
                content: '\f062';
                font-family: 'Font Awesome 5 Free';
                font-weight: 600;
                width: 50px;
                height: 50px;
                display: flex;
                flex-flow: row wrap;
                justify-content: center;
                align-items: center;
                border: 1px solid $grey;
                margin-left: 20px;
                font-size: 12px;
            }

            span {

                @media (max-width: 550px) {
                    display: none;
                }
            }
        }
    }
}