.main-nav {
    margin-right: 30px;

    @include mq(xlarge) {
        margin: 0;
    }
    
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;

        li {
            margin: 0 20px;

            @include mq(xlarge) {
                margin: 0;
            }

            &:not(.main-nav__separated) {
                @include mq(xlarge) {
                    display: none;
                }
            }
            
            a {
                font-weight: 600;
                color: $white;
            }
        }
    }

    &__separated {
        position: relative;

        &:before {
            content: '';
            position: absolute;
            left: -20px;
            top: 0;
            bottom: 0;
            margin: auto 0;
            width: 1px;
            height: 100%;
            background: $white-transparent;

            @include mq(xlarge) {
                display: none;
            }
        }
    }

    &__label {
        color: $white-transparent;
        margin-top: -21px;

        @include mq(xlarge) {
            margin-top: 0;
        }
    }
}