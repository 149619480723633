.flex {
    display: flex;
}

.flex--row {
    @extend .flex;
    flex-flow: row wrap;
}

.flex--col {
    @extend .flex;        
    flex-flow: column wrap;
}

.al-center {
    align-items: center;
}

.al-start {
    align-items: flex-start;
}

.al-end {
    align-items: flex-end;
}

.ju-center {
    justify-content: center;
}

.ju-start {
    justify-content: flex-start;
}

.ju-end {
    justify-content: flex-end;
}

.ju-sb {
    justify-content: space-between;
}

.grow {
    flex-grow: 1;
}