.c-review {
  background-color: $white;
  color: $black;
  padding: 15rem 20rem;

  @media (max-width: 1300px) {
    padding: 15rem 10rem;
  }

  @media (max-width: 768px) {
    padding: 10rem 2rem;
  }

  .c-review_content {
    border: 2px solid #e4e4e4;
    padding: 70px 0;

    h4 {
      text-align: center;
    }

    .slick-slider {
      .slick-arrow {
        position: absolute;
        top: 25%;
        border: 1px solid #eaeaea;
        background-color: transparent;
        width: 40px;
        height: 40px;
        z-index: 50;
        border-radius: 30px;
        cursor: pointer;
      }

      .slick-prev {
        left: 10%;
        color: transparent !important;

        &::after {
          content: url("../images/prev.svg");
          position: absolute;
          top: 11px;
          right: 10px;
        }

        @media (max-width: 630px) {
          left: 2%;
        }
      }

      .slick-next {
        left: 85%;
        color: transparent !important;

        &::after {
          content: url("../images/next.svg");
          position: absolute;
          top: 11px;
          right: 10px;
        }

        @media (max-width: 630px) {
          left: 90%;
        }

        @media (max-width: 500px) {
          left: 87%;
        }

        @media (max-width: 400px) {
          left: 84%;
        }
      }
    }

    .c-review_text {
      padding: 5rem 25rem;
      text-align: center;

      @media (max-width: 1300px) {
        padding: 5rem 15rem;
      }

      @media (max-width: 768px) {
        padding: 5rem 6rem;
      }

      h4 {
        margin-top: 15px;
        font-size: 18px;
      }

      p {
        font-size: 16px;
        font-weight: 400;
      }

      .c-btn {
        margin-top: 50px;
      }
    }

    .c-review_rating {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 20px;
      padding-bottom: 20px;

      .note,
      .avis {
          font-size: 18px;
          font-weight: 400;
          color: #4d4d4d;
      }

      .stars {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      .grey-star {
        &::after {
          content: url(../images/star-grey.svg);
          width: 25px;
          height: 25px;
        }
      }

      .full-star {
        &::after {
          content: url(../images/star.svg);
          width: 25px;
          height: 25px;
        }
      }

      .half-star {
        &::after {
          content: url(../images/demi-star.svg);
          width: 25px;
          height: 25px;
        }
      }
    }
  }

  .c-btn {
    text-align: center;
  }
}
