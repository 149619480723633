.single-service {
    &__banner {
        height: 650px;
        position: relative;
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: rgba($black, 0.8);
            z-index: 1;
        }
        .container{
            position: relative;
            z-index: 2;
        }
        button.scrollTo {
            appearance: none;
            background: transparent;
            border: 0;
            cursor: pointer;
            outline: 0;
        }

        &-bg {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            background-size: cover;
            background-position:center;
            background-repeat: no-repeat;
            width: 100%;
            z-index: 0;
            overflow: hidden;
            display: inline-block;
            padding: 0;
            margin: 0;
            padding-top: 56.25%;
            height: 0;
            pointer-events: none;
            @include mq(large) {
                width: 100%;
            }

            
        }
        #player{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            @media screen and (max-width: 1200px) {
                display: none;
            }
        }

        .container {
            height: 100%;
        }

        &-content {

            .category {
                color: $grey;
                font-weight: 700;
                font-size: 1.8rem;
                line-height: 2.4rem;
                display: block;
                margin-bottom: 10px;
            }
            
            p {
                max-width: 600px;
            }

            .video-button {
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                color: $white;
                margin-bottom: -100px;
                margin-top: 100px;

                .play-icon {
                    width: 40px;
                    height: 40px;
                    border-radius: 100%;
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: center;
                    align-items: center;
                    border: 2px solid $white;
                    margin-right: 15px;

                    i {
                        font-size: 10px;
                        color: $white;
                    }
                }
            }
        }
    }

    &__content {
        background: $white;
        color: $black;
        &-video{
            position: relative;
            z-index: 1;
            margin-top: -250px;
            margin-bottom: 80px;
        }
        .content {
            @include mq(large) {
                padding: 5rem 3rem;
            }

            @include mq(small) {
                padding: 5rem 0;
            }
        }

        .wrapper {
            padding-top: 10rem;
            padding-bottom: 10rem;
        }

        .row {
            display: flex;
            flex-flow: row wrap;
            align-items: center;

            .content {
                width: 55%;

                @media (max-width: 850px) {
                    width: 100%;
                }

                h2 {
                    font-size: 4rem;
                    line-height: 4.5rem;

                    &:before {
                        content: '';
                        display: block;
                        width: 25px;
                        height: 1px;
                        border-top: 4px solid $orange;
                        margin-bottom: 20px;
                    }
                }

                p {
                    font-size: 1.8rem;
                }
            }
            
            img {
                width: 45%;
                height: auto;

                @media (max-width: 850px) {
                    width: 100%;
                }
            }

            &:nth-child(even) {

                img {
                    order: -1;

                    @media (max-width: 850px) {
                        order: initial;
                    }
                }
            }

            &:not(:last-of-type) {
                margin-bottom: 10rem;
            }
        }
    }

    &__more {
        background: $white;
        color: $black;

        .has-orange-separator {
            
            &:before {
                bottom: 20px;
                border-left: 2px solid $white;
                border-right: 2px solid $white;
            }
        }
    }
}

.content-services-centered {
    text-align: center;

    p {
        max-width: 850px;
        margin-left: auto;
        margin-right: auto;
    }
}