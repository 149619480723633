.splash {
    width: 100%;
    height: 100%;
    background: $black;

    #player {
        position: absolute;
        top: 0px;
        left: 0px;
        right: 0px;
        bottom: 0px;
        width: 100%;
        height: 100%;

        @include mq(xlarge) {
            display: none;
        }
    }

    .container {
        height: 100%;
    }

    &__background {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 0;
        opacity: 0.2;
        overflow: hidden;
        display: inline-block; 
        padding: 0;            
        margin: 0;
        padding-top: 56.25%;   
        height: 0px;
        pointer-events: none;

        @include mq(xlarge) {
            padding-top: 0;
            height: 100%;
        }

        @include mq(xlarge) {
            opacity: 1;
        }

        &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            height: 250px;
            z-index: 9999;
            background: linear-gradient(to top, rgba(9,12,16,0) 0%,rgba(9,12,16,1) 100%);
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 350px;
            background: linear-gradient(to bottom, rgba(9,12,16,0) 0%,rgba(9,12,16,1) 100%);
        }
    }

    &__wrapper {
        height: 100%;

        h4, h1, h2 {
            max-width: 1000px;
            line-height: 1.2em;
        }
        h2{
            font-size: 2.5rem;
        }

        p {
            margin-bottom: 30px;
        }

        .splash__content {
            flex: 0 75%;

            @media screen and (max-width: 767px) {
                flex: 0 100%;
            }
        }

        .splash__image {
            flex: 0 25%;
            display: flex;
            justify-content: flex-end;

            @media screen and (max-width: 767px) {
                display: none;
            }

            img {
                max-width: 250px;
            }
        }
    }

    &--about {

        .splash__background {
            height: 100%;
            z-index: 0;
        }
    }
}