@charset "UTF-8";
/* =======================================================================
Core
========================================================================== */
/* =======================================================================
Responsive
========================================================================== */
/* =======================================================================
Font-face
========================================================================== */
@import url("https://use.typekit.net/aaf0krl.css");
/* =======================================================================
Variables
========================================================================== */
/* =======================================================================
z-index
========================================================================== */
/* =======================================================================
Helpers
========================================================================== */
/* line 5, public/wp-content/themes/lesage/assets/src/scss/core/_helpers.scss */
.h-centered {
  text-align: center;
}

/* line 9, public/wp-content/themes/lesage/assets/src/scss/core/_helpers.scss */
.h-clearfix:before,
.h-clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

/* line 15, public/wp-content/themes/lesage/assets/src/scss/core/_helpers.scss */
.h-clearfix:after {
  clear: both;
}

/* line 19, public/wp-content/themes/lesage/assets/src/scss/core/_helpers.scss */
.h-clearfix {
  *zoom: 1;
}

/* line 23, public/wp-content/themes/lesage/assets/src/scss/core/_helpers.scss */
.h-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* line 32, public/wp-content/themes/lesage/assets/src/scss/core/_helpers.scss */
.h-visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* line 43, public/wp-content/themes/lesage/assets/src/scss/core/_helpers.scss */
.h-embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}

/* line 51, public/wp-content/themes/lesage/assets/src/scss/core/_helpers.scss */
.h-embed-responsive iframe,
.h-embed-responsive embed,
.h-embed-responsive object,
.h-embed-responsive video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 0;
}

/* line 64, public/wp-content/themes/lesage/assets/src/scss/core/_helpers.scss */
.h-embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

/* line 68, public/wp-content/themes/lesage/assets/src/scss/core/_helpers.scss */
.h-embed-responsive-4by3 {
  padding-bottom: 75%;
}

/* line 72, public/wp-content/themes/lesage/assets/src/scss/core/_helpers.scss */
.h-flex-center {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

/* line 79, public/wp-content/themes/lesage/assets/src/scss/core/_helpers.scss */
.u-margin-bottom-none {
  margin-bottom: 0 !important;
}

/* =======================================================================
Global
========================================================================== */
/* line 1, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
#wpadminbar {
  z-index: 9999999;
}

/* line 5, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
html {
  font-size: 10px;
  overflow-x: hidden;
}

/* line 10, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
body {
  background-color: #090C10;
  font-family: "futura-pt";
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 500;
  color: #FAFAFA;
  margin: 0px;
}

/* line 27, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
body.black-bg .page-header {
  background: #090C10;
  border-bottom: 1px solid #707070;
}

/* line 34, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
html, body {
  height: 100%;
}

/* line 38, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
section {
  position: relative;
}

/* line 42, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
html, body {
  height: 100%;
}

/* line 46, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
* {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* line 52, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
img {
  max-width: 100%;
}

/* line 56, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
textarea {
  resize: vertical;
}

/* line 60, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
.h2 {
  font-weight: 700;
}

/* line 64, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
h1, h2, .h2, h3, h4, h5, h6 {
  line-height: 1.1em;
  margin-top: 0;
  margin-bottom: 20px;
}

/* line 69, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
h1 span, h2 span, .h2 span, h3 span, h4 span, h5 span, h6 span {
  color: #FF5205;
}

/* line 73, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
h1.has-separator, h2.has-separator, .h2.has-separator, h3.has-separator, h4.has-separator, h5.has-separator, h6.has-separator {
  position: relative;
  margin-bottom: 0;
}

/* line 77, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
h1.has-separator:after, h2.has-separator:after, .h2.has-separator:after, h3.has-separator:after, h4.has-separator:after, h5.has-separator:after, h6.has-separator:after {
  content: '';
  display: block;
  width: 25px;
  height: 2px;
  background: #FAFAFA;
  margin: 20px 0;
}

/* line 87, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
h1.has-orange-separator, h2.has-orange-separator, .h2.has-orange-separator, h3.has-orange-separator, h4.has-orange-separator, h5.has-orange-separator, h6.has-orange-separator {
  position: relative;
  margin-bottom: 0;
}

/* line 91, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
h1.has-orange-separator:before, h2.has-orange-separator:before, .h2.has-orange-separator:before, h3.has-orange-separator:before, h4.has-orange-separator:before, h5.has-orange-separator:before, h6.has-orange-separator:before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 24px;
  z-index: 3;
  width: 6px;
  height: 3px;
  border-left: 2px solid #090C10;
  border-right: 2px solid #090C10;
  background: transparent;
  transform: skewX(-10deg);
}

/* line 106, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
h1.has-orange-separator:after, h2.has-orange-separator:after, .h2.has-orange-separator:after, h3.has-orange-separator:after, h4.has-orange-separator:after, h5.has-orange-separator:after, h6.has-orange-separator:after {
  content: '';
  display: block;
  width: 40px;
  height: 3px;
  background: #FF5205;
  margin: 20px 0;
  transform: skewX(-10deg);
}

/* line 117, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
h1.has-orange-separator--white, h2.has-orange-separator--white, .h2.has-orange-separator--white, h3.has-orange-separator--white, h4.has-orange-separator--white, h5.has-orange-separator--white, h6.has-orange-separator--white {
  position: relative;
  margin-bottom: 0;
}

/* line 121, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
h1.has-orange-separator--white:before, h2.has-orange-separator--white:before, .h2.has-orange-separator--white:before, h3.has-orange-separator--white:before, h4.has-orange-separator--white:before, h5.has-orange-separator--white:before, h6.has-orange-separator--white:before {
  content: '';
  display: block;
  position: absolute;
  bottom: 0;
  left: 24px;
  z-index: 3;
  width: 6px;
  height: 3px;
  border-left: 2px solid #FAFAFA;
  border-right: 2px solid #FAFAFA;
  background: transparent;
  transform: skewX(-10deg);
}

/* line 138, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
h1.has-orange-separator--white.bottom-20:before, h2.has-orange-separator--white.bottom-20:before, .h2.has-orange-separator--white.bottom-20:before, h3.has-orange-separator--white.bottom-20:before, h4.has-orange-separator--white.bottom-20:before, h5.has-orange-separator--white.bottom-20:before, h6.has-orange-separator--white.bottom-20:before {
  bottom: 20px;
}

/* line 144, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
h1.has-orange-separator--white:after, h2.has-orange-separator--white:after, .h2.has-orange-separator--white:after, h3.has-orange-separator--white:after, h4.has-orange-separator--white:after, h5.has-orange-separator--white:after, h6.has-orange-separator--white:after {
  content: '';
  display: block;
  width: 40px;
  height: 3px;
  background: #FF5205;
  margin: 20px 0;
  transform: skewX(-10deg);
}

/* line 157, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
h1,
h4.h1 {
  font-size: 7.5rem;
  text-transform: uppercase;
}

@media (max-width: 1200px) {
  /* line 157, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
  h1,
  h4.h1 {
    font-size: 6.5rem;
  }
}

@media (max-width: 1024px) {
  /* line 157, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
  h1,
  h4.h1 {
    font-size: 5.5rem;
  }
}

@media (max-width: 768px) {
  /* line 157, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
  h1,
  h4.h1 {
    font-size: 5rem;
  }
}

@media (max-width: 480px) {
  /* line 157, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
  h1,
  h4.h1 {
    font-size: 4rem;
  }
}

@media (max-width: 375px) {
  /* line 157, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
  h1,
  h4.h1 {
    font-size: 3rem;
  }
}

/* line 183, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
h2,
.h2,
h4.h2 h3.h2,
h5.h2 {
  font-size: 5.6rem;
  line-height: 1.2em;
  text-transform: uppercase;
}

@media (max-width: 1200px) {
  /* line 183, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
  h2,
  .h2,
  h4.h2 h3.h2,
  h5.h2 {
    font-size: 5rem;
  }
}

@media (max-width: 1024px) {
  /* line 183, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
  h2,
  .h2,
  h4.h2 h3.h2,
  h5.h2 {
    font-size: 4.5rem;
  }
}

@media (max-width: 768px) {
  /* line 183, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
  h2,
  .h2,
  h4.h2 h3.h2,
  h5.h2 {
    font-size: 3.5rem;
  }
}

@media (max-width: 480px) {
  /* line 183, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
  h2,
  .h2,
  h4.h2 h3.h2,
  h5.h2 {
    font-size: 3rem;
  }
}

@media (max-width: 375px) {
  /* line 183, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
  h2,
  .h2,
  h4.h2 h3.h2,
  h5.h2 {
    font-size: 2.5rem;
  }
}

/* line 213, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
h3,
h2.h3 {
  font-size: 4.6rem;
}

@media (max-width: 1200px) {
  /* line 213, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
  h3,
  h2.h3 {
    font-size: 4.2rem;
  }
}

@media (max-width: 1024px) {
  /* line 213, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
  h3,
  h2.h3 {
    font-size: 3.8rem;
  }
}

@media (max-width: 768px) {
  /* line 213, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
  h3,
  h2.h3 {
    font-size: 3.2rem;
  }
}

@media (max-width: 480px) {
  /* line 213, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
  h3,
  h2.h3 {
    font-size: 2.8rem;
  }
}

/* line 234, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
h4 {
  font-size: 2.5rem;
  line-height: 1.5em;
}

@media (max-width: 1200px) {
  /* line 234, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
  h4 {
    font-size: 2.2rem;
  }
}

@media (max-width: 1024px) {
  /* line 234, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
  h4 {
    font-size: 2rem;
  }
}

/* line 247, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
h5 {
  font-size: 2.1rem;
}

/* line 250, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
h5:not(:first-of-type) {
  margin-top: 80px;
}

/* line 255, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
h6 {
  font-size: 1.8rem;
}

/* line 259, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
p {
  font-size: 2rem;
  line-height: 1.8em;
  margin-top: 0;
  margin-bottom: 30px;
}

@media (max-width: 480px) {
  /* line 259, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
  p {
    font-size: 1.6rem;
  }
}

/* line 270, public/wp-content/themes/lesage/assets/src/scss/global/_base.scss */
a {
  text-decoration: none;
}

/* line 1, public/wp-content/themes/lesage/assets/src/scss/global/_nav.scss */
.main-nav {
  margin-right: 30px;
}

@media (max-width: 1200px) {
  /* line 1, public/wp-content/themes/lesage/assets/src/scss/global/_nav.scss */
  .main-nav {
    margin: 0;
  }
}

/* line 8, public/wp-content/themes/lesage/assets/src/scss/global/_nav.scss */
.main-nav ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

/* line 13, public/wp-content/themes/lesage/assets/src/scss/global/_nav.scss */
.main-nav ul li {
  margin: 0 20px;
}

@media (max-width: 1200px) {
  /* line 13, public/wp-content/themes/lesage/assets/src/scss/global/_nav.scss */
  .main-nav ul li {
    margin: 0;
  }
}

@media (max-width: 1200px) {
  /* line 20, public/wp-content/themes/lesage/assets/src/scss/global/_nav.scss */
  .main-nav ul li:not(.main-nav__separated) {
    display: none;
  }
}

/* line 26, public/wp-content/themes/lesage/assets/src/scss/global/_nav.scss */
.main-nav ul li a {
  font-weight: 600;
  color: #FAFAFA;
}

/* line 33, public/wp-content/themes/lesage/assets/src/scss/global/_nav.scss */
.main-nav__separated {
  position: relative;
}

/* line 36, public/wp-content/themes/lesage/assets/src/scss/global/_nav.scss */
.main-nav__separated:before {
  content: '';
  position: absolute;
  left: -20px;
  top: 0;
  bottom: 0;
  margin: auto 0;
  width: 1px;
  height: 100%;
  background: rgba(250, 250, 250, 0.4);
}

@media (max-width: 1200px) {
  /* line 36, public/wp-content/themes/lesage/assets/src/scss/global/_nav.scss */
  .main-nav__separated:before {
    display: none;
  }
}

/* line 53, public/wp-content/themes/lesage/assets/src/scss/global/_nav.scss */
.main-nav__label {
  color: rgba(250, 250, 250, 0.4);
  margin-top: -21px;
}

@media (max-width: 1200px) {
  /* line 53, public/wp-content/themes/lesage/assets/src/scss/global/_nav.scss */
  .main-nav__label {
    margin-top: 0;
  }
}

/* line 1, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
.page-content {
  margin-left: 110px;
  height: 100%;
  position: relative;
}

@media (max-width: 1200px) {
  /* line 1, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
  .page-content {
    margin-left: 0;
  }
}

/* line 11, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
.page-header {
  position: fixed;
  top: 0;
  left: 110px;
  right: 0;
  height: 90px;
  z-index: 999999;
  padding: 20px 30px;
  transition: all 300ms ease;
}

@media (max-width: 1200px) {
  /* line 11, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
  .page-header {
    left: 0;
  }
}

@media (max-width: 480px) {
  /* line 11, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
  .page-header {
    padding: 20px;
  }
}

/* line 29, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
.page-header.has-background {
  background: #090c10;
  border-bottom: 1px solid #707070;
}

/* line 34, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
.page-header.no-background {
  background: rgba(9, 12, 16, 0);
  border-bottom: 1px solid rgba(112, 112, 112, 0);
}

@media (max-width: 1200px) {
  /* line 39, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
  .page-header .c-button, .page-header .c-button--style-1, .page-header .c-button--style-2, .page-header .c-button--skewed, .page-header .c-button--skewed-2, .page-header .c-button--skewed-3, .page-header .c-button--transparent {
    display: none;
  }
}

/* line 47, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
.page-header__logo img {
  max-width: 120px;
}

@media (max-width: 1200px) {
  /* line 46, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
  .page-header__logo {
    text-align: center;
  }
}

@media (max-width: 375px) {
  /* line 54, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
  .page-header__logo img {
    width: 90px;
  }
}

/* line 62, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 30px;
  position: relative;
}

/* line 69, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
.container--left {
  margin-left: 0;
}

/* line 74, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
.wrapper {
  padding-top: 20rem;
  padding-bottom: 20rem;
}

@media (max-width: 1024px) {
  /* line 74, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
  .wrapper {
    padding-top: 15rem;
    padding-bottom: 15rem;
  }
}

@media (max-width: 768px) {
  /* line 74, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
  .wrapper {
    padding-top: 10rem;
    padding-bottom: 10rem;
  }
}

@media (max-width: 480px) {
  /* line 74, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
  .wrapper {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

/* line 95, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
.content {
  padding: 10rem;
  position: relative;
}

@media (max-width: 1024px) {
  /* line 95, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
  .content {
    padding: 5rem 8rem;
  }
}

@media (max-width: 768px) {
  /* line 95, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
  .content {
    padding: 5rem;
  }
}

@media (max-width: 480px) {
  /* line 95, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
  .content {
    padding: 3rem 0;
  }
}

/* line 111, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
.content.limited-big > * {
  max-width: 750px;
}

/* line 115, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
.content.limited > * {
  max-width: 550px;
}

/* line 119, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
.content.half {
  padding: 10rem 5rem;
}

@media (max-width: 768px) {
  /* line 119, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
  .content.half {
    padding: 5rem 0;
  }
}

/* line 128, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
.pushed-down {
  margin-top: 100px;
}

/* line 132, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
.orange-sidebar {
  position: relative;
}

/* line 135, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
.orange-sidebar:after {
  content: '';
  position: absolute;
  left: calc(100% - 30px);
  width: 2500px;
  top: 0;
  bottom: 0;
  background: #FF5205;
}

@media (max-width: 1024px) {
  /* line 135, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
  .orange-sidebar:after {
    display: none;
  }
}

/* line 151, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
.orange-sidebar.pull-top:after {
  top: -200px;
}

/* line 158, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
.orange-sidebar.pull-top:after {
  bottom: -100px;
}

/* line 163, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}

/* line 169, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
.embed-responsive:before {
  padding-top: 56.25%;
  display: block;
  content: "";
}

/* line 174, public/wp-content/themes/lesage/assets/src/scss/global/_layout.scss */
.embed-responsive-item {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

/* line 1, public/wp-content/themes/lesage/assets/src/scss/global/_sidebar.scss */
.sidebar {
  width: 110px;
  background: #090C10;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  border-right: 1px solid #707070;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
}

@media (max-width: 1200px) {
  /* line 1, public/wp-content/themes/lesage/assets/src/scss/global/_sidebar.scss */
  .sidebar {
    display: none;
  }
}

/* line 19, public/wp-content/themes/lesage/assets/src/scss/global/_sidebar.scss */
.sidebar__socials {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 30px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

/* line 30, public/wp-content/themes/lesage/assets/src/scss/global/_sidebar.scss */
.sidebar__socials a {
  color: #FAFAFA;
  margin-bottom: 20px;
}

/* line 35, public/wp-content/themes/lesage/assets/src/scss/global/_sidebar.scss */
.sidebar__socials span {
  color: #707070;
}

/* line 1, public/wp-content/themes/lesage/assets/src/scss/global/_flex.scss */
.flex, .flex--row, .flex--col {
  display: flex;
}

/* line 5, public/wp-content/themes/lesage/assets/src/scss/global/_flex.scss */
.flex--row {
  flex-flow: row wrap;
}

/* line 10, public/wp-content/themes/lesage/assets/src/scss/global/_flex.scss */
.flex--col {
  flex-flow: column wrap;
}

/* line 15, public/wp-content/themes/lesage/assets/src/scss/global/_flex.scss */
.al-center {
  align-items: center;
}

/* line 19, public/wp-content/themes/lesage/assets/src/scss/global/_flex.scss */
.al-start {
  align-items: flex-start;
}

/* line 23, public/wp-content/themes/lesage/assets/src/scss/global/_flex.scss */
.al-end {
  align-items: flex-end;
}

/* line 27, public/wp-content/themes/lesage/assets/src/scss/global/_flex.scss */
.ju-center {
  justify-content: center;
}

/* line 31, public/wp-content/themes/lesage/assets/src/scss/global/_flex.scss */
.ju-start {
  justify-content: flex-start;
}

/* line 35, public/wp-content/themes/lesage/assets/src/scss/global/_flex.scss */
.ju-end {
  justify-content: flex-end;
}

/* line 39, public/wp-content/themes/lesage/assets/src/scss/global/_flex.scss */
.ju-sb {
  justify-content: space-between;
}

/* line 43, public/wp-content/themes/lesage/assets/src/scss/global/_flex.scss */
.grow {
  flex-grow: 1;
}

/* line 1, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
footer {
  background-image: url(../images/soil-dark-back.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  background-color: #090C10;
  z-index: 5;
}

/* line 10, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
footer:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  background: rgba(9, 12, 16, 0.75);
}

/* line 21, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
footer:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url(../images/soil-dark-front.png);
  z-index: 6;
  opacity: 0.8;
  background-size: cover;
  background-repeat: no-repeat;
}

/* line 35, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
footer > * {
  position: relative;
  z-index: 9999;
}

/* line 43, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
.footer__soumission {
  padding: 200px 40px;
  position: relative;
  text-align: center;
}

@media (max-width: 550px) {
  /* line 43, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
  .footer__soumission {
    padding: 150px 40px;
  }
}

/* line 52, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
.footer__soumission-border {
  position: absolute;
  top: 40px;
  right: 40px;
  bottom: 40px;
  left: 40px;
  border: 1px solid #707070;
  pointer-events: none;
}

@media (max-width: 550px) {
  /* line 52, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
  .footer__soumission-border {
    top: 30px;
    right: 30px;
    bottom: 30px;
    left: 30px;
  }
}

@media (max-width: 480px) {
  /* line 52, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
  .footer__soumission-border {
    top: 20px;
    right: 20px;
    bottom: 20px;
    left: 20px;
  }
}

/* line 76, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
.footer__soumission:before {
  content: '';
  position: absolute;
  top: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 5px;
  width: 30px;
  background: #FAFAFA;
}

@media (max-width: 550px) {
  /* line 76, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
  .footer__soumission:before {
    top: 30px;
  }
}

@media (max-width: 480px) {
  /* line 76, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
  .footer__soumission:before {
    top: 20px;
  }
}

/* line 96, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
.footer__soumission:after {
  content: '';
  position: absolute;
  bottom: 40px;
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 5px;
  width: 30px;
  background: #FAFAFA;
}

@media (max-width: 550px) {
  /* line 96, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
  .footer__soumission:after {
    bottom: 30px;
  }
}

@media (max-width: 480px) {
  /* line 96, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
  .footer__soumission:after {
    bottom: 20px;
  }
}

/* line 116, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
.footer__soumission .h2 {
  font-size: 50px;
  line-height: 55px;
  text-transform: initial;
}

@media (max-width: 768px) {
  /* line 116, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
  .footer__soumission .h2 {
    font-size: 40px;
    line-height: 45px;
  }
}

@media (max-width: 550px) {
  /* line 116, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
  .footer__soumission .h2 {
    font-size: 35px;
    line-height: 40px;
  }
}

@media (max-width: 480px) {
  /* line 116, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
  .footer__soumission .h2 {
    font-size: 28px;
    line-height: 34px;
  }
}

@media (max-width: 375px) {
  /* line 116, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
  .footer__soumission .h2 {
    font-size: 22px;
    line-height: 26px;
  }
}

/* line 142, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
.footer__soumission .h3 {
  font-size: 25px;
  line-height: 30px;
  color: #FF5205;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  /* line 142, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
  .footer__soumission .h3 {
    font-size: 20px;
    line-height: 25px;
  }
}

@media (max-width: 550px) {
  /* line 142, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
  .footer__soumission .h3 {
    font-size: 18px;
    line-height: 23px;
  }
}

@media (max-width: 480px) {
  /* line 142, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
  .footer__soumission .h3 {
    font-size: 16px;
    line-height: 22px;
  }
}

@media (max-width: 375px) {
  /* line 142, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
  .footer__soumission .h3 {
    font-size: 14px;
    line-height: 20px;
  }
}

/* line 169, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
.footer__soumission a {
  font-weight: 400;
}

@media (max-width: 480px) {
  /* line 173, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
  .footer__soumission .c-button--skewed {
    margin-left: -50px;
  }
}

/* line 180, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
.footer__content {
  width: 100%;
  padding: 80px 40px;
  display: flex;
  flex-flow: row nowrap;
  margin-right: -20px;
}

/* line 186, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
.footer__content img {
  max-width: 122px;
}

@media (max-width: 1024px) {
  /* line 180, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
  .footer__content {
    flex-flow: column wrap;
  }
}

@media (max-width: 480px) {
  /* line 180, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
  .footer__content {
    padding: 60px 30px;
  }
}

/* line 197, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
.footer__content .col_1x4 {
  width: calc(25% - 20px);
  margin-right: 20px;
}

@media (max-width: 1024px) {
  /* line 197, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
  .footer__content .col_1x4 {
    width: 100%;
    margin: 20px 0;
  }
}

/* line 207, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
.footer__content h4 {
  font-size: 20px;
  line-height: 25px;
}

/* line 212, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
.footer__content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

@media (max-width: 1024px) {
  /* line 217, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
  .footer__content ul.quick-links {
    display: flex;
    flex-flow: row wrap;
  }
}

/* line 224, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
.footer__content ul li {
  margin-bottom: 10px;
}

/* line 227, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
.footer__content ul li a {
  color: #FAFAFA;
  font-size: 1.6rem;
}

/* line 231, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
.footer__content ul li a i.fa-arrow-right {
  font-size: 12px;
  color: #FF5205;
  transition: all 200ms ease;
  opacity: 0;
  visibility: hidden;
}

/* line 241, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
.footer__content ul li a:hover i.fa-arrow-right {
  opacity: 1;
  visibility: visible;
  transform: translateX(8px);
}

/* line 251, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
.footer__content .columns-list {
  display: flex;
  flex-direction: column !important;
  flex-flow: row wrap;
}

/* line 256, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
.footer__content .columns-list li {
  width: 100%;
}

@media (max-width: 1024px) {
  /* line 256, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
  .footer__content .columns-list li {
    width: auto;
  }
}

@media (max-width: 480px) {
  /* line 256, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
  .footer__content .columns-list li {
    width: 100%;
  }
}

/* line 273, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
.footer__content .coordinates-list li a {
  transition: all 200ms ease;
}

/* line 277, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
.footer__content .coordinates-list li a:hover {
  color: #FF5205;
}

/* line 281, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
.footer__content .coordinates-list li i {
  font-size: 14px;
  color: #FAFAFA;
  transition: all 200ms ease;
  margin-left: 10px;
}

/* line 287, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
.footer__content .coordinates-list li i:hover {
  color: #FF5205;
}

/* line 295, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
.footer__copyright {
  padding: 40px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
}

@media (max-width: 480px) {
  /* line 295, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
  .footer__copyright {
    padding: 30px;
  }
}

/* line 306, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
.footer__copyright button {
  outline: 0;
  border: 0;
  background: transparent;
  color: #FAFAFA;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  font-weight: 500;
  cursor: pointer;
}

/* line 319, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
.footer__copyright button:after {
  content: '\f062';
  font-family: 'Font Awesome 5 Free';
  font-weight: 600;
  width: 50px;
  height: 50px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  border: 1px solid #707070;
  margin-left: 20px;
  font-size: 12px;
}

@media (max-width: 550px) {
  /* line 334, public/wp-content/themes/lesage/assets/src/scss/global/_footer.scss */
  .footer__copyright button span {
    display: none;
  }
}

/* =======================================================================
Layout
========================================================================== */
/* line 1, public/wp-content/themes/lesage/assets/src/scss/layout/_splash.scss */
.splash {
  width: 100%;
  height: 100%;
  background: #090C10;
}

/* line 6, public/wp-content/themes/lesage/assets/src/scss/layout/_splash.scss */
.splash #player {
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
}

@media (max-width: 1200px) {
  /* line 6, public/wp-content/themes/lesage/assets/src/scss/layout/_splash.scss */
  .splash #player {
    display: none;
  }
}

/* line 20, public/wp-content/themes/lesage/assets/src/scss/layout/_splash.scss */
.splash .container {
  height: 100%;
}

/* line 24, public/wp-content/themes/lesage/assets/src/scss/layout/_splash.scss */
.splash__background {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 0;
  opacity: 0.2;
  overflow: hidden;
  display: inline-block;
  padding: 0;
  margin: 0;
  padding-top: 56.25%;
  height: 0px;
  pointer-events: none;
}

@media (max-width: 1200px) {
  /* line 24, public/wp-content/themes/lesage/assets/src/scss/layout/_splash.scss */
  .splash__background {
    padding-top: 0;
    height: 100%;
  }
}

@media (max-width: 1200px) {
  /* line 24, public/wp-content/themes/lesage/assets/src/scss/layout/_splash.scss */
  .splash__background {
    opacity: 1;
  }
}

/* line 50, public/wp-content/themes/lesage/assets/src/scss/layout/_splash.scss */
.splash__background:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 250px;
  z-index: 9999;
  background: linear-gradient(to top, rgba(9, 12, 16, 0) 0%, #090c10 100%);
}

/* line 61, public/wp-content/themes/lesage/assets/src/scss/layout/_splash.scss */
.splash__background:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 350px;
  background: linear-gradient(to bottom, rgba(9, 12, 16, 0) 0%, #090c10 100%);
}

/* line 72, public/wp-content/themes/lesage/assets/src/scss/layout/_splash.scss */
.splash__wrapper {
  height: 100%;
}

/* line 75, public/wp-content/themes/lesage/assets/src/scss/layout/_splash.scss */
.splash__wrapper h4, .splash__wrapper h1, .splash__wrapper h2 {
  max-width: 1000px;
  line-height: 1.2em;
}

/* line 79, public/wp-content/themes/lesage/assets/src/scss/layout/_splash.scss */
.splash__wrapper h2 {
  font-size: 2.5rem;
}

/* line 83, public/wp-content/themes/lesage/assets/src/scss/layout/_splash.scss */
.splash__wrapper p {
  margin-bottom: 30px;
}

/* line 87, public/wp-content/themes/lesage/assets/src/scss/layout/_splash.scss */
.splash__wrapper .splash__content {
  flex: 0 75%;
}

@media screen and (max-width: 767px) {
  /* line 87, public/wp-content/themes/lesage/assets/src/scss/layout/_splash.scss */
  .splash__wrapper .splash__content {
    flex: 0 100%;
  }
}

/* line 95, public/wp-content/themes/lesage/assets/src/scss/layout/_splash.scss */
.splash__wrapper .splash__image {
  flex: 0 25%;
  display: flex;
  justify-content: flex-end;
}

@media screen and (max-width: 767px) {
  /* line 95, public/wp-content/themes/lesage/assets/src/scss/layout/_splash.scss */
  .splash__wrapper .splash__image {
    display: none;
  }
}

/* line 104, public/wp-content/themes/lesage/assets/src/scss/layout/_splash.scss */
.splash__wrapper .splash__image img {
  max-width: 250px;
}

/* line 112, public/wp-content/themes/lesage/assets/src/scss/layout/_splash.scss */
.splash--about .splash__background {
  height: 100%;
  z-index: 0;
}

/* line 5, public/wp-content/themes/lesage/assets/src/scss/layout/_home.scss */
.home-about .wrapper {
  padding-top: 50rem;
}

@media (max-width: 1024px) {
  /* line 5, public/wp-content/themes/lesage/assets/src/scss/layout/_home.scss */
  .home-about .wrapper {
    padding-top: 40rem;
  }
}

@media (max-width: 768px) {
  /* line 5, public/wp-content/themes/lesage/assets/src/scss/layout/_home.scss */
  .home-about .wrapper {
    padding-top: 30rem;
  }
}

@media (max-width: 480px) {
  /* line 5, public/wp-content/themes/lesage/assets/src/scss/layout/_home.scss */
  .home-about .wrapper {
    padding-top: 20rem;
  }
}

/* line 21, public/wp-content/themes/lesage/assets/src/scss/layout/_home.scss */
.home-about__img {
  position: absolute;
  top: 25rem;
  bottom: 0;
  right: 0;
  width: 55%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}

@media (max-width: 480px) {
  /* line 21, public/wp-content/themes/lesage/assets/src/scss/layout/_home.scss */
  .home-about__img {
    position: relative;
    height: 400px;
    width: 100%;
    background-size: cover;
    top: initial;
    margin-bottom: 5rem;
  }
}

/* line 44, public/wp-content/themes/lesage/assets/src/scss/layout/_home.scss */
.home-10-years {
  overflow: hidden;
}

/* line 47, public/wp-content/themes/lesage/assets/src/scss/layout/_home.scss */
.home-10-years .wrapper {
  padding-top: 10rem;
  padding-bottom: 10rem;
  text-align: center;
}

/* line 53, public/wp-content/themes/lesage/assets/src/scss/layout/_home.scss */
.home-10-years__orange-bar {
  background: #FF5205;
  position: relative;
  padding: 40px 0;
  font-size: 30px;
  line-height: 35px;
  font-weight: bold;
  transform: translateY(-20px);
  text-transform: uppercase;
}

@media (max-width: 1024px) {
  /* line 53, public/wp-content/themes/lesage/assets/src/scss/layout/_home.scss */
  .home-10-years__orange-bar {
    font-size: 25px;
    line-height: 30px;
  }
}

@media (max-width: 768px) {
  /* line 53, public/wp-content/themes/lesage/assets/src/scss/layout/_home.scss */
  .home-10-years__orange-bar {
    font-size: 20px;
    line-height: 25px;
    padding: 30px 0;
  }
}

@media (max-width: 375px) {
  /* line 53, public/wp-content/themes/lesage/assets/src/scss/layout/_home.scss */
  .home-10-years__orange-bar {
    font-size: 16px;
    line-height: 21px;
  }
}

/* line 79, public/wp-content/themes/lesage/assets/src/scss/layout/_home.scss */
.home-10-years__orange-bar:before, .home-10-years__orange-bar:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2000px;
  background: #FF5205;
}

/* line 89, public/wp-content/themes/lesage/assets/src/scss/layout/_home.scss */
.home-10-years__orange-bar:before {
  right: 100%;
}

/* line 93, public/wp-content/themes/lesage/assets/src/scss/layout/_home.scss */
.home-10-years__orange-bar:after {
  left: 100%;
}

/* line 100, public/wp-content/themes/lesage/assets/src/scss/layout/_home.scss */
.home-partenaires {
  background: #FAFAFA;
}

/* line 103, public/wp-content/themes/lesage/assets/src/scss/layout/_home.scss */
.home-partenaires .content {
  padding-top: 10rem;
  padding-bottom: 0;
}

/* line 108, public/wp-content/themes/lesage/assets/src/scss/layout/_home.scss */
.home-partenaires h2 {
  color: #090C10;
}

/* line 113, public/wp-content/themes/lesage/assets/src/scss/layout/_home.scss */
.home-partenaires h2.has-orange-separator:before {
  border-color: #FAFAFA;
}

/* line 119, public/wp-content/themes/lesage/assets/src/scss/layout/_home.scss */
.home-partenaires__list {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  padding: 0;
  margin: 0 -40px 0 0;
}

@media (max-width: 1024px) {
  /* line 119, public/wp-content/themes/lesage/assets/src/scss/layout/_home.scss */
  .home-partenaires__list {
    margin: 0;
    flex-flow: row wrap;
    justify-content: flex-start;
  }
}

@media (max-width: 480px) {
  /* line 119, public/wp-content/themes/lesage/assets/src/scss/layout/_home.scss */
  .home-partenaires__list {
    flex-flow: column wrap;
    align-items: center;
  }
}

/* line 141, public/wp-content/themes/lesage/assets/src/scss/layout/_home.scss */
.home-partenaires__list li {
  margin-right: 40px;
}

@media (max-width: 480px) {
  /* line 141, public/wp-content/themes/lesage/assets/src/scss/layout/_home.scss */
  .home-partenaires__list li {
    margin-right: 0;
  }
}

/* line 148, public/wp-content/themes/lesage/assets/src/scss/layout/_home.scss */
.home-partenaires__list li img {
  backface-visibility: hidden;
}

/* line 3, public/wp-content/themes/lesage/assets/src/scss/layout/_about.scss */
.about-mission {
  padding-top: 10rem;
}

/* line 6, public/wp-content/themes/lesage/assets/src/scss/layout/_about.scss */
.about-mission .wrapper {
  padding-bottom: 0;
}

/* line 11, public/wp-content/themes/lesage/assets/src/scss/layout/_about.scss */
.about-ten-years {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  overflow: hidden;
}

/* line 18, public/wp-content/themes/lesage/assets/src/scss/layout/_about.scss */
.about-ten-years:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: radial-gradient(ellipse at center, rgba(9, 12, 16, 0) 0%, #090c10 100%);
}

/* line 28, public/wp-content/themes/lesage/assets/src/scss/layout/_about.scss */
.about-ten-years .wrapper {
  padding: 0;
}

/* line 32, public/wp-content/themes/lesage/assets/src/scss/layout/_about.scss */
.about-ten-years .content {
  text-align: center;
  position: relative;
}

/* line 36, public/wp-content/themes/lesage/assets/src/scss/layout/_about.scss */
.about-ten-years .content:before, .about-ten-years .content:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  height: 200px;
  background: #FAFAFA;
  opacity: 0.1;
  width: 1500px;
  margin: auto 0;
  transform: skewX(8deg);
}

/* line 50, public/wp-content/themes/lesage/assets/src/scss/layout/_about.scss */
.about-ten-years .content:before {
  left: calc( 100% - 5rem);
}

/* line 54, public/wp-content/themes/lesage/assets/src/scss/layout/_about.scss */
.about-ten-years .content:after {
  right: calc( 100% - 5rem);
}

/* line 60, public/wp-content/themes/lesage/assets/src/scss/layout/_about.scss */
.about-carousel {
  padding-top: 10rem;
}

/* line 63, public/wp-content/themes/lesage/assets/src/scss/layout/_about.scss */
.about-carousel .container {
  padding-left: 0;
}

@media (max-width: 1024px) {
  /* line 63, public/wp-content/themes/lesage/assets/src/scss/layout/_about.scss */
  .about-carousel .container {
    padding-left: 30px;
  }
}

/* line 73, public/wp-content/themes/lesage/assets/src/scss/layout/_about.scss */
.about-carousel .carousel__dots ul {
  justify-content: flex-end;
}

/* line 76, public/wp-content/themes/lesage/assets/src/scss/layout/_about.scss */
.about-carousel .carousel__dots ul li {
  margin-right: 0;
  margin-left: 8px;
}

/* line 84, public/wp-content/themes/lesage/assets/src/scss/layout/_about.scss */
.about-team {
  background: #FAFAFA;
  color: #090C10;
}

/* line 88, public/wp-content/themes/lesage/assets/src/scss/layout/_about.scss */
.about-team .wrapper {
  padding-bottom: 10rem;
}

/* line 92, public/wp-content/themes/lesage/assets/src/scss/layout/_about.scss */
.about-team .content {
  padding: 0;
}

/* line 98, public/wp-content/themes/lesage/assets/src/scss/layout/_about.scss */
.about-team .has-orange-separator:before {
  border-color: #FAFAFA;
}

/* line 103, public/wp-content/themes/lesage/assets/src/scss/layout/_about.scss */
.about-team .skewed-images {
  width: 100%;
  max-width: 1100px;
  margin-left: auto;
  margin-right: -50px;
  padding-bottom: 10rem;
  position: relative;
  z-index: 3;
}

@media (max-width: 1024px) {
  /* line 103, public/wp-content/themes/lesage/assets/src/scss/layout/_about.scss */
  .about-team .skewed-images {
    margin-right: 0;
    padding-bottom: 2rem;
  }
}

/* line 119, public/wp-content/themes/lesage/assets/src/scss/layout/_about.scss */
.about-testimonials {
  background: #FAFAFA;
  color: #090C10;
}

/* line 123, public/wp-content/themes/lesage/assets/src/scss/layout/_about.scss */
.about-testimonials .content {
  padding-bottom: 20rem;
  width: 100%;
}

/* line 130, public/wp-content/themes/lesage/assets/src/scss/layout/_about.scss */
.about-testimonials .has-orange-separator:before {
  border-color: #FAFAFA;
}

/* line 2, public/wp-content/themes/lesage/assets/src/scss/layout/_single-projet.scss */
.single-projet-banner p {
  max-width: 750px;
  margin-bottom: 0;
  margin-top: 20px;
}

/* line 8, public/wp-content/themes/lesage/assets/src/scss/layout/_single-projet.scss */
.single-projet-banner .category {
  color: #707070;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.4rem;
  display: block;
  margin-bottom: 10px;
}

/* line 20, public/wp-content/themes/lesage/assets/src/scss/layout/_single-projet.scss */
.single-projet__bar {
  display: flex;
  flex-flow: row wrap;
  margin: 15rem -20px 5rem 0;
}

/* line 25, public/wp-content/themes/lesage/assets/src/scss/layout/_single-projet.scss */
.single-projet__bar-item {
  width: calc(25% - 20px);
  margin-right: 20px;
  display: flex;
  flex-flow: column wrap;
  position: relative;
  padding: 0px 20px 120px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}

@media (max-width: 1024px) {
  /* line 25, public/wp-content/themes/lesage/assets/src/scss/layout/_single-projet.scss */
  .single-projet__bar-item {
    width: calc(50% - 20px);
    margin-bottom: 100px;
  }
}

/* line 43, public/wp-content/themes/lesage/assets/src/scss/layout/_single-projet.scss */
.single-projet__bar-item:first-of-type:after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  right: 100%;
  width: 2500px;
  background: #707070;
  opacity: 0.1;
  transform: skewX(-10deg);
}

@media (max-width: 1024px) {
  /* line 43, public/wp-content/themes/lesage/assets/src/scss/layout/_single-projet.scss */
  .single-projet__bar-item:first-of-type:after {
    display: none;
  }
}

/* line 60, public/wp-content/themes/lesage/assets/src/scss/layout/_single-projet.scss */
.single-projet__bar-item span {
  display: block;
}

/* line 63, public/wp-content/themes/lesage/assets/src/scss/layout/_single-projet.scss */
.single-projet__bar-item span.item-title {
  margin-top: -60px;
  text-align: center;
}

/* line 68, public/wp-content/themes/lesage/assets/src/scss/layout/_single-projet.scss */
.single-projet__bar-item span.item-stat {
  font-size: 80px;
  line-height: 80px;
  font-weight: 700;
}

/* line 74, public/wp-content/themes/lesage/assets/src/scss/layout/_single-projet.scss */
.single-projet__bar-item span.item-subtitle {
  font-weight: bold;
  color: #FF5205;
}

/* line 79, public/wp-content/themes/lesage/assets/src/scss/layout/_single-projet.scss */
.single-projet__bar-item span.play-icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 50px;
  height: 50px;
  margin: auto;
  border: 2px solid #FAFAFA;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

/* line 95, public/wp-content/themes/lesage/assets/src/scss/layout/_single-projet.scss */
.single-projet__bar-item span.play-icon i {
  color: #FAFAFA;
  margin-right: -2px;
  margin-bottom: -1px;
}

/* line 106, public/wp-content/themes/lesage/assets/src/scss/layout/_single-projet.scss */
.single-projet__bar-item.video:before {
  background: #FF5205;
  opacity: 1;
}

/* line 112, public/wp-content/themes/lesage/assets/src/scss/layout/_single-projet.scss */
.single-projet__bar-item:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: #707070;
  opacity: 0.1;
  transform: skew(-10deg);
  z-index: -1;
}

/* line 127, public/wp-content/themes/lesage/assets/src/scss/layout/_single-projet.scss */
.single-projet__slider {
  background: #090C10;
  padding-bottom: 50px;
  position: relative;
}

/* line 132, public/wp-content/themes/lesage/assets/src/scss/layout/_single-projet.scss */
.single-projet__slider:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 200px;
  background: #FAFAFA;
}

/* line 144, public/wp-content/themes/lesage/assets/src/scss/layout/_single-projet.scss */
.single-projet__slider .carousel__dots ul {
  justify-content: flex-end;
}

@media (max-width: 480px) {
  /* line 144, public/wp-content/themes/lesage/assets/src/scss/layout/_single-projet.scss */
  .single-projet__slider .carousel__dots ul {
    justify-content: flex-start;
  }
}

/* line 151, public/wp-content/themes/lesage/assets/src/scss/layout/_single-projet.scss */
.single-projet__slider .carousel__dots ul li {
  margin-right: 0;
  margin-left: 10px;
}

@media (max-width: 480px) {
  /* line 151, public/wp-content/themes/lesage/assets/src/scss/layout/_single-projet.scss */
  .single-projet__slider .carousel__dots ul li {
    margin-right: 10px;
    margin-left: 0;
    width: calc(33.33% - 10px);
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  /* line 162, public/wp-content/themes/lesage/assets/src/scss/layout/_single-projet.scss */
  .single-projet__slider .carousel__dots ul li button {
    width: 100%;
  }
}

/* line 170, public/wp-content/themes/lesage/assets/src/scss/layout/_single-projet.scss */
.single-projet__slider .carousel__dots ul li.slick-active button {
  color: #FF5205;
}

/* line 2, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
.single-service__banner {
  height: 650px;
  position: relative;
}

/* line 5, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
.single-service__banner:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(9, 12, 16, 0.8);
  z-index: 1;
}

/* line 15, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
.single-service__banner .container {
  position: relative;
  z-index: 2;
}

/* line 19, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
.single-service__banner button.scrollTo {
  appearance: none;
  background: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
}

/* line 27, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
.single-service__banner-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  z-index: 0;
  overflow: hidden;
  display: inline-block;
  padding: 0;
  margin: 0;
  padding-top: 56.25%;
  height: 0;
  pointer-events: none;
}

@media (max-width: 1024px) {
  /* line 27, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
  .single-service__banner-bg {
    width: 100%;
  }
}

/* line 50, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
.single-service__banner #player {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 1200px) {
  /* line 50, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
  .single-service__banner #player {
    display: none;
  }
}

/* line 63, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
.single-service__banner .container {
  height: 100%;
}

/* line 69, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
.single-service__banner-content .category {
  color: #707070;
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 2.4rem;
  display: block;
  margin-bottom: 10px;
}

/* line 78, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
.single-service__banner-content p {
  max-width: 600px;
}

/* line 82, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
.single-service__banner-content .video-button {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  color: #FAFAFA;
  margin-bottom: -100px;
  margin-top: 100px;
}

/* line 90, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
.single-service__banner-content .video-button .play-icon {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  border: 2px solid #FAFAFA;
  margin-right: 15px;
}

/* line 101, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
.single-service__banner-content .video-button .play-icon i {
  font-size: 10px;
  color: #FAFAFA;
}

/* line 110, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
.single-service__content {
  background: #FAFAFA;
  color: #090C10;
}

/* line 113, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
.single-service__content-video {
  position: relative;
  z-index: 1;
  margin-top: -250px;
  margin-bottom: 80px;
}

@media (max-width: 1024px) {
  /* line 119, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
  .single-service__content .content {
    padding: 5rem 3rem;
  }
}

@media (max-width: 480px) {
  /* line 119, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
  .single-service__content .content {
    padding: 5rem 0;
  }
}

/* line 129, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
.single-service__content .wrapper {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

/* line 134, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
.single-service__content .row {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

/* line 139, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
.single-service__content .row .content {
  width: 55%;
}

@media (max-width: 850px) {
  /* line 139, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
  .single-service__content .row .content {
    width: 100%;
  }
}

/* line 146, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
.single-service__content .row .content h2 {
  font-size: 4rem;
  line-height: 4.5rem;
}

/* line 150, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
.single-service__content .row .content h2:before {
  content: '';
  display: block;
  width: 25px;
  height: 1px;
  border-top: 4px solid #FF5205;
  margin-bottom: 20px;
}

/* line 160, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
.single-service__content .row .content p {
  font-size: 1.8rem;
}

/* line 165, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
.single-service__content .row img {
  width: 45%;
  height: auto;
}

@media (max-width: 850px) {
  /* line 165, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
  .single-service__content .row img {
    width: 100%;
  }
}

/* line 176, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
.single-service__content .row:nth-child(even) img {
  order: -1;
}

@media (max-width: 850px) {
  /* line 176, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
  .single-service__content .row:nth-child(even) img {
    order: initial;
  }
}

/* line 185, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
.single-service__content .row:not(:last-of-type) {
  margin-bottom: 10rem;
}

/* line 191, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
.single-service__more {
  background: #FAFAFA;
  color: #090C10;
}

/* line 197, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
.single-service__more .has-orange-separator:before {
  bottom: 20px;
  border-left: 2px solid #FAFAFA;
  border-right: 2px solid #FAFAFA;
}

/* line 206, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
.content-services-centered {
  text-align: center;
}

/* line 209, public/wp-content/themes/lesage/assets/src/scss/layout/_single-service.scss */
.content-services-centered p {
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
}

/* line 3, public/wp-content/themes/lesage/assets/src/scss/layout/_single-article.scss */
.single-article img {
  transform: scale(1.2);
  margin: 80px 0;
}

/* line 8, public/wp-content/themes/lesage/assets/src/scss/layout/_single-article.scss */
.single-article a {
  color: #FF5205;
}

/* line 14, public/wp-content/themes/lesage/assets/src/scss/layout/_single-article.scss */
.single-article ul li, .single-article ol li {
  font-size: 20px;
  line-height: 28px;
}

/* line 23, public/wp-content/themes/lesage/assets/src/scss/layout/_single-article.scss */
.blogue.single-article-blog .content {
  padding-top: 0;
}

/* line 27, public/wp-content/themes/lesage/assets/src/scss/layout/_single-article.scss */
.blogue.single-article-blog .wrapper {
  padding-top: 0 !important;
}

/* line 1, public/wp-content/themes/lesage/assets/src/scss/layout/_equipements.scss */
.equipements {
  background: #FAFAFA;
  color: #090C10;
}

/* line 7, public/wp-content/themes/lesage/assets/src/scss/layout/_equipements.scss */
.equipements .has-orange-separator:before {
  border-color: #FAFAFA !important;
}

/* line 12, public/wp-content/themes/lesage/assets/src/scss/layout/_equipements.scss */
.equipements__list {
  display: flex;
  flex-flow: row wrap;
  margin-right: -20px;
  width: 100%;
}

/* line 19, public/wp-content/themes/lesage/assets/src/scss/layout/_equipements.scss */
.equipements__list-item {
  width: calc(33.333% - 10px);
  margin-bottom: 30px;
  margin-right: 10px;
}

@media (max-width: 1024px) {
  /* line 19, public/wp-content/themes/lesage/assets/src/scss/layout/_equipements.scss */
  .equipements__list-item {
    width: calc(50% - 10px);
  }
}

@media (max-width: 480px) {
  /* line 19, public/wp-content/themes/lesage/assets/src/scss/layout/_equipements.scss */
  .equipements__list-item {
    width: 100%;
    margin-right: 0;
  }
}

/* line 34, public/wp-content/themes/lesage/assets/src/scss/layout/_equipements.scss */
.equipements__list-item .equipement-image {
  height: 20vw;
  max-height: 450px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 10px;
}

@media (max-width: 1024px) {
  /* line 34, public/wp-content/themes/lesage/assets/src/scss/layout/_equipements.scss */
  .equipements__list-item .equipement-image {
    height: 30vw;
  }
}

@media (max-width: 480px) {
  /* line 34, public/wp-content/themes/lesage/assets/src/scss/layout/_equipements.scss */
  .equipements__list-item .equipement-image {
    height: 50vw;
  }
}

/* line 51, public/wp-content/themes/lesage/assets/src/scss/layout/_equipements.scss */
.equipements__list-item h3 {
  font-size: 25px;
  line-height: 30px;
  margin-bottom: 0;
}

@media (max-width: 1024px) {
  /* line 51, public/wp-content/themes/lesage/assets/src/scss/layout/_equipements.scss */
  .equipements__list-item h3 {
    font-size: 20px;
    line-height: 25px;
  }
}

/* line 62, public/wp-content/themes/lesage/assets/src/scss/layout/_equipements.scss */
.equipements__list-item .type {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #707070;
}

/* line 1, public/wp-content/themes/lesage/assets/src/scss/layout/_blogue.scss */
.blogue {
  background: #FAFAFA;
  padding-bottom: 10rem;
}

/* line 5, public/wp-content/themes/lesage/assets/src/scss/layout/_blogue.scss */
.blogue .wrapper {
  padding: 10rem 0 5rem;
}

/* line 9, public/wp-content/themes/lesage/assets/src/scss/layout/_blogue.scss */
.blogue .content {
  padding-bottom: 0;
}

@media (max-width: 768px) {
  /* line 12, public/wp-content/themes/lesage/assets/src/scss/layout/_blogue.scss */
  .blogue .content.flex--row.ju-sb {
    flex-flow: column wrap;
    justify-content: flex-start;
  }
}

/* line 26, public/wp-content/themes/lesage/assets/src/scss/layout/_blogue.scss */
.blogue h3.h2 {
  text-transform: uppercase;
}

/* line 30, public/wp-content/themes/lesage/assets/src/scss/layout/_blogue.scss */
.blogue .h2,
.blogue h4 {
  color: #090C10;
}

/* line 36, public/wp-content/themes/lesage/assets/src/scss/layout/_blogue.scss */
.blogue .h2.has-orange-separator:before,
.blogue h4.has-orange-separator:before {
  border-color: #FAFAFA;
}

/* line 42, public/wp-content/themes/lesage/assets/src/scss/layout/_blogue.scss */
.blogue__listing {
  display: flex;
  flex-flow: row wrap;
  margin-right: -20px;
}

@media (max-width: 768px) {
  /* line 42, public/wp-content/themes/lesage/assets/src/scss/layout/_blogue.scss */
  .blogue__listing {
    margin-right: 0;
  }
}

/* line 52, public/wp-content/themes/lesage/assets/src/scss/layout/_blogue.scss */
.blogue__item {
  width: calc(33.333% - 20px);
  margin-right: 20px;
  margin-bottom: 40px;
}

@media (max-width: 768px) {
  /* line 52, public/wp-content/themes/lesage/assets/src/scss/layout/_blogue.scss */
  .blogue__item {
    width: 100%;
    margin-right: 0;
  }
}

/* line 62, public/wp-content/themes/lesage/assets/src/scss/layout/_blogue.scss */
.blogue__item > a {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  min-height: 300px;
  position: relative;
}

@media (max-width: 1024px) {
  /* line 62, public/wp-content/themes/lesage/assets/src/scss/layout/_blogue.scss */
  .blogue__item > a {
    min-height: initial;
    height: 25vw;
  }
}

@media (max-width: 768px) {
  /* line 62, public/wp-content/themes/lesage/assets/src/scss/layout/_blogue.scss */
  .blogue__item > a {
    height: 40vw;
  }
}

/* line 84, public/wp-content/themes/lesage/assets/src/scss/layout/_blogue.scss */
.blogue__item > a:hover:before {
  background: rgba(9, 12, 16, 0.5);
}

/* line 88, public/wp-content/themes/lesage/assets/src/scss/layout/_blogue.scss */
.blogue__item > a:hover span {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

/* line 95, public/wp-content/themes/lesage/assets/src/scss/layout/_blogue.scss */
.blogue__item > a:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(9, 12, 16, 0);
  transition: all 200ms ease;
}

/* line 106, public/wp-content/themes/lesage/assets/src/scss/layout/_blogue.scss */
.blogue__item > a span {
  position: relative;
  z-index: 20;
  color: #FAFAFA;
  font-size: 1.8rem;
  line-height: 2.4rem;
  transform: translateY(20px);
  transition: all 200ms ease;
  opacity: 0;
  visibility: hidden;
}

/* line 117, public/wp-content/themes/lesage/assets/src/scss/layout/_blogue.scss */
.blogue__item > a span i {
  color: #FF5205;
  margin-left: 5px;
  font-size: 14px;
}

/* line 127, public/wp-content/themes/lesage/assets/src/scss/layout/_blogue.scss */
.blogue h3:not(.h2) {
  font-size: 2.2rem;
  margin-top: 20px;
  margin-bottom: 10px;
}

@media (max-width: 1024px) {
  /* line 127, public/wp-content/themes/lesage/assets/src/scss/layout/_blogue.scss */
  .blogue h3:not(.h2) {
    font-size: 1.8rem;
  }
}

/* line 138, public/wp-content/themes/lesage/assets/src/scss/layout/_blogue.scss */
.blogue h3:not(.h2):hover a {
  color: #FF5205;
}

/* line 143, public/wp-content/themes/lesage/assets/src/scss/layout/_blogue.scss */
.blogue h3:not(.h2) a {
  color: #090C10;
  transition: all 200ms ease;
}

/* line 149, public/wp-content/themes/lesage/assets/src/scss/layout/_blogue.scss */
.blogue .date {
  color: #090C10;
  font-size: 1.8rem;
  line-height: 2.4rem;
}

/* line 1, public/wp-content/themes/lesage/assets/src/scss/layout/_commercial.scss */
.commercial__banner {
  height: 100%;
  background-color: #090C10;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* line 8, public/wp-content/themes/lesage/assets/src/scss/layout/_commercial.scss */
.commercial__banner:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(9, 12, 16, 0.7);
}

/* line 18, public/wp-content/themes/lesage/assets/src/scss/layout/_commercial.scss */
.commercial__banner .container {
  height: 100%;
}

/* line 21, public/wp-content/themes/lesage/assets/src/scss/layout/_commercial.scss */
.commercial__banner .container h1 {
  text-align: center;
}

/* line 25, public/wp-content/themes/lesage/assets/src/scss/layout/_commercial.scss */
.commercial__banner .container .pre-title {
  display: block;
  font-size: 2.4rem;
  line-height: 3rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #707070;
  text-align: center;
}

@media (max-width: 768px) {
  /* line 25, public/wp-content/themes/lesage/assets/src/scss/layout/_commercial.scss */
  .commercial__banner .container .pre-title {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

/* line 40, public/wp-content/themes/lesage/assets/src/scss/layout/_commercial.scss */
.commercial__banner .container button {
  position: absolute;
  bottom: 6rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 40px;
  height: 40px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  outline: 0;
  border: 0;
  background: transparent;
  cursor: pointer;
}

/* line 60, public/wp-content/themes/lesage/assets/src/scss/layout/_commercial.scss */
.commercial-10-years {
  background: #FAFAFA;
  color: #090C10;
}

/* line 64, public/wp-content/themes/lesage/assets/src/scss/layout/_commercial.scss */
.commercial-10-years .wrapper {
  padding-bottom: 10rem;
}

/* line 69, public/wp-content/themes/lesage/assets/src/scss/layout/_commercial.scss */
.commercial-carousel {
  background: #FAFAFA;
  color: #090C10;
}

/* line 73, public/wp-content/themes/lesage/assets/src/scss/layout/_commercial.scss */
.commercial-carousel .content {
  padding: 5rem 10rem;
}

@media (max-width: 768px) {
  /* line 73, public/wp-content/themes/lesage/assets/src/scss/layout/_commercial.scss */
  .commercial-carousel .content {
    padding: 5rem 0;
  }
}

/* line 83, public/wp-content/themes/lesage/assets/src/scss/layout/_commercial.scss */
.commercial-carousel .carousel__dots ul {
  justify-content: flex-end;
}

/* line 86, public/wp-content/themes/lesage/assets/src/scss/layout/_commercial.scss */
.commercial-carousel .carousel__dots ul li {
  margin-right: 0;
  margin-left: 10px;
}

/* line 92, public/wp-content/themes/lesage/assets/src/scss/layout/_commercial.scss */
.commercial-carousel .carousel__dots ul li.slick-active button {
  color: #FF5205;
}

/* line 101, public/wp-content/themes/lesage/assets/src/scss/layout/_commercial.scss */
.commercial-partenaires {
  padding: 10rem 0 20rem;
  background: #FAFAFA;
  color: #090C10;
}

/* line 106, public/wp-content/themes/lesage/assets/src/scss/layout/_commercial.scss */
.commercial-partenaires .content {
  padding-bottom: 5rem;
}

/* line 110, public/wp-content/themes/lesage/assets/src/scss/layout/_commercial.scss */
.commercial-partenaires__list {
  list-style-type: none;
  padding: 0;
  margin: 0 -30px 0 0;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

@media (max-width: 375px) {
  /* line 110, public/wp-content/themes/lesage/assets/src/scss/layout/_commercial.scss */
  .commercial-partenaires__list {
    margin: 0;
    flex-flow: column wrap;
    align-items: center;
  }
}

/* line 124, public/wp-content/themes/lesage/assets/src/scss/layout/_commercial.scss */
.commercial-partenaires__list li {
  width: calc(20% - 30px);
  margin-right: 30px;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  /* line 124, public/wp-content/themes/lesage/assets/src/scss/layout/_commercial.scss */
  .commercial-partenaires__list li {
    width: calc(33.333% - 30px);
  }
}

@media (max-width: 480px) {
  /* line 124, public/wp-content/themes/lesage/assets/src/scss/layout/_commercial.scss */
  .commercial-partenaires__list li {
    width: calc(50% - 30px);
  }
}

@media (max-width: 375px) {
  /* line 124, public/wp-content/themes/lesage/assets/src/scss/layout/_commercial.scss */
  .commercial-partenaires__list li {
    width: 100%;
    margin-right: 0;
  }
}

/* line 1, public/wp-content/themes/lesage/assets/src/scss/layout/_faq.scss */
.faq {
  background: #FAFAFA;
  color: #090C10;
  padding-bottom: 10rem;
}

/* line 6, public/wp-content/themes/lesage/assets/src/scss/layout/_faq.scss */
.faq .wrapper {
  padding: 10rem 0 5rem;
}

/* line 10, public/wp-content/themes/lesage/assets/src/scss/layout/_faq.scss */
.faq .content {
  padding-bottom: 0;
}

/* line 1, public/wp-content/themes/lesage/assets/src/scss/layout/_contact.scss */
.contact-info {
  display: flex;
  flex-flow: row wrap;
  margin-top: 20px;
}

@media (max-width: 768px) {
  /* line 1, public/wp-content/themes/lesage/assets/src/scss/layout/_contact.scss */
  .contact-info {
    width: 100%;
  }
}

/* line 10, public/wp-content/themes/lesage/assets/src/scss/layout/_contact.scss */
.contact-info__col {
  display: flex;
  flex-flow: column wrap;
  min-width: 200px;
  margin-top: 30px;
}

@media (max-width: 480px) {
  /* line 10, public/wp-content/themes/lesage/assets/src/scss/layout/_contact.scss */
  .contact-info__col {
    min-width: 100%;
  }
}

/* line 20, public/wp-content/themes/lesage/assets/src/scss/layout/_contact.scss */
.contact-info__col:first-of-type {
  margin-right: 40px;
}

/* line 24, public/wp-content/themes/lesage/assets/src/scss/layout/_contact.scss */
.contact-info__col a {
  margin-bottom: 20px;
  color: #FAFAFA;
}

/* line 28, public/wp-content/themes/lesage/assets/src/scss/layout/_contact.scss */
.contact-info__col a.contact-address {
  color: #FF5205;
  text-decoration: underline;
}

/* line 33, public/wp-content/themes/lesage/assets/src/scss/layout/_contact.scss */
.contact-info__col a:last-of-type {
  margin-bottom: 0;
}

/* line 38, public/wp-content/themes/lesage/assets/src/scss/layout/_contact.scss */
.contact-info__col strong {
  margin-bottom: 10px;
}

/* line 42, public/wp-content/themes/lesage/assets/src/scss/layout/_contact.scss */
.contact-info__col p {
  font-size: 15px;
  line-height: 22px;
  margin: 0 0 10px;
}

/* line 50, public/wp-content/themes/lesage/assets/src/scss/layout/_contact.scss */
.contact {
  background: #090C10;
  color: #FAFAFA;
}

/* line 54, public/wp-content/themes/lesage/assets/src/scss/layout/_contact.scss */
.contact h2 {
  margin-top: 100px;
}

/* line 58, public/wp-content/themes/lesage/assets/src/scss/layout/_contact.scss */
.contact .wrapper {
  padding: 10rem 0 5rem;
}

/* line 62, public/wp-content/themes/lesage/assets/src/scss/layout/_contact.scss */
.contact .content {
  padding-bottom: 0;
}

/* line 66, public/wp-content/themes/lesage/assets/src/scss/layout/_contact.scss */
.contact__row {
  display: flex;
  flex-flow: row wrap;
}

/* line 71, public/wp-content/themes/lesage/assets/src/scss/layout/_contact.scss */
.contact__col {
  width: 50%;
}

@media (max-width: 1024px) {
  /* line 71, public/wp-content/themes/lesage/assets/src/scss/layout/_contact.scss */
  .contact__col {
    width: 100%;
  }
}

/* line 78, public/wp-content/themes/lesage/assets/src/scss/layout/_contact.scss */
.contact__col:first-of-type {
  padding: 15rem 10rem;
}

@media (max-width: 1200px) {
  /* line 78, public/wp-content/themes/lesage/assets/src/scss/layout/_contact.scss */
  .contact__col:first-of-type {
    padding: 10rem 5rem;
  }
}

@media (max-width: 1024px) {
  /* line 78, public/wp-content/themes/lesage/assets/src/scss/layout/_contact.scss */
  .contact__col:first-of-type {
    padding: 10rem 0rem;
  }
}

/* line 90, public/wp-content/themes/lesage/assets/src/scss/layout/_contact.scss */
.contact__col #map {
  height: 100%;
  width: 100%;
}

@media (max-width: 1024px) {
  /* line 90, public/wp-content/themes/lesage/assets/src/scss/layout/_contact.scss */
  .contact__col #map {
    height: 60vw;
  }
}

@media (max-width: 480px) {
  /* line 90, public/wp-content/themes/lesage/assets/src/scss/layout/_contact.scss */
  .contact__col #map {
    height: 100vw;
  }
}

/* line 103, public/wp-content/themes/lesage/assets/src/scss/layout/_contact.scss */
.contact__col #map:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 50%;
  z-index: 9999;
  background: linear-gradient(to bottom, #090c10 0%, #090c10 5%, rgba(9, 12, 16, 0) 100%);
  pointer-events: none;
}

/* line 2, public/wp-content/themes/lesage/assets/src/scss/layout/_contact-new.scss */
.c-contact .c-contact_hero {
  padding: 15rem 15rem;
  position: relative;
}

@media (max-width: 900px) {
  /* line 2, public/wp-content/themes/lesage/assets/src/scss/layout/_contact-new.scss */
  .c-contact .c-contact_hero {
    padding: 15rem 0rem;
  }
}

@media (max-width: 768px) {
  /* line 2, public/wp-content/themes/lesage/assets/src/scss/layout/_contact-new.scss */
  .c-contact .c-contact_hero {
    padding: 15rem 2rem 10rem;
  }
}

/* line 14, public/wp-content/themes/lesage/assets/src/scss/layout/_contact-new.scss */
.c-contact .c-contact_hero h1 {
  padding-bottom: 3rem;
  width: 80%;
}

@media (max-width: 768px) {
  /* line 14, public/wp-content/themes/lesage/assets/src/scss/layout/_contact-new.scss */
  .c-contact .c-contact_hero h1 {
    width: 100%;
  }
}

/* line 24, public/wp-content/themes/lesage/assets/src/scss/layout/_contact-new.scss */
.c-contact .c-contact_hero .c-contact_description p {
  font-weight: 400;
  font-size: 1.7rem;
}

/* line 30, public/wp-content/themes/lesage/assets/src/scss/layout/_contact-new.scss */
.c-contact .c-contact_hero .c-contact_media {
  position: absolute;
  bottom: 0;
  right: 50px;
  height: 80%;
  z-index: -1;
}

@media (max-width: 1300px) {
  /* line 30, public/wp-content/themes/lesage/assets/src/scss/layout/_contact-new.scss */
  .c-contact .c-contact_hero .c-contact_media {
    display: none;
  }
}

/* line 41, public/wp-content/themes/lesage/assets/src/scss/layout/_contact-new.scss */
.c-contact .c-contact_hero .c-contact_media img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  opacity: 0.5;
}

@media (max-width: 1300px) {
  /* line 41, public/wp-content/themes/lesage/assets/src/scss/layout/_contact-new.scss */
  .c-contact .c-contact_hero .c-contact_media img {
    width: 75%;
  }
}

/* line 1, public/wp-content/themes/lesage/assets/src/scss/layout/_careers.scss */
.careers {
  background: #FAFAFA;
  color: #090C10;
}

/* line 5, public/wp-content/themes/lesage/assets/src/scss/layout/_careers.scss */
.careers .wrapper {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

@media (max-width: 768px) {
  /* line 10, public/wp-content/themes/lesage/assets/src/scss/layout/_careers.scss */
  .careers .content {
    padding: 0;
  }
}

/* line 20, public/wp-content/themes/lesage/assets/src/scss/layout/_careers.scss */
.career__cta {
  margin-top: 50px;
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}

/* line 26, public/wp-content/themes/lesage/assets/src/scss/layout/_careers.scss */
.career__cta a {
  width: 100%;
}

/* line 31, public/wp-content/themes/lesage/assets/src/scss/layout/_careers.scss */
.career__submit {
  background: #FF5205;
  color: #FAFAFA;
  text-align: center;
  padding: 20px 30px;
  max-width: 400px;
}

/* line 38, public/wp-content/themes/lesage/assets/src/scss/layout/_careers.scss */
.career__submit i {
  font-size: 12px;
  margin-left: 10px;
}

/* line 44, public/wp-content/themes/lesage/assets/src/scss/layout/_careers.scss */
.career__close {
  color: #090C10;
  text-align: center;
  margin-top: 20px;
}

/* line 1, public/wp-content/themes/lesage/assets/src/scss/layout/_soumission.scss */
.soumission__banner {
  height: 650px;
  background-color: #090C10;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* line 8, public/wp-content/themes/lesage/assets/src/scss/layout/_soumission.scss */
.soumission__banner .container {
  height: 100%;
}

/* line 11, public/wp-content/themes/lesage/assets/src/scss/layout/_soumission.scss */
.soumission__banner .container h1 {
  text-align: center;
}

/* line 15, public/wp-content/themes/lesage/assets/src/scss/layout/_soumission.scss */
.soumission__banner .container p {
  max-width: 750px;
  text-align: center;
}

/* line 20, public/wp-content/themes/lesage/assets/src/scss/layout/_soumission.scss */
.soumission__banner .container .pre-title {
  display: block;
  font-size: 2.4rem;
  line-height: 3rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #707070;
  text-align: center;
}

@media (max-width: 768px) {
  /* line 20, public/wp-content/themes/lesage/assets/src/scss/layout/_soumission.scss */
  .soumission__banner .container .pre-title {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

/* line 35, public/wp-content/themes/lesage/assets/src/scss/layout/_soumission.scss */
.soumission__banner .container button {
  position: absolute;
  bottom: 6rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 40px;
  height: 40px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  outline: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

/* line 55, public/wp-content/themes/lesage/assets/src/scss/layout/_soumission.scss */
.soumission__form {
  background: white;
  color: #090C10;
  padding: 10rem 0;
}

/* line 2, public/wp-content/themes/lesage/assets/src/scss/layout/_soumission-new.scss */
.c-soumission .c-soumission_hero {
  padding: 15rem;
  position: relative;
}

@media (max-width: 900px) {
  /* line 2, public/wp-content/themes/lesage/assets/src/scss/layout/_soumission-new.scss */
  .c-soumission .c-soumission_hero {
    padding: 15rem 0rem;
  }
}

@media (max-width: 768px) {
  /* line 2, public/wp-content/themes/lesage/assets/src/scss/layout/_soumission-new.scss */
  .c-soumission .c-soumission_hero {
    padding: 15rem 2rem 10rem;
  }
}

/* line 14, public/wp-content/themes/lesage/assets/src/scss/layout/_soumission-new.scss */
.c-soumission .c-soumission_hero h1 {
  margin: 0 auto;
  width: 70%;
  text-align: center;
}

@media (max-width: 768px) {
  /* line 14, public/wp-content/themes/lesage/assets/src/scss/layout/_soumission-new.scss */
  .c-soumission .c-soumission_hero h1 {
    width: 100%;
  }
}

/* line 25, public/wp-content/themes/lesage/assets/src/scss/layout/_soumission-new.scss */
.c-soumission .c-soumission_media {
  position: absolute;
  inset: 0;
  z-index: -1;
}

/* line 30, public/wp-content/themes/lesage/assets/src/scss/layout/_soumission-new.scss */
.c-soumission .c-soumission_media img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.5;
}

/* line 38, public/wp-content/themes/lesage/assets/src/scss/layout/_soumission-new.scss */
.c-soumission .c-soumission_description {
  padding: 10rem 15rem;
}

/* line 41, public/wp-content/themes/lesage/assets/src/scss/layout/_soumission-new.scss */
.c-soumission .c-soumission_description p {
  font-weight: 400;
  font-size: 1.7rem;
}

@media (max-width: 768px) {
  /* line 38, public/wp-content/themes/lesage/assets/src/scss/layout/_soumission-new.scss */
  .c-soumission .c-soumission_description {
    padding: 10rem 2rem;
  }
}

/* line 1, public/wp-content/themes/lesage/assets/src/scss/layout/_licenses.scss */
.licenses {
  background: #FAFAFA;
  color: #090C10;
}

/* line 5, public/wp-content/themes/lesage/assets/src/scss/layout/_licenses.scss */
.licenses .wrapper {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

/* line 10, public/wp-content/themes/lesage/assets/src/scss/layout/_licenses.scss */
.licenses h2 {
  margin-top: 100px;
}

/* line 14, public/wp-content/themes/lesage/assets/src/scss/layout/_licenses.scss */
.licenses p {
  max-width: 650px;
}

/* line 18, public/wp-content/themes/lesage/assets/src/scss/layout/_licenses.scss */
.licenses .bullet-list {
  list-style-type: none;
  padding: 0 0 0 30px;
}

/* line 22, public/wp-content/themes/lesage/assets/src/scss/layout/_licenses.scss */
.licenses .bullet-list li {
  padding: 0px;
  margin: 15px 0;
  font-size: 16px;
  line-height: 24px;
}

/* line 28, public/wp-content/themes/lesage/assets/src/scss/layout/_licenses.scss */
.licenses .bullet-list li:before {
  content: '•';
  color: #FF5205;
  margin-left: -26px;
  margin-right: 10px;
}

/* line 35, public/wp-content/themes/lesage/assets/src/scss/layout/_licenses.scss */
.licenses .bullet-list li:after {
  display: none;
}

/* line 1, public/wp-content/themes/lesage/assets/src/scss/layout/_thank-you.scss */
.thank-you__banner {
  height: 100%;
  background-color: #090C10;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* line 8, public/wp-content/themes/lesage/assets/src/scss/layout/_thank-you.scss */
.thank-you__banner .container {
  height: 100%;
}

/* line 11, public/wp-content/themes/lesage/assets/src/scss/layout/_thank-you.scss */
.thank-you__banner .container h1 {
  text-align: center;
}

/* line 15, public/wp-content/themes/lesage/assets/src/scss/layout/_thank-you.scss */
.thank-you__banner .container p {
  max-width: 750px;
  text-align: center;
}

/* line 20, public/wp-content/themes/lesage/assets/src/scss/layout/_thank-you.scss */
.thank-you__banner .container .pre-title {
  display: block;
  font-size: 2.4rem;
  line-height: 3rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: #707070;
  text-align: center;
}

@media (max-width: 768px) {
  /* line 20, public/wp-content/themes/lesage/assets/src/scss/layout/_thank-you.scss */
  .thank-you__banner .container .pre-title {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

/* line 35, public/wp-content/themes/lesage/assets/src/scss/layout/_thank-you.scss */
.thank-you__banner .container button {
  position: absolute;
  bottom: 6rem;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 40px;
  height: 40px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  outline: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

/* line 1, public/wp-content/themes/lesage/assets/src/scss/layout/_info.scss */
.c-info {
  padding: 15rem 20rem 0;
  background-color: #FAFAFA;
}

@media (max-width: 1300px) {
  /* line 1, public/wp-content/themes/lesage/assets/src/scss/layout/_info.scss */
  .c-info {
    padding: 15rem 10rem 0;
  }
}

@media (max-width: 768px) {
  /* line 1, public/wp-content/themes/lesage/assets/src/scss/layout/_info.scss */
  .c-info {
    padding: 10rem 2rem 0;
  }
}

/* line 13, public/wp-content/themes/lesage/assets/src/scss/layout/_info.scss */
.c-info .c-info_title {
  position: relative;
  margin-bottom: 80px;
  color: #090C10;
}

/* line 18, public/wp-content/themes/lesage/assets/src/scss/layout/_info.scss */
.c-info .c-info_title::before {
  content: "";
  position: absolute;
  top: -48px;
  left: 0;
  width: 50px;
  height: 10px;
  background-image: url("../images/line.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

/* line 32, public/wp-content/themes/lesage/assets/src/scss/layout/_info.scss */
.c-info .c-info_content {
  display: flex;
  background-color: #090C10;
}

@media (max-width: 768px) {
  /* line 32, public/wp-content/themes/lesage/assets/src/scss/layout/_info.scss */
  .c-info .c-info_content {
    flex-direction: column;
  }
}

/* line 40, public/wp-content/themes/lesage/assets/src/scss/layout/_info.scss */
.c-info .c-info_content .c-info_media {
  width: 45%;
}

@media (max-width: 768px) {
  /* line 40, public/wp-content/themes/lesage/assets/src/scss/layout/_info.scss */
  .c-info .c-info_content .c-info_media {
    width: 100%;
  }
}

/* line 48, public/wp-content/themes/lesage/assets/src/scss/layout/_info.scss */
.c-info .c-info_content .c-info_information {
  width: 55%;
}

@media (max-width: 768px) {
  /* line 48, public/wp-content/themes/lesage/assets/src/scss/layout/_info.scss */
  .c-info .c-info_content .c-info_information {
    width: 100%;
  }
}

/* line 56, public/wp-content/themes/lesage/assets/src/scss/layout/_info.scss */
.c-info .c-info_content .c-info_information {
  display: flex;
  gap: 40px;
  padding: 48px 40px;
}

@media (max-width: 1355px) and (min-width: 1300px) {
  /* line 56, public/wp-content/themes/lesage/assets/src/scss/layout/_info.scss */
  .c-info .c-info_content .c-info_information {
    padding: 48px 25px;
  }
}

@media (max-width: 1045px) {
  /* line 56, public/wp-content/themes/lesage/assets/src/scss/layout/_info.scss */
  .c-info .c-info_content .c-info_information {
    flex-direction: column;
    padding: 4rem 1.5rem;
  }
}

@media (max-width: 768px) {
  /* line 56, public/wp-content/themes/lesage/assets/src/scss/layout/_info.scss */
  .c-info .c-info_content .c-info_information {
    padding: 4rem;
  }
}

/* line 74, public/wp-content/themes/lesage/assets/src/scss/layout/_info.scss */
.c-info .c-info_content .c-info_information h4 {
  font-size: 15px;
}

/* line 78, public/wp-content/themes/lesage/assets/src/scss/layout/_info.scss */
.c-info .c-info_content .c-info_information .c-info_address,
.c-info .c-info_content .c-info_information .c-info_contact {
  width: 50%;
}

@media (max-width: 1000px) {
  /* line 78, public/wp-content/themes/lesage/assets/src/scss/layout/_info.scss */
  .c-info .c-info_content .c-info_information .c-info_address,
  .c-info .c-info_content .c-info_information .c-info_contact {
    width: 100%;
  }
}

/* line 87, public/wp-content/themes/lesage/assets/src/scss/layout/_info.scss */
.c-info .c-info_content .c-info_information .c-info_contact {
  display: flex;
  flex-direction: column;
  gap: 4rem;
}

/* line 92, public/wp-content/themes/lesage/assets/src/scss/layout/_info.scss */
.c-info .c-info_content .c-info_information .c-info_contact h4 {
  margin-bottom: 10px;
}

/* line 97, public/wp-content/themes/lesage/assets/src/scss/layout/_info.scss */
.c-info .c-info_content .c-info_information .c-info_address {
  display: flex;
  flex-direction: column;
}

/* line 101, public/wp-content/themes/lesage/assets/src/scss/layout/_info.scss */
.c-info .c-info_content .c-info_information .c-info_address h4 {
  margin-bottom: 10px;
}

/* line 105, public/wp-content/themes/lesage/assets/src/scss/layout/_info.scss */
.c-info .c-info_content .c-info_information .c-info_address div {
  padding-bottom: 4rem;
  font-weight: 400;
  line-height: 2;
}

/* line 111, public/wp-content/themes/lesage/assets/src/scss/layout/_info.scss */
.c-info .c-info_content .c-info_information .c-info_address a {
  color: #FF5205;
  text-decoration: underline;
  font-size: 15px;
  font-weight: 400;
}

/* line 121, public/wp-content/themes/lesage/assets/src/scss/layout/_info.scss */
.c-info .c-info_content .c-info_information .c-info_contact div a {
  color: #fff;
  font-weight: 400;
}

/* line 127, public/wp-content/themes/lesage/assets/src/scss/layout/_info.scss */
.c-info .c-info_content .c-info_information .c-info_contact .c-info_socials {
  display: flex;
  gap: 20px;
}

/* line 134, public/wp-content/themes/lesage/assets/src/scss/layout/_info.scss */
.c-info .c-info_content .c-info_media {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 72px;
  background-color: #f5f5f5;
}

/* line 1, public/wp-content/themes/lesage/assets/src/scss/layout/_review.scss */
.c-review {
  background-color: #FAFAFA;
  color: #090C10;
  padding: 15rem 20rem;
}

@media (max-width: 1300px) {
  /* line 1, public/wp-content/themes/lesage/assets/src/scss/layout/_review.scss */
  .c-review {
    padding: 15rem 10rem;
  }
}

@media (max-width: 768px) {
  /* line 1, public/wp-content/themes/lesage/assets/src/scss/layout/_review.scss */
  .c-review {
    padding: 10rem 2rem;
  }
}

/* line 14, public/wp-content/themes/lesage/assets/src/scss/layout/_review.scss */
.c-review .c-review_content {
  border: 2px solid #e4e4e4;
  padding: 70px 0;
}

/* line 18, public/wp-content/themes/lesage/assets/src/scss/layout/_review.scss */
.c-review .c-review_content h4 {
  text-align: center;
}

/* line 23, public/wp-content/themes/lesage/assets/src/scss/layout/_review.scss */
.c-review .c-review_content .slick-slider .slick-arrow {
  position: absolute;
  top: 25%;
  border: 1px solid #eaeaea;
  background-color: transparent;
  width: 40px;
  height: 40px;
  z-index: 50;
  border-radius: 30px;
  cursor: pointer;
}

/* line 35, public/wp-content/themes/lesage/assets/src/scss/layout/_review.scss */
.c-review .c-review_content .slick-slider .slick-prev {
  left: 10%;
  color: transparent !important;
}

/* line 39, public/wp-content/themes/lesage/assets/src/scss/layout/_review.scss */
.c-review .c-review_content .slick-slider .slick-prev::after {
  content: url("../images/prev.svg");
  position: absolute;
  top: 11px;
  right: 10px;
}

@media (max-width: 630px) {
  /* line 35, public/wp-content/themes/lesage/assets/src/scss/layout/_review.scss */
  .c-review .c-review_content .slick-slider .slick-prev {
    left: 2%;
  }
}

/* line 51, public/wp-content/themes/lesage/assets/src/scss/layout/_review.scss */
.c-review .c-review_content .slick-slider .slick-next {
  left: 85%;
  color: transparent !important;
}

/* line 55, public/wp-content/themes/lesage/assets/src/scss/layout/_review.scss */
.c-review .c-review_content .slick-slider .slick-next::after {
  content: url("../images/next.svg");
  position: absolute;
  top: 11px;
  right: 10px;
}

@media (max-width: 630px) {
  /* line 51, public/wp-content/themes/lesage/assets/src/scss/layout/_review.scss */
  .c-review .c-review_content .slick-slider .slick-next {
    left: 90%;
  }
}

@media (max-width: 500px) {
  /* line 51, public/wp-content/themes/lesage/assets/src/scss/layout/_review.scss */
  .c-review .c-review_content .slick-slider .slick-next {
    left: 87%;
  }
}

@media (max-width: 400px) {
  /* line 51, public/wp-content/themes/lesage/assets/src/scss/layout/_review.scss */
  .c-review .c-review_content .slick-slider .slick-next {
    left: 84%;
  }
}

/* line 76, public/wp-content/themes/lesage/assets/src/scss/layout/_review.scss */
.c-review .c-review_content .c-review_text {
  padding: 5rem 25rem;
  text-align: center;
}

@media (max-width: 1300px) {
  /* line 76, public/wp-content/themes/lesage/assets/src/scss/layout/_review.scss */
  .c-review .c-review_content .c-review_text {
    padding: 5rem 15rem;
  }
}

@media (max-width: 768px) {
  /* line 76, public/wp-content/themes/lesage/assets/src/scss/layout/_review.scss */
  .c-review .c-review_content .c-review_text {
    padding: 5rem 6rem;
  }
}

/* line 88, public/wp-content/themes/lesage/assets/src/scss/layout/_review.scss */
.c-review .c-review_content .c-review_text h4 {
  margin-top: 15px;
  font-size: 18px;
}

/* line 93, public/wp-content/themes/lesage/assets/src/scss/layout/_review.scss */
.c-review .c-review_content .c-review_text p {
  font-size: 16px;
  font-weight: 400;
}

/* line 98, public/wp-content/themes/lesage/assets/src/scss/layout/_review.scss */
.c-review .c-review_content .c-review_text .c-btn {
  margin-top: 50px;
}

/* line 103, public/wp-content/themes/lesage/assets/src/scss/layout/_review.scss */
.c-review .c-review_content .c-review_rating {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-bottom: 20px;
}

/* line 110, public/wp-content/themes/lesage/assets/src/scss/layout/_review.scss */
.c-review .c-review_content .c-review_rating .note,
.c-review .c-review_content .c-review_rating .avis {
  font-size: 18px;
  font-weight: 400;
  color: #4d4d4d;
}

/* line 117, public/wp-content/themes/lesage/assets/src/scss/layout/_review.scss */
.c-review .c-review_content .c-review_rating .stars {
  display: flex;
  align-items: center;
  gap: 10px;
}

/* line 124, public/wp-content/themes/lesage/assets/src/scss/layout/_review.scss */
.c-review .c-review_content .c-review_rating .grey-star::after {
  content: url(../images/star-grey.svg);
  width: 25px;
  height: 25px;
}

/* line 132, public/wp-content/themes/lesage/assets/src/scss/layout/_review.scss */
.c-review .c-review_content .c-review_rating .full-star::after {
  content: url(../images/star.svg);
  width: 25px;
  height: 25px;
}

/* line 140, public/wp-content/themes/lesage/assets/src/scss/layout/_review.scss */
.c-review .c-review_content .c-review_rating .half-star::after {
  content: url(../images/demi-star.svg);
  width: 25px;
  height: 25px;
}

/* line 149, public/wp-content/themes/lesage/assets/src/scss/layout/_review.scss */
.c-review .c-btn {
  text-align: center;
}

/* =======================================================================
Components
========================================================================== */
/* line 1, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button, .c-button--style-1, .c-button--style-2, .c-button--skewed, .c-button--skewed-2, .c-button--skewed-3, .c-button--transparent {
  padding: 12px 30px;
  color: white;
  position: relative;
  font-size: 1.5rem;
  cursor: pointer;
}

/* line 9, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--style-1 {
  border: 1px solid #FF5205;
  background: rgba(255, 82, 5, 0.4);
}

/* line 14, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--style-1:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 30px;
  right: 30px;
  height: 3px;
  background: #FF5205;
  z-index: -1;
}

/* line 28, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--style-1.animation-in:before {
  animation: style-1-in 400ms forwards ease;
}

/* line 35, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--style-1.animation-out:before {
  animation: style-1-out 400ms forwards ease;
}

/* line 42, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--style-2 {
  border: 1px solid #FF5205;
  background: #FF5205;
  font-weight: 400;
  display: inline-flex;
}

/* line 49, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--style-2:before {
  content: url("../images/heart.svg");
  margin-right: 10px;
  width: 20px;
  height: 20px;
  padding-top: 2px;
}

/* line 58, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--skewed {
  border: 1px solid #FF5205;
  background: rgba(255, 82, 5, 0.4);
  transform: skewX(-10deg);
  position: relative;
  display: inline-block;
}

/* line 66, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--skewed:before {
  content: '';
  position: absolute;
  top: -1px;
  bottom: -1px;
  right: 0;
  width: 0;
  background: #FF5205;
  z-index: -1;
  transition: all 300ms ease;
}

/* line 78, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--skewed:after {
  content: '+';
  font-size: 2rem;
  position: absolute;
  top: -1px;
  bottom: -1px;
  right: -50px;
  width: 50px;
  background: #FF5205;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  z-index: -1;
  border-right: 1px solid #FF5205;
}

/* line 95, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--skewed span {
  display: inline-block;
  transform: skewX(10deg);
}

/* line 102, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--skewed:hover:before {
  width: 100%;
}

/* line 109, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--skewed-2 {
  transform: skewX(-10deg);
  position: relative;
  display: inline-block;
  color: #090C10;
  transition: all 200ms ease;
}

@media (max-width: 768px) {
  /* line 109, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
  .c-button--skewed-2 {
    color: #FAFAFA;
  }
}

/* line 121, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--skewed-2:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 0;
  background: #FF5205;
  z-index: -1;
  transition: all 200ms ease;
}

@media (max-width: 768px) {
  /* line 121, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
  .c-button--skewed-2:before {
    width: 100%;
  }
}

/* line 137, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--skewed-2:after {
  content: "\f009";
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  color: #FAFAFA;
  font-size: 14px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: -50px;
  width: 50px;
  background: #FF5205;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  z-index: -1;
  border-right: 1px solid #FF5205;
}

/* line 158, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--skewed-2 span {
  display: inline-block;
  transform: skewX(10deg);
}

/* line 163, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--skewed-2:hover {
  color: #FAFAFA;
}

/* line 166, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--skewed-2:hover:before {
  width: 100%;
}

/* line 172, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--skewed-3 {
  border: 1px solid #FF5205;
  background-color: #FF5205;
  transform: skewX(-25deg);
  position: relative;
  display: inline-block;
  padding: 15px 170px;
  transition: 200ms;
  font-family: 'futura-pt';
}

@media (max-width: 768px) {
  /* line 172, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
  .c-button--skewed-3 {
    padding: 12px 0;
  }
}

/* line 187, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--skewed-3:before {
  content: '';
  position: absolute;
  top: -1px;
  bottom: -1px;
  right: 0;
  width: 0;
  background: #FF5205;
  z-index: -1;
  transition: all 300ms ease;
}

/* line 199, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--skewed-3:after {
  content: '+';
  font-size: 2rem;
  position: absolute;
  top: -1px;
  bottom: -1px;
  right: -50px;
  width: 50px;
  background: #FF5205;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  z-index: -1;
  border-right: 1px solid #FF5205;
}

/* line 216, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--skewed-3 span {
  display: inline-block;
  transform: skewX(10deg);
}

/* line 221, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--skewed-3:hover {
  background-color: #fff;
  border-color: 1px solid #FF5205;
  color: #FF5205;
}

/* line 226, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--skewed-3:hover:before {
  width: 100%;
}

/* line 232, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--transparent {
  position: relative;
  padding: 8px 0;
}

/* line 237, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--transparent:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  border-bottom: 3px solid #FF5205;
  width: 15px;
  transition: all 300ms ease;
}

/* line 247, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--transparent i {
  font-size: 12px;
  margin-left: 5px;
  transition: all 300ms ease;
}

/* line 255, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--transparent:hover:after {
  width: 100%;
}

/* line 259, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--transparent:hover i {
  color: #FF5205;
}

/* line 265, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--filter {
  border: 1px solid #707070;
  display: none;
  flex-flow: row wrap;
  align-items: center;
}

/* line 271, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--filter .filter-icon {
  display: block;
  width: 12px;
  height: 6px;
  border-top: 2px solid #FF5205;
  border-bottom: 2px solid #FF5205;
  margin-left: 10px;
  position: relative;
}

/* line 280, public/wp-content/themes/lesage/assets/src/scss/components/_button.scss */
.c-button--filter .filter-icon:before {
  content: "";
  position: absolute;
  top: -3px;
  left: 3px;
  height: 8px;
  border-right: 2px solid #090c10;
}

@keyframes style-1-in {
  0% {
    left: 30px;
    right: 30px;
    height: 3px;
  }
  50% {
    left: 0px;
    right: 0px;
    height: 3px;
  }
  100% {
    left: 0px;
    right: 0px;
    height: 100%;
  }
}

@keyframes style-1-out {
  0% {
    left: 0px;
    right: 0px;
    height: 100%;
  }
  50% {
    left: 0px;
    right: 0px;
    height: 3px;
  }
  100% {
    left: 30px;
    right: 30px;
    height: 3px;
  }
}

/* line 1, public/wp-content/themes/lesage/assets/src/scss/components/_orange-bar.scss */
.c-orange-bar {
  display: flex;
  flex-flow: row nowrap;
  position: absolute;
  width: 75%;
  left: -20px;
  bottom: 0;
  height: 200px;
  transform: translateY(50%);
}

@media (max-width: 768px) {
  /* line 1, public/wp-content/themes/lesage/assets/src/scss/components/_orange-bar.scss */
  .c-orange-bar {
    height: 150px;
    width: 85%;
  }
}

@media (max-width: 480px) {
  /* line 1, public/wp-content/themes/lesage/assets/src/scss/components/_orange-bar.scss */
  .c-orange-bar {
    height: 100px;
    width: 85%;
  }
}

/* line 21, public/wp-content/themes/lesage/assets/src/scss/components/_orange-bar.scss */
.c-orange-bar.flipped {
  transform: translateY(50%) scale(-1, 1);
  left: initial;
  right: -20px;
}

/* line 29, public/wp-content/themes/lesage/assets/src/scss/components/_orange-bar.scss */
.c-orange-bar.animated div {
  transform: skewX(10deg) scale(1);
}

/* line 35, public/wp-content/themes/lesage/assets/src/scss/components/_orange-bar.scss */
.c-orange-bar div {
  height: 100%;
  border-top: 10px solid #FAFAFA;
  border-right: 10px solid #FAFAFA;
  background: #FF5205;
  transform: skewX(10deg) scale(0);
  transition: all 500ms ease;
  transform-origin: bottom left;
}

/* line 44, public/wp-content/themes/lesage/assets/src/scss/components/_orange-bar.scss */
.c-orange-bar div:nth-of-type(1) {
  flex-grow: 1;
}

/* line 48, public/wp-content/themes/lesage/assets/src/scss/components/_orange-bar.scss */
.c-orange-bar div:nth-of-type(2), .c-orange-bar div:nth-of-type(3) {
  flex-basis: 25%;
  margin-left: 30px;
}

@media (max-width: 480px) {
  /* line 48, public/wp-content/themes/lesage/assets/src/scss/components/_orange-bar.scss */
  .c-orange-bar div:nth-of-type(2), .c-orange-bar div:nth-of-type(3) {
    margin-left: 15px;
  }
}

/* line 3, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
.carousel:before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 150px;
  background: #FAFAFA;
  z-index: -1;
}

@media (max-width: 768px) {
  /* line 14, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
  .carousel .container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 768px) {
  /* line 21, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
  .carousel .container .content {
    padding-left: 0;
    padding-right: 0;
  }
}

/* line 30, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
.carousel .content {
  padding-bottom: 0;
}

/* line 34, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
.carousel-slide {
  height: 40vw;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 768px) {
  /* line 34, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
  .carousel-slide {
    height: 500px;
  }
}

@media (max-width: 480px) {
  /* line 34, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
  .carousel-slide {
    height: 380px;
  }
}

/* line 48, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
.carousel__dots {
  margin-bottom: 30px;
}

/* line 51, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
.carousel__dots ul {
  display: flex;
  flex-flow: row wrap;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

@media (max-width: 768px) {
  /* line 51, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
  .carousel__dots ul {
    justify-content: center;
  }
}

/* line 62, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
.carousel__dots ul li {
  margin-right: 8px;
}

/* line 65, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
.carousel__dots ul li button {
  appearance: none;
  border: 0;
  background-color: transparent;
  border-bottom: 4px solid #707070;
  width: 60px;
  text-align: left;
  padding: 0;
  color: #707070;
  font-family: "futura-pt";
  font-weight: 700;
  font-size: 18px;
  padding-bottom: 5px;
  outline: 0;
  transition: all 300ms ease;
  cursor: pointer;
}

/* line 82, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
.carousel__dots ul li button:before {
  content: '0';
}

/* line 88, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
.carousel__dots ul li.slick-active button {
  border-bottom: 4px solid #FF5205;
  color: #FAFAFA;
}

/* line 98, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
.five-lines {
  width: 200px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: -100px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  opacity: 0.7;
}

@media (max-width: 1024px) {
  /* line 98, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
  .five-lines {
    display: none;
  }
}

/* line 113, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
.five-lines div {
  height: 1px;
  border-bottom: 1px solid #707070;
  margin: 20px 0;
}

/* line 120, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
.testimonial-slider {
  padding-left: 200px;
  margin-top: 10rem;
}

@media (max-width: 1024px) {
  /* line 120, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
  .testimonial-slider {
    padding-left: 120px;
  }
}

@media (max-width: 768px) {
  /* line 120, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
  .testimonial-slider {
    padding-left: 0;
    margin-top: 5rem;
  }
}

/* line 133, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
.testimonial-slider h4 {
  font-size: 3rem;
  line-height: 3.5rem;
  margin-bottom: 0;
}

/* line 139, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
.testimonial-slider .city {
  color: #FF5205;
  font-weight: 700;
  font-size: 1.8rem;
  display: block;
  margin-bottom: 20px;
}

/* line 147, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
.testimonial-slider .testimonial-index {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 30px;
  display: flex;
  flex-flow: column wrap;
}

@media (max-width: 1024px) {
  /* line 147, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
  .testimonial-slider .testimonial-index {
    display: none;
  }
}

/* line 161, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
.testimonial-slider .testimonial-numbers {
  width: 100%;
}

/* line 165, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
.testimonial-slider .testimonial-number {
  text-align: center;
  font-weight: bold;
}

/* line 170, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
.testimonial-slider .testimonial-total {
  width: 100%;
  border-top: 2px solid #090C10;
  margin-top: 10px;
  padding-top: 10px;
  font-weight: bold;
  text-align: center;
}

/* line 179, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
.testimonial-slider .testimonial-arrows {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

/* line 184, public/wp-content/themes/lesage/assets/src/scss/components/_carousel.scss */
.testimonial-slider .testimonial-arrows i {
  color: #FF5205;
  margin-right: 20px;
  font-size: 12px;
  cursor: pointer;
}

/* line 1, public/wp-content/themes/lesage/assets/src/scss/components/_skewed-images.scss */
.skewed-images {
  display: flex;
  flex-flow: row wrap;
}

/* line 5, public/wp-content/themes/lesage/assets/src/scss/components/_skewed-images.scss */
.skewed-images h3 {
  font-size: 4rem;
  line-height: 4.5rem;
  margin-bottom: 0px;
}

@media (max-width: 1024px) {
  /* line 5, public/wp-content/themes/lesage/assets/src/scss/components/_skewed-images.scss */
  .skewed-images h3 {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}

/* line 16, public/wp-content/themes/lesage/assets/src/scss/components/_skewed-images.scss */
.skewed-images .job-title {
  color: #FF5205;
}

/* line 20, public/wp-content/themes/lesage/assets/src/scss/components/_skewed-images.scss */
.skewed-images .skewed-image-container {
  width: 50%;
  margin-bottom: 120px;
}

@media (max-width: 1024px) {
  /* line 20, public/wp-content/themes/lesage/assets/src/scss/components/_skewed-images.scss */
  .skewed-images .skewed-image-container {
    width: 100%;
    margin-bottom: 30px;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
}

/* line 32, public/wp-content/themes/lesage/assets/src/scss/components/_skewed-images.scss */
.skewed-images .skewed-image-container .skewed-image {
  height: 40vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
  transform: skewY(-10deg);
  margin-bottom: 60px;
}

@media (max-width: 1024px) {
  /* line 32, public/wp-content/themes/lesage/assets/src/scss/components/_skewed-images.scss */
  .skewed-images .skewed-image-container .skewed-image {
    height: 70vw;
    transform: skewY(-5deg);
  }
}

@media (max-width: 480px) {
  /* line 32, public/wp-content/themes/lesage/assets/src/scss/components/_skewed-images.scss */
  .skewed-images .skewed-image-container .skewed-image {
    height: 100vw;
  }
}

/* line 50, public/wp-content/themes/lesage/assets/src/scss/components/_skewed-images.scss */
.skewed-images .skewed-image-container .skewed-content {
  padding-right: 80px;
}

@media (max-width: 1024px) {
  /* line 50, public/wp-content/themes/lesage/assets/src/scss/components/_skewed-images.scss */
  .skewed-images .skewed-image-container .skewed-content {
    padding-right: 0;
  }
}

/* line 58, public/wp-content/themes/lesage/assets/src/scss/components/_skewed-images.scss */
.skewed-images .skewed-image-container:nth-child(even) {
  margin-top: 80px;
  margin-left: -1px;
}

@media (max-width: 1024px) {
  /* line 58, public/wp-content/themes/lesage/assets/src/scss/components/_skewed-images.scss */
  .skewed-images .skewed-image-container:nth-child(even) {
    margin-left: auto;
  }
}

/* line 1, public/wp-content/themes/lesage/assets/src/scss/components/_banner-levels.scss */
.banner-levels {
  height: 650px;
  padding-top: 150px;
  padding-bottom: 50px;
  position: fixed;
  top: 0;
  left: 110px;
  right: 0;
  bottom: 0;
  z-index: 2;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 1200px) {
  /* line 1, public/wp-content/themes/lesage/assets/src/scss/components/_banner-levels.scss */
  .banner-levels {
    left: 0;
  }
}

@media (max-width: 768px) {
  /* line 1, public/wp-content/themes/lesage/assets/src/scss/components/_banner-levels.scss */
  .banner-levels {
    padding-top: 150px;
    height: 400px;
  }
}

@media (max-width: 768px) {
  /* line 24, public/wp-content/themes/lesage/assets/src/scss/components/_banner-levels.scss */
  .banner-levels--contact {
    height: 600px;
  }
}

@media (max-width: 768px) {
  /* line 29, public/wp-content/themes/lesage/assets/src/scss/components/_banner-levels.scss */
  .banner-levels--contact .scrollTo {
    display: none;
  }
}

/* line 38, public/wp-content/themes/lesage/assets/src/scss/components/_banner-levels.scss */
.banner-levels.single-projet-banner:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(9, 12, 16, 0.8);
}

/* line 48, public/wp-content/themes/lesage/assets/src/scss/components/_banner-levels.scss */
.banner-levels.single-projet-banner .banner-levels__row {
  padding-bottom: 50px;
}

/* line 51, public/wp-content/themes/lesage/assets/src/scss/components/_banner-levels.scss */
.banner-levels.single-projet-banner .banner-levels__row .date {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 1.6rem;
  position: relative;
  text-transform: capitalize;
}

@media (max-width: 768px) {
  /* line 51, public/wp-content/themes/lesage/assets/src/scss/components/_banner-levels.scss */
  .banner-levels.single-projet-banner .banner-levels__row .date {
    margin-top: 30px;
  }
}

/* line 63, public/wp-content/themes/lesage/assets/src/scss/components/_banner-levels.scss */
.banner-levels.single-projet-banner .banner-levels__row .date:before {
  content: '';
  position: absolute;
  top: -10px;
  left: 0;
  width: 30px;
  height: 1px;
  border-top: 3px solid #FF5205;
}

/* line 76, public/wp-content/themes/lesage/assets/src/scss/components/_banner-levels.scss */
.banner-levels .container {
  height: 100%;
}

/* line 80, public/wp-content/themes/lesage/assets/src/scss/components/_banner-levels.scss */
.banner-levels__wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
}

/* line 86, public/wp-content/themes/lesage/assets/src/scss/components/_banner-levels.scss */
.banner-levels__wrapper button {
  background: transparent;
  border: 0;
  appearance: none;
  align-self: flex-start;
  outline: 0;
  cursor: pointer;
}

/* line 97, public/wp-content/themes/lesage/assets/src/scss/components/_banner-levels.scss */
.banner-levels__row {
  flex-grow: 1;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-end;
  padding-bottom: 100px;
}

@media (max-width: 768px) {
  /* line 97, public/wp-content/themes/lesage/assets/src/scss/components/_banner-levels.scss */
  .banner-levels__row {
    flex-flow: column wrap;
    justify-content: center;
    align-items: flex-start;
    padding-bottom: 50px;
  }
}

/* line 112, public/wp-content/themes/lesage/assets/src/scss/components/_banner-levels.scss */
.banner-levels__row h1 {
  margin-bottom: 0;
}

@media (max-width: 768px) {
  /* line 112, public/wp-content/themes/lesage/assets/src/scss/components/_banner-levels.scss */
  .banner-levels__row h1 {
    margin-bottom: 20px;
  }
}

/* line 120, public/wp-content/themes/lesage/assets/src/scss/components/_banner-levels.scss */
.banner-levels__row .c-button, .banner-levels__row .c-button--style-1, .banner-levels__row .c-button--style-2, .banner-levels__row .c-button--skewed, .banner-levels__row .c-button--skewed-2, .banner-levels__row .c-button--skewed-3, .banner-levels__row .c-button--transparent {
  margin-bottom: 20px;
}

@media (max-width: 1200px) {
  /* line 120, public/wp-content/themes/lesage/assets/src/scss/components/_banner-levels.scss */
  .banner-levels__row .c-button, .banner-levels__row .c-button--style-1, .banner-levels__row .c-button--style-2, .banner-levels__row .c-button--skewed, .banner-levels__row .c-button--skewed-2, .banner-levels__row .c-button--skewed-3, .banner-levels__row .c-button--transparent {
    margin-bottom: 5px;
  }
}

/* line 129, public/wp-content/themes/lesage/assets/src/scss/components/_banner-levels.scss */
.banner-levels__spacer {
  position: relative;
  background: #090C10;
  z-index: 3;
  margin-top: 650px;
}

@media (max-width: 768px) {
  /* line 129, public/wp-content/themes/lesage/assets/src/scss/components/_banner-levels.scss */
  .banner-levels__spacer {
    margin-top: 400px;
  }
}

/* line 139, public/wp-content/themes/lesage/assets/src/scss/components/_banner-levels.scss */
.banner-levels__spacer.white-layout {
  background: #FAFAFA;
  color: #090C10;
}

/* line 143, public/wp-content/themes/lesage/assets/src/scss/components/_banner-levels.scss */
.banner-levels__spacer.white-layout .wrapper {
  padding: 10rem 0;
}

@media (max-width: 768px) {
  /* line 148, public/wp-content/themes/lesage/assets/src/scss/components/_banner-levels.scss */
  .banner-levels__spacer--contact {
    margin-top: 600px;
  }
}

/* line 3, public/wp-content/themes/lesage/assets/src/scss/components/_projets.scss */
.projets-wrapper.projets--home {
  background: #FAFAFA;
  padding-bottom: 10rem;
}

/* line 8, public/wp-content/themes/lesage/assets/src/scss/components/_projets.scss */
.projets-wrapper.projets--home .projets__item {
  width: calc(33.333% - 20px);
  margin-right: 20px;
}

@media (max-width: 1024px) {
  /* line 8, public/wp-content/themes/lesage/assets/src/scss/components/_projets.scss */
  .projets-wrapper.projets--home .projets__item {
    width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  /* line 8, public/wp-content/themes/lesage/assets/src/scss/components/_projets.scss */
  .projets-wrapper.projets--home .projets__item {
    width: 100%;
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  /* line 8, public/wp-content/themes/lesage/assets/src/scss/components/_projets.scss */
  .projets-wrapper.projets--home .projets__item {
    min-height: 300px;
  }
}

@media (max-width: 1024px) {
  /* line 26, public/wp-content/themes/lesage/assets/src/scss/components/_projets.scss */
  .projets-wrapper.projets--home .projets__item:last-of-type {
    width: calc(100% - 20px);
    margin-right: 0;
    margin-top: 20px;
  }
}

/* line 35, public/wp-content/themes/lesage/assets/src/scss/components/_projets.scss */
.projets-wrapper.projets--home .projets__listing {
  margin-right: -20px;
}

/* line 40, public/wp-content/themes/lesage/assets/src/scss/components/_projets.scss */
.projets-wrapper .wrapper {
  padding: 10rem 0 0;
}

/* line 44, public/wp-content/themes/lesage/assets/src/scss/components/_projets.scss */
.projets-wrapper h2 {
  color: #FAFAFA;
}

/* line 49, public/wp-content/themes/lesage/assets/src/scss/components/_projets.scss */
.projets-wrapper .projets__listing {
  display: flex;
  flex-flow: row wrap;
}

/* line 53, public/wp-content/themes/lesage/assets/src/scss/components/_projets.scss */
.projets-wrapper .projets__listing h3 {
  font-size: 3.2rem;
  line-height: 3.4rem;
  margin-bottom: 10px;
}

/* line 60, public/wp-content/themes/lesage/assets/src/scss/components/_projets.scss */
.projets-wrapper .projets__item {
  width: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 450px;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  color: #FAFAFA;
}

@media (max-width: 1024px) {
  /* line 60, public/wp-content/themes/lesage/assets/src/scss/components/_projets.scss */
  .projets-wrapper .projets__item {
    width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  /* line 60, public/wp-content/themes/lesage/assets/src/scss/components/_projets.scss */
  .projets-wrapper .projets__item {
    width: 100%;
    margin-top: 0;
  }
}

@media (max-width: 480px) {
  /* line 60, public/wp-content/themes/lesage/assets/src/scss/components/_projets.scss */
  .projets-wrapper .projets__item {
    min-height: 300px;
  }
}

/* line 88, public/wp-content/themes/lesage/assets/src/scss/components/_projets.scss */
.projets-wrapper .projets__item:before {
  content: '';
  background: #090C10;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  opacity: 0.5;
  transition: all 200ms ease;
}

/* line 102, public/wp-content/themes/lesage/assets/src/scss/components/_projets.scss */
.projets-wrapper .projets__item:hover .projets__details {
  margin-bottom: 0;
}

/* line 106, public/wp-content/themes/lesage/assets/src/scss/components/_projets.scss */
.projets-wrapper .projets__item:hover:before {
  opacity: 0.7;
}

/* line 111, public/wp-content/themes/lesage/assets/src/scss/components/_projets.scss */
.projets-wrapper .projets__item .play-icon {
  position: absolute;
  top: 30px;
  left: 30px;
  border: 2px solid #FAFAFA;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

/* line 124, public/wp-content/themes/lesage/assets/src/scss/components/_projets.scss */
.projets-wrapper .projets__item .play-icon i {
  font-size: 10px;
  color: #FAFAFA;
  margin-right: -2px;
}

/* line 132, public/wp-content/themes/lesage/assets/src/scss/components/_projets.scss */
.projets-wrapper .projets__content {
  padding: 30px 30px 20px;
  position: relative;
  z-index: 999;
}

/* line 137, public/wp-content/themes/lesage/assets/src/scss/components/_projets.scss */
.projets-wrapper .projets__content h3 {
  font-size: 2.5rem;
  line-height: 3rem;
  margin-bottom: 0;
  color: #FAFAFA;
  text-transform: initial;
}

@media (max-width: 480px) {
  /* line 137, public/wp-content/themes/lesage/assets/src/scss/components/_projets.scss */
  .projets-wrapper .projets__content h3 {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

/* line 151, public/wp-content/themes/lesage/assets/src/scss/components/_projets.scss */
.projets-wrapper .projets__details {
  padding: 25px 30px;
  background: #FF5205;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 1.4rem;
  margin-bottom: -70px;
  transition: all 200ms ease;
  position: relative;
  z-index: 999;
}

/* line 165, public/wp-content/themes/lesage/assets/src/scss/components/_projets.scss */
.projets-wrapper .projets__details i {
  font-size: 14px;
  margin-left: 10px;
}

/* line 171, public/wp-content/themes/lesage/assets/src/scss/components/_projets.scss */
.projets-wrapper .projets__cat {
  margin-bottom: 0;
  text-transform: initial;
  color: #FAFAFA;
  font-size: 1.5rem;
  font-weight: 400;
}

/* line 1, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
.services {
  background: #FAFAFA;
}

/* line 4, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
.services--black {
  background: #090C10;
}

/* line 9, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
.services--black .col_1x2:last-of-type {
  width: 100%;
}

/* line 17, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
.services--puzzle .services__list {
  padding-right: 10rem;
}

@media (max-width: 1024px) {
  /* line 17, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
  .services--puzzle .services__list {
    padding-right: 0;
  }
}

/* line 29, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
.services--puzzle .services__list .col_1x2:nth-child(2) {
  margin-right: -10rem;
  width: calc(50% + 10rem);
}

@media (max-width: 1024px) {
  /* line 29, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
  .services--puzzle .services__list .col_1x2:nth-child(2) {
    margin-right: 0;
    width: 50%;
  }
}

@media (max-width: 768px) {
  /* line 29, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
  .services--puzzle .services__list .col_1x2:nth-child(2) {
    width: 100%;
  }
}

/* line 43, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
.services--puzzle .services__list .col_1x2:nth-of-type(2) {
  margin-top: 100px;
}

@media (max-width: 1024px) {
  /* line 43, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
  .services--puzzle .services__list .col_1x2:nth-of-type(2) {
    margin-top: 0;
  }
}

/* line 51, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
.services--puzzle .services__list .col_1x2:nth-of-type(odd):not(:first-child) {
  margin-top: -100px;
}

@media (max-width: 1024px) {
  /* line 51, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
  .services--puzzle .services__list .col_1x2:nth-of-type(odd):not(:first-child) {
    margin-top: 0;
  }
}

@media (max-width: 768px) {
  /* line 62, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
  .services .content {
    padding: 10rem 0;
  }
}

/* line 69, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
.services h2 {
  color: #090C10;
}

/* line 74, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
.services h2.has-orange-separator:before {
  border-color: #FAFAFA;
  bottom: 20px;
}

/* line 81, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
.services h4 {
  color: #373B41;
  max-width: 450px;
}

/* line 86, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
.services p {
  color: #373B41;
}

/* line 90, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
.services .col_1x2 {
  width: 50%;
}

@media (max-width: 768px) {
  /* line 90, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
  .services .col_1x2 {
    width: 100%;
  }
}

@media (max-width: 768px) {
  /* line 97, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
  .services .col_1x2.ju-center {
    justify-content: flex-start;
  }
}

@media (max-width: 768px) {
  /* line 104, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
  .services .col_1x2.pushed-down {
    margin-top: 0;
  }
}

/* line 112, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
.services__list {
  display: flex;
  flex-flow: row wrap;
}

/* line 116, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
.services__list .col_1x2 {
  width: 50%;
}

@media (max-width: 768px) {
  /* line 116, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
  .services__list .col_1x2 {
    width: 100%;
  }
}

/* line 125, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
.services__item {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 450px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  padding: 30px;
}

/* line 140, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
.services__item h2 {
  color: white;
}

/* line 145, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
.services__item:hover:after {
  transform: translateY(0);
}

/* line 150, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
.services__item:hover .c-button--transparent:after {
  width: 100%;
}

/* line 155, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
.services__item:hover i {
  color: #FF5205;
}

/* line 160, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
.services__item:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: radial-gradient(ellipse at center, rgba(9, 12, 16, 0.7) 0%, rgba(9, 12, 16, 0.9) 100%);
  border: 1px solid rgba(112, 112, 112, 0.4);
}

/* line 171, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
.services__item:after {
  content: '';
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 10px;
  z-index: 9999;
  background: #FF5205;
  transition: all 300ms ease;
  transform: translateY(10px);
}

/* line 184, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
.services__item > * {
  position: relative;
  z-index: 20;
}

/* line 189, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
.services__item h3 {
  color: #FAFAFA;
  margin-bottom: 0;
  text-align: center;
  text-transform: initial;
}

/* line 198, public/wp-content/themes/lesage/assets/src/scss/components/_services.scss */
.services__subtitle {
  color: rgba(250, 250, 250, 0.5) !important;
  font-weight: 600 !important;
  margin-bottom: 0;
  font-size: 1.8rem;
  line-height: 2.2rem;
}

/* line 1, public/wp-content/themes/lesage/assets/src/scss/components/_accordion.scss */
.accordion {
  list-style-type: none;
  padding: 0;
  margin: 6rem 0;
}

/* line 8, public/wp-content/themes/lesage/assets/src/scss/components/_accordion.scss */
.accordion--careers h3 {
  font-size: 35px;
  line-height: 40px;
}

@media (max-width: 768px) {
  /* line 8, public/wp-content/themes/lesage/assets/src/scss/components/_accordion.scss */
  .accordion--careers h3 {
    font-size: 25px;
    line-height: 30px;
  }
}

/* line 17, public/wp-content/themes/lesage/assets/src/scss/components/_accordion.scss */
.accordion--careers h3:after {
  content: '';
  display: block;
  width: 40px;
  height: 1px;
  border-bottom: 5px solid #FF5205;
  margin-top: 20px;
}

/* line 26, public/wp-content/themes/lesage/assets/src/scss/components/_accordion.scss */
.accordion--careers h3 a {
  color: #090C10;
}

/* line 31, public/wp-content/themes/lesage/assets/src/scss/components/_accordion.scss */
.accordion--careers h4 {
  font-size: 20px;
  line-height: 25px;
}

@media (max-width: 768px) {
  /* line 36, public/wp-content/themes/lesage/assets/src/scss/components/_accordion.scss */
  .accordion--careers p {
    font-size: 16px;
    line-height: 24px;
  }
}

/* line 43, public/wp-content/themes/lesage/assets/src/scss/components/_accordion.scss */
.accordion--careers > li {
  border: 1px solid rgba(112, 112, 112, 0.5);
  padding: 50px !important;
}

@media (max-width: 768px) {
  /* line 43, public/wp-content/themes/lesage/assets/src/scss/components/_accordion.scss */
  .accordion--careers > li {
    padding: 40px 30px !important;
  }
}

@media (max-width: 480px) {
  /* line 43, public/wp-content/themes/lesage/assets/src/scss/components/_accordion.scss */
  .accordion--careers > li {
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    padding: 0px 0px 40px !important;
  }
}

/* line 58, public/wp-content/themes/lesage/assets/src/scss/components/_accordion.scss */
.accordion--careers > li:after {
  display: none;
}

/* line 62, public/wp-content/themes/lesage/assets/src/scss/components/_accordion.scss */
.accordion--careers > li ul {
  list-style-type: none;
  padding: 0 0 0 30px;
}

/* line 66, public/wp-content/themes/lesage/assets/src/scss/components/_accordion.scss */
.accordion--careers > li ul li {
  padding: 0px;
  margin: 15px 0;
  font-size: 16px;
  line-height: 24px;
}

/* line 72, public/wp-content/themes/lesage/assets/src/scss/components/_accordion.scss */
.accordion--careers > li ul li:before {
  content: '•';
  color: #FF5205;
  margin-left: -26px;
  margin-right: 10px;
}

/* line 79, public/wp-content/themes/lesage/assets/src/scss/components/_accordion.scss */
.accordion--careers > li ul li:after {
  display: none;
}

/* line 87, public/wp-content/themes/lesage/assets/src/scss/components/_accordion.scss */
.accordion li {
  color: #090C10;
  position: relative;
  padding-bottom: 30px;
  margin-bottom: 30px;
}

/* line 93, public/wp-content/themes/lesage/assets/src/scss/components/_accordion.scss */
.accordion li:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: rgba(112, 112, 112, 0.2);
  transition: all 300ms ease;
}

/* line 106, public/wp-content/themes/lesage/assets/src/scss/components/_accordion.scss */
.accordion li.active:after {
  height: 3px;
  background: #FF5205;
}

/* line 113, public/wp-content/themes/lesage/assets/src/scss/components/_accordion.scss */
.accordion li.active .accordion-more-details .plus-icon:after {
  transform: rotate(0deg);
}

/* line 123, public/wp-content/themes/lesage/assets/src/scss/components/_accordion.scss */
.accordion li h4 a {
  color: #090C10;
}

/* line 128, public/wp-content/themes/lesage/assets/src/scss/components/_accordion.scss */
.accordion li div.accordion-content {
  display: none;
}

/* line 131, public/wp-content/themes/lesage/assets/src/scss/components/_accordion.scss */
.accordion li div.accordion-content p {
  color: #373B41;
}

/* line 137, public/wp-content/themes/lesage/assets/src/scss/components/_accordion.scss */
.accordion li .accordion-more-details {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  cursor: pointer;
  font-weight: 600;
}

/* line 144, public/wp-content/themes/lesage/assets/src/scss/components/_accordion.scss */
.accordion li .accordion-more-details .plus-icon {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 10px;
  height: 10px;
  margin-left: 10px;
}

/* line 154, public/wp-content/themes/lesage/assets/src/scss/components/_accordion.scss */
.accordion li .accordion-more-details .plus-icon:before, .accordion li .accordion-more-details .plus-icon:after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 2px;
  background: #FF5205;
}

/* line 164, public/wp-content/themes/lesage/assets/src/scss/components/_accordion.scss */
.accordion li .accordion-more-details .plus-icon:after {
  transform: rotate(90deg);
  transition: all 300ms ease;
}

/* line 1, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.c-form {
  padding: 15rem 20rem 0;
  background-color: #FAFAFA;
  color: #000;
}

@media (max-width: 1300px) {
  /* line 1, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
  .c-form {
    padding: 15rem;
  }
}

@media (max-width: 768px) {
  /* line 1, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
  .c-form {
    padding: 10rem 2rem;
  }
}

/* line 14, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.c-form h5 {
  margin-bottom: 30px;
  opacity: 0.5;
  color: #1d1d1d;
}

/* line 21, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.c-form_section {
  padding-top: 5rem;
}

/* line 24, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.c-form_section p {
  color: #090C10;
  font-size: 18px;
  font-weight: 400;
}

@media (max-width: 1300px) {
  /* line 31, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
  .form-wrapper {
    padding-right: 0;
  }
}

/* line 36, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form-wrapper .form__row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  width: 100%;
  margin: 60px 0;
  gap: 60px;
}

/* line 44, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form-wrapper .form__row:first-of-type {
  margin-top: 0;
}

@media (max-width: 768px) {
  /* line 36, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
  .form-wrapper .form__row {
    flex-direction: column;
  }
}

/* line 53, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form-wrapper .form__row.no-translate label {
  transform: none;
}

/* line 57, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form-wrapper .form__row.no-translate .wpcf7-form-control-wrap:after {
  display: none;
}

/* line 64, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form-wrapper .form__row .wpcf7-form-control-wrap.active:after {
  height: 3px;
  background: #FF5205;
}

/* line 71, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form-wrapper .form__row .wpcf7-form-control-wrap {
  position: relative;
  display: block;
  width: 100%;
}

/* line 76, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form-wrapper .form__row .wpcf7-form-control-wrap.message:after {
  display: none;
}

/* line 80, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form-wrapper .form__row .wpcf7-form-control-wrap:after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  height: 2px;
  background: rgba(112, 112, 112, 0.2);
  transition: all 300ms ease;
}

/* line 90, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form-wrapper .form__row .wpcf7-form-control-wrap textarea {
  padding: 20px;
  border: 2px solid rgba(112, 112, 112, 0.2);
  resize: none;
}

/* line 96, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form-wrapper .form__row .wpcf7-form-control-wrap.domaineDuProjet, .form-wrapper .form__row .wpcf7-form-control-wrap.typeDeProjet {
  position: relative;
}

/* line 100, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form-wrapper .form__row .wpcf7-form-control-wrap.domaineDuProjet:before, .form-wrapper .form__row .wpcf7-form-control-wrap.typeDeProjet:before {
  content: "";
  position: absolute;
  right: 30px;
  bottom: 30px;
  display: block;
  width: 10px;
  height: 10px;
  transform: rotate(45deg);
  border-right: 2px solid #FF5205;
  border-bottom: 2px solid #FF5205;
  pointer-events: none;
}

/* line 116, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form-wrapper .form__row label {
  display: block;
  width: 45%;
  transform: translateY(30px) scale(1);
  transform-origin: top left;
  transition: all 300ms ease;
  font-family: "futura-pt";
  font-size: 2rem;
  font-weight: 700;
}

@media (max-width: 768px) {
  /* line 116, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
  .form-wrapper .form__row label {
    width: 100%;
  }
}

/* line 129, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form-wrapper .form__row label.active {
  transform: translateY(0) translateX(0px) scale(0.8);
  color: #373B41;
}

/* line 135, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form-wrapper .form__row.label-textarea label {
  transform: translateY(30px) translateX(12px) scale(1);
}

/* line 137, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form-wrapper .form__row.label-textarea label.active {
  transform: translateY(0) translateX(0px) scale(0.8);
}

/* line 143, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form-wrapper .form__row .c-text-field {
  width: 45%;
}

@media (max-width: 930px) {
  /* line 143, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
  .form-wrapper .form__row .c-text-field {
    width: 100%;
  }
}

/* line 151, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form-wrapper .form__row input:not([type="submit"]),
.form-wrapper .form__row textarea {
  width: 100%;
  padding: 10px 0;
  outline: 0;
  border: 0;
  background: transparent;
  font-family: "futura-pt";
  font-size: 1.8rem;
  font-weight: 400;
  line-height: 2.4rem;
  appearance: none;
}

/* line 164, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form-wrapper .form__row input:not([type="submit"])::placeholder,
.form-wrapper .form__row textarea::placeholder {
  color: #000;
}

/* line 169, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form-wrapper .form__row textarea {
  height: 150px;
}

/* line 173, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form-wrapper .form__row select {
  width: 100%;
  margin-top: 20px;
  padding: 20px 30px;
  outline: 0;
  border: 2px solid rgba(112, 112, 112, 0.2);
  background: transparent;
  font-family: "futura-pt";
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 2.4rem;
  appearance: none;
}

/* line 187, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form-wrapper .form__row input[type="file"],
.form-wrapper .form__row input[type="date"] {
  margin-top: 20px;
  padding: 20px 30px;
  border: 2px solid rgba(112, 112, 112, 0.2);
}

/* line 194, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form-wrapper .form__row.-single {
  flex-direction: row;
  justify-content: center;
  margin-bottom: 0;
}

/* line 202, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.wpcf7-response-output {
  margin: 2em 0.5em 0em !important;
}

/* line 207, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.contact__form input,
.contact__form textarea {
  color: #FAFAFA;
}

/* line 214, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form__checkbox .wpcf7-checkbox {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media (max-width: 768px) {
  /* line 214, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
  .form__checkbox .wpcf7-checkbox {
    flex-direction: column;
  }
}

/* line 223, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form__checkbox .wpcf7-checkbox .wpcf7-list-item {
  display: flex;
  width: 45%;
  margin-left: 0;
  padding: 2rem 0;
  font-size: 20px;
}

@media (max-width: 768px) {
  /* line 223, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
  .form__checkbox .wpcf7-checkbox .wpcf7-list-item {
    width: 100%;
  }
}

/* line 234, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form__checkbox .wpcf7-checkbox .wpcf7-list-item input[type="checkbox"] {
  margin-bottom: 15px;
  margin-left: 0;
}

/* line 239, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form__checkbox .wpcf7-checkbox .wpcf7-list-item .wpcf7-list-item-label {
  padding-left: 15px;
}

/* line 244, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form__checkbox .wpcf7-checkbox label {
  position: relative;
  cursor: pointer;
}

/* line 249, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form__checkbox .wpcf7-checkbox input[type="checkbox"] {
  position: relative;
  visibility: hidden;
}

/* line 254, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form__checkbox .wpcf7-checkbox input[type="checkbox"] + span:before {
  content: "";
  position: absolute;
  top: 0px;
  display: block;
  width: 18px;
  height: 18px;
  border: 1px solid #FF5205;
}

/* line 264, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form__checkbox .wpcf7-checkbox input[type="checkbox"] + span:after {
  content: url("../images/check.svg");
  position: absolute;
  top: 0px;
  left: 0px;
  display: block;
  width: 10px;
  height: 10px;
  visibility: hidden;
  border-radius: 100%;
  color: #fff;
  font-size: 16px;
}

/* line 278, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form__checkbox .wpcf7-checkbox input[type="checkbox"]:checked + span:before {
  background: #FF5205;
}

/* line 282, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form__checkbox .wpcf7-checkbox input[type="checkbox"]:checked + span:after {
  visibility: visible;
}

/* line 288, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form__date {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

@media (max-width: 1000px) {
  /* line 288, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
  .form__date {
    flex-direction: column;
  }
}

/* line 297, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form__date .date {
  display: flex;
  flex-direction: column;
  width: 45%;
}

@media (max-width: 768px) {
  /* line 297, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
  .form__date .date {
    width: 100%;
  }
}

/* line 307, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form__date .date p > span input[type="date"] {
  padding: 17px 20px;
}

/* line 310, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form__date .date p > span input[type="date"]::-webkit-datetime-edit {
  font-family: "futura-pt";
  font-size: 18px;
}

/* line 315, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form__date .date p > span input[type="date"]::-webkit-calendar-picker-indicator {
  width: 23px;
  height: 23px;
  margin-left: 0.5rem;
  opacity: 1;
  border-radius: 50%;
  color: rgba(0, 0, 0, 0);
  background-image: url(../images/calendar.svg);
  cursor: pointer;
}

/* line 329, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form__date .file {
  width: 45%;
}

@media (max-width: 768px) {
  /* line 329, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
  .form__date .file {
    width: 100%;
  }
}

/* line 337, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form__date span {
  width: 45%;
}

@media (max-width: 1000px) {
  /* line 337, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
  .form__date span {
    width: 100%;
  }
}

/* line 344, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form__date span input {
  width: 100%;
  padding: 20px;
  border: 1px solid #373B41;
  background: transparent;
}

/* line 352, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form__date .c-file {
  display: flex;
}

/* line 355, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form__date .c-file .c-file_label {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;
  background-color: #FF5205;
}

@media (min-width: 769px) and (max-width: 1200px) {
  /* line 355, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
  .form__date .c-file .c-file_label {
    width: 80%;
  }
}

/* line 370, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form__textarea textarea {
  width: 100%;
  padding: 15px;
  border: none;
  background-color: #f5f5f5;
  font-family: "futura-pt";
  font-size: 18px;
}

/* line 378, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.form__textarea textarea::placeholder {
  color: #090C10;
}

/* line 384, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.submit_btn {
  position: relative;
}

/* line 387, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.submit_btn input[type="submit"] {
  padding: 15px 170px;
  transform: skewX(-25deg);
  outline: 0;
  border: 1px solid #FF5205;
  background-color: #FF5205;
  cursor: pointer;
  transition: 0.2s;
  text-indent: -99999px;
  font-family: "futura-pt";
}

/* line 399, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.submit_btn button {
  position: absolute;
  top: 15px;
  left: 42%;
  border: none;
  background-color: transparent;
  color: #FAFAFA;
  font-family: "futura-pt";
  font-size: 1.5rem;
  font-weight: 500;
  pointer-events: none;
}

/* line 413, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.wpcf7-spinner {
  display: none;
}

/* line 418, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.c-file input,
.c-file .file {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(0, 0, 0, 0);
}

/* line 432, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.c-file_btn {
  width: 100%;
  padding: 20px 15px;
  color: #FAFAFA;
  cursor: pointer;
  text-align: center;
}

/* line 440, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.c-file_name {
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* line 448, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.c-file_size {
  white-space: nowrap;
}

/* line 452, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.c-file_clear {
  cursor: pointer;
}

/* line 457, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.c-file input,
.c-file .file {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  overflow: hidden;
  white-space: nowrap;
  clip: rect(0, 0, 0, 0);
}

/* line 471, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.c-file_document {
  display: flex;
  align-items: center;
  width: 100%;
  border: 1px solid #373B41;
}

/* line 477, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.c-file_document .c-file_meta {
  width: 100%;
  margin-left: 15px;
}

/* line 483, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.c-file_name {
  display: block;
  max-width: 100%;
  color: #707070;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* line 492, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.c-file_size {
  display: none;
  white-space: nowrap;
}

/* line 497, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.c-file_clear {
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;
  cursor: pointer;
}

/* line 503, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.c-file_clear::after {
  content: url("../images/clear.svg");
}

/* line 508, public/wp-content/themes/lesage/assets/src/scss/components/_form.scss */
.wpcf7-not-valid-tip {
  position: absolute;
  bottom: -4px;
  transform: translateY(100%);
}

/* line 1, public/wp-content/themes/lesage/assets/src/scss/components/_modal.scss */
body.modal-is-active {
  overflow: hidden;
}

/* line 4, public/wp-content/themes/lesage/assets/src/scss/components/_modal.scss */
body.modal-is-active .modal {
  opacity: 1;
  visibility: visible;
  pointer-events: initial;
}

/* line 11, public/wp-content/themes/lesage/assets/src/scss/components/_modal.scss */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 10px solid #FF5205;
  z-index: 9999999;
  background: #FAFAFA;
  color: #090C10;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: all 300ms ease;
}

/* line 26, public/wp-content/themes/lesage/assets/src/scss/components/_modal.scss */
.modal__submit {
  justify-content: center !important;
  margin-top: 50px !important;
}

/* line 30, public/wp-content/themes/lesage/assets/src/scss/components/_modal.scss */
.modal__submit input {
  font-weight: 600 !important;
  font-size: 1.8rem !important;
  padding: 18px 50px !important;
  max-width: 350px;
  width: 100% !important;
  text-align: center;
  border: 2px solid #FF5205 !important;
  transition: all 300ms ease;
}

/* line 40, public/wp-content/themes/lesage/assets/src/scss/components/_modal.scss */
.modal__submit input:hover {
  background: rgba(255, 82, 5, 0.7) !important;
}

/* line 46, public/wp-content/themes/lesage/assets/src/scss/components/_modal.scss */
.modal__close {
  position: absolute;
  top: 80px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 40px;
  height: 40px;
  background: #FF5205;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  z-index: 99999;
  cursor: pointer;
  border: 2px solid #FF5205;
  transition: all 300ms ease;
}

/* line 64, public/wp-content/themes/lesage/assets/src/scss/components/_modal.scss */
.modal__close:hover {
  background: rgba(255, 82, 5, 0.7);
}

/* line 68, public/wp-content/themes/lesage/assets/src/scss/components/_modal.scss */
.modal__close:before, .modal__close:after {
  content: '';
  position: absolute;
  width: 20px;
  height: 2px;
  background: #FAFAFA;
}

/* line 77, public/wp-content/themes/lesage/assets/src/scss/components/_modal.scss */
.modal__close:before {
  transform: rotate(45deg);
}

/* line 81, public/wp-content/themes/lesage/assets/src/scss/components/_modal.scss */
.modal__close:after {
  transform: rotate(-45deg);
}

/* line 86, public/wp-content/themes/lesage/assets/src/scss/components/_modal.scss */
.modal__scroll {
  position: absolute;
  bottom: 5rem;
  left: 0;
  right: 0;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  color: #090C10;
}

/* line 97, public/wp-content/themes/lesage/assets/src/scss/components/_modal.scss */
.modal__scroll img {
  filter: invert(1);
  margin-bottom: 10px;
}

/* line 103, public/wp-content/themes/lesage/assets/src/scss/components/_modal.scss */
.modal__form {
  padding-top: 75px;
}

/* line 107, public/wp-content/themes/lesage/assets/src/scss/components/_modal.scss */
.modal__content {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow-y: auto;
}

/* line 113, public/wp-content/themes/lesage/assets/src/scss/components/_modal.scss */
.modal__content-intro {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 5rem;
  position: relative;
}

/* line 123, public/wp-content/themes/lesage/assets/src/scss/components/_modal.scss */
.modal__content-intro .subtitle {
  color: #FF5205;
  font-size: 2.5rem;
  line-height: 3rem;
}

/* line 129, public/wp-content/themes/lesage/assets/src/scss/components/_modal.scss */
.modal__content-intro h2 {
  text-align: center;
}

/* line 133, public/wp-content/themes/lesage/assets/src/scss/components/_modal.scss */
.modal__content-intro p {
  text-align: center;
  margin-bottom: 0;
}

/* line 140, public/wp-content/themes/lesage/assets/src/scss/components/_modal.scss */
.modal__content form {
  max-width: 1200px;
  margin: 0 auto;
  padding-bottom: 10rem;
}

/* line 145, public/wp-content/themes/lesage/assets/src/scss/components/_modal.scss */
.modal__content form h2 {
  text-transform: none;
}

/* line 149, public/wp-content/themes/lesage/assets/src/scss/components/_modal.scss */
.modal__content form p {
  display: none;
}

/* line 153, public/wp-content/themes/lesage/assets/src/scss/components/_modal.scss */
.modal__content form .form__row + h2 {
  margin-top: 100px;
  margin-bottom: 50px;
}

/* line 158, public/wp-content/themes/lesage/assets/src/scss/components/_modal.scss */
.modal__content form .bottom-spacer {
  margin-bottom: 50px;
}

/* line 165, public/wp-content/themes/lesage/assets/src/scss/components/_modal.scss */
.modal-close-bottom {
  display: block;
  width: 100%;
  text-align: center;
  color: #707070;
  font-weight: 600;
  font-size: 1.8rem;
  margin-top: 30px;
  cursor: pointer;
}

/* line 1, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn {
  position: fixed;
  left: 110px;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 9999999;
  background-color: #090C10;
  display: flex;
  flex-flow: column nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 500ms ease;
}

@media (max-width: 1200px) {
  /* line 1, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn {
    left: 0;
    top: 90px;
  }
}

/* line 20, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn.active {
  opacity: 1;
  visibility: visible;
}

/* line 25, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn:before {
  content: "";
  background-image: url(../images/soil-dark-back.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 5;
  opacity: 0.4;
}

/* line 40, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__top-row {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  height: 90px;
  width: 100%;
  position: relative;
  z-index: 6;
}

@media (max-width: 1200px) {
  /* line 40, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__top-row {
    display: none;
  }
}

/* line 55, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__top-row .fpn-logo {
  width: 120px;
}

/* line 59, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__top-row .fpn-cta {
  display: flex;
  align-items: center;
}

/* line 64, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__top-row .fpn-email {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
}

@media (max-width: 768px) {
  /* line 64, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__top-row .fpn-email {
    display: none;
  }
}

/* line 73, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__top-row .fpn-email span {
  font-weight: 700;
  margin-right: 20px;
  font-size: 16px;
}

/* line 79, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__top-row .fpn-email a {
  font-size: 16px;
  color: #FAFAFA;
  transition: all 300ms ease;
}

/* line 84, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__top-row .fpn-email a:hover {
  color: #FF5205;
}

/* line 90, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__top-row .fpn-phone {
  position: relative;
  margin-right: 50px;
  margin-top: -1px;
}

/* line 95, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__top-row .fpn-phone span {
  position: absolute;
  top: 0;
  right: 0;
  transform: translateY(-100%);
  color: #707070;
}

/* line 103, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__top-row .fpn-phone a {
  font-weight: 600;
  color: #FAFAFA;
}

/* line 110, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  position: relative;
  z-index: 6;
}

@media (max-width: 768px) {
  /* line 110, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row {
    flex-flow: column wrap;
    align-items: flex-start;
  }
}

/* line 123, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper {
  width: 100%;
}

/* line 126, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper.desktop-fpn {
  display: flex;
  flex-flow: row nowrap;
  padding: 40px 100px;
}

@media (max-width: 1440px) {
  /* line 126, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row .fpn__wrapper.desktop-fpn {
    padding: 40px 60px;
  }
}

@media (max-width: 1024px) {
  /* line 126, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row .fpn__wrapper.desktop-fpn {
    padding: 40px;
  }
}

@media (max-width: 768px) {
  /* line 126, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row .fpn__wrapper.desktop-fpn {
    display: flex;
  }
}

@media (max-width: 768px) {
  /* line 126, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row .fpn__wrapper.desktop-fpn {
    display: none;
  }
}

/* line 148, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper.mobile-fpn {
  display: none;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  overflow: auto;
}

@media (max-width: 768px) {
  /* line 148, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row .fpn__wrapper.mobile-fpn {
    display: flex;
  }
}

/* line 160, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper.mobile-fpn .fpn-main-menu {
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  padding: 80px 30px 30px;
  width: 100%;
}

@media (max-width: 480px) {
  /* line 160, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row .fpn__wrapper.mobile-fpn .fpn-main-menu {
    padding: 20px 30px;
  }
}

/* line 172, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper.mobile-fpn .c-button, .fpn__bottom-row .fpn__wrapper.mobile-fpn .c-button--style-1, .fpn__bottom-row .fpn__wrapper.mobile-fpn .c-button--style-2, .fpn__bottom-row .fpn__wrapper.mobile-fpn .c-button--skewed, .fpn__bottom-row .fpn__wrapper.mobile-fpn .c-button--skewed-2, .fpn__bottom-row .fpn__wrapper.mobile-fpn .c-button--skewed-3, .fpn__bottom-row .fpn__wrapper.mobile-fpn .c-button--transparent {
  margin-top: auto;
  width: 100%;
  text-align: center;
  padding: 20px;
}

/* line 180, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* line 185, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper ul.fpn-main-menu {
  padding-right: 80px;
}

/* line 188, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper ul.fpn-main-menu li {
  margin: 0 0 20px;
}

@media (max-width: 480px) {
  /* line 188, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row .fpn__wrapper ul.fpn-main-menu li {
    margin: 0 0 10px;
  }
}

/* line 195, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper ul.fpn-main-menu li:first-of-type {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
}

@media (max-width: 768px) {
  /* line 195, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row .fpn__wrapper ul.fpn-main-menu li:first-of-type {
    flex-flow: column nowrap;
    align-items: flex-start;
  }
}

@media (max-width: 768px) {
  /* line 205, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row .fpn__wrapper ul.fpn-main-menu li:first-of-type i {
    display: none;
  }
}

/* line 212, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper ul.fpn-main-menu li a {
  color: #707070;
  font-size: 5rem;
  line-height: 5.5rem;
  font-weight: 700;
  font-family: "futura-pt";
  transition: all 300ms ease;
}

@media (max-width: 1340px) {
  /* line 212, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row .fpn__wrapper ul.fpn-main-menu li a {
    font-size: 4rem;
    line-height: 4.5rem;
  }
}

@media (max-width: 1024px) {
  /* line 212, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row .fpn__wrapper ul.fpn-main-menu li a {
    font-size: 3rem;
    line-height: 3.5rem;
  }
}

@media (max-width: 768px) {
  /* line 212, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row .fpn__wrapper ul.fpn-main-menu li a {
    color: white;
  }
}

@media (max-width: 480px) {
  /* line 212, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row .fpn__wrapper ul.fpn-main-menu li a {
    font-size: 2rem;
    line-height: 2.5rem;
  }
}

/* line 239, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper ul.fpn-main-menu li a:hover {
  color: #FAFAFA;
}

/* line 246, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper ul.fpn-services-list {
  padding-top: 0;
}

/* line 249, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper ul.fpn-services-list li {
  margin: 0 0 20px;
}

@media (max-width: 1200px) {
  /* line 249, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row .fpn__wrapper ul.fpn-services-list li {
    margin: 0 0 10px;
  }
}

@media (max-width: 768px) {
  /* line 249, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row .fpn__wrapper ul.fpn-services-list li {
    width: 100%;
  }
}

@media (max-width: 768px) {
  /* line 260, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row .fpn__wrapper ul.fpn-services-list li:last-of-type {
    margin-bottom: 0px;
  }
}

/* line 266, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper ul.fpn-services-list li a {
  color: #FAFAFA;
  font-size: 30px;
  line-height: 35px;
  font-weight: 700;
  font-family: "futura-pt";
}

@media (max-width: 1340px) {
  /* line 266, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row .fpn__wrapper ul.fpn-services-list li a {
    font-size: 25px;
    line-height: 30px;
  }
}

@media (max-width: 1024px) {
  /* line 266, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row .fpn__wrapper ul.fpn-services-list li a {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  /* line 266, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row .fpn__wrapper ul.fpn-services-list li a {
    font-size: 16px;
    line-height: 22px;
  }
}

/* line 287, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper ul.fpn-services-list li a i {
  color: #FF5205;
  font-size: 15px;
  transition: all 300ms ease;
}

/* line 294, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper ul.fpn-services-list li a:hover i {
  transform: translateX(10px);
}

/* line 303, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper .fpn-quick-links, .fpn__bottom-row .fpn__wrapper .fpn-quick-links--mobile {
  min-width: 175px;
}

/* line 306, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper .fpn-quick-links--mobile {
  display: none;
  flex-flow: column nowrap;
  align-items: flex-start;
  padding: 0px 30px;
  width: 100%;
}

@media (max-width: 768px) {
  /* line 306, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row .fpn__wrapper .fpn-quick-links--mobile {
    display: flex;
  }
}

/* line 318, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper .fpn-quick-links--mobile h3,
.fpn__bottom-row .fpn__wrapper .fpn-quick-links--mobile span.h3 {
  font-size: 25px;
  line-height: 30px;
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
}

@media (max-width: 480px) {
  /* line 318, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row .fpn__wrapper .fpn-quick-links--mobile h3,
  .fpn__bottom-row .fpn__wrapper .fpn-quick-links--mobile span.h3 {
    font-size: 18px;
    line-height: 24px;
  }
}

@media (max-width: 768px) {
  /* line 333, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row .fpn__wrapper .fpn-quick-links ul, .fpn__bottom-row .fpn__wrapper .fpn-quick-links--mobile ul {
    display: flex;
    flex-flow: row wrap;
  }
}

/* line 338, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper .fpn-quick-links ul li, .fpn__bottom-row .fpn__wrapper .fpn-quick-links--mobile ul li {
  margin: 0 0 10px;
}

@media (max-width: 768px) {
  /* line 338, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row .fpn__wrapper .fpn-quick-links ul li, .fpn__bottom-row .fpn__wrapper .fpn-quick-links--mobile ul li {
    margin: 0 30px 20px 0px;
  }
}

@media (max-width: 480px) {
  /* line 338, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row .fpn__wrapper .fpn-quick-links ul li, .fpn__bottom-row .fpn__wrapper .fpn-quick-links--mobile ul li {
    margin: 0 20px 10px 0px;
  }
}

/* line 349, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper .fpn-quick-links ul li a, .fpn__bottom-row .fpn__wrapper .fpn-quick-links--mobile ul li a {
  color: #FAFAFA;
  font-size: 20px;
  line-height: 25px;
  font-weight: 600;
  font-family: "futura-pt";
}

@media (max-width: 768px) {
  /* line 349, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row .fpn__wrapper .fpn-quick-links ul li a, .fpn__bottom-row .fpn__wrapper .fpn-quick-links--mobile ul li a {
    font-size: 16px;
    line-height: 22px;
  }
}

/* line 361, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper .fpn-quick-links ul li a i, .fpn__bottom-row .fpn__wrapper .fpn-quick-links--mobile ul li a i {
  color: #FF5205;
  font-size: 12px;
  transition: all 300ms ease;
}

@media (max-width: 768px) {
  /* line 361, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row .fpn__wrapper .fpn-quick-links ul li a i, .fpn__bottom-row .fpn__wrapper .fpn-quick-links--mobile ul li a i {
    display: none;
  }
}

/* line 372, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper .fpn-quick-links ul li a:hover i, .fpn__bottom-row .fpn__wrapper .fpn-quick-links--mobile ul li a:hover i {
  transform: translateX(10px);
}

/* line 381, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper .fpn-email--mobile {
  padding: 10px 30px;
}

/* line 384, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper .fpn-email--mobile span {
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  margin-right: 20px;
}

/* line 391, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper .fpn-email--mobile a {
  color: #FF5205;
}

/* line 396, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper .fpn-address {
  margin-top: 50px;
}

/* line 400, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper .fpn-address h4:not(:first-of-type) {
  margin-top: 40px;
}

/* line 405, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper .fpn-address--mobile {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  padding: 10px 30px;
}

@media (max-width: 480px) {
  /* line 405, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
  .fpn__bottom-row .fpn__wrapper .fpn-address--mobile {
    display: none;
  }
}

/* line 415, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper .fpn-address--mobile span {
  font-size: 16px;
  line-height: 22px;
  font-weight: 700;
  margin-right: 20px;
}

/* line 422, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper .fpn-address--mobile a {
  color: #FAFAFA;
  text-decoration: underline;
}

/* line 428, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper .fpn-address p {
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
}

/* line 434, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper .fpn-address a {
  color: #FF5205;
  text-decoration: underline;
}

/* line 440, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper h3,
.fpn__bottom-row .fpn__wrapper .h3 {
  font-size: 30px;
  line-height: 35px;
  font-weight: bold;
  display: block;
  margin-bottom: 10px;
}

/* line 449, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn__wrapper h4 {
  font-size: 20px;
  line-height: 25px;
  margin-bottom: 10px;
}

/* line 456, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn-col {
  flex-grow: 1;
}

/* line 459, public/wp-content/themes/lesage/assets/src/scss/components/_full-page-nav.scss */
.fpn__bottom-row .fpn-col.fpn-services-sub-menu {
  padding-right: 80px;
}

/* line 1, public/wp-content/themes/lesage/assets/src/scss/components/_burger.scss */
.burger-icon {
  width: 50px;
  height: 50px;
  border: 1px solid #707070;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

/* line 11, public/wp-content/themes/lesage/assets/src/scss/components/_burger.scss */
.burger-icon.mobile {
  display: none;
}

@media (max-width: 1200px) {
  /* line 11, public/wp-content/themes/lesage/assets/src/scss/components/_burger.scss */
  .burger-icon.mobile {
    display: flex;
  }
}

@media (max-width: 480px) {
  /* line 11, public/wp-content/themes/lesage/assets/src/scss/components/_burger.scss */
  .burger-icon.mobile {
    width: 40px;
    height: 40px;
  }
}

/* line 26, public/wp-content/themes/lesage/assets/src/scss/components/_burger.scss */
.burger-icon.active:before {
  transform: translateY(0) rotate(45deg);
}

/* line 31, public/wp-content/themes/lesage/assets/src/scss/components/_burger.scss */
.burger-icon.active:after {
  transform: translateY(0) rotate(-45deg);
}

/* line 36, public/wp-content/themes/lesage/assets/src/scss/components/_burger.scss */
.burger-icon:before, .burger-icon:after {
  content: '';
  width: 30px;
  height: 2px;
  background: #FAFAFA;
  position: absolute;
  transition: all 300ms ease;
}

@media (max-width: 480px) {
  /* line 36, public/wp-content/themes/lesage/assets/src/scss/components/_burger.scss */
  .burger-icon:before, .burger-icon:after {
    width: 20px;
  }
}

/* line 50, public/wp-content/themes/lesage/assets/src/scss/components/_burger.scss */
.burger-icon:before {
  transform: translateY(-4px);
}

/* line 54, public/wp-content/themes/lesage/assets/src/scss/components/_burger.scss */
.burger-icon:after {
  transform: translateY(4px);
}

/* line 1, public/wp-content/themes/lesage/assets/src/scss/components/_map.scss */
.c-map {
  position: relative;
  height: 560px;
}

/* line 5, public/wp-content/themes/lesage/assets/src/scss/components/_map.scss */
.c-map #map {
  position: absolute;
  inset: 0;
}
