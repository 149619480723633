.projets-wrapper {

    &.projets--home {
        background: $white;
        padding-bottom: 10rem;
        

        .projets__item {
            width: calc(33.333% - 20px);    
            margin-right: 20px;
            
            @include mq(large) {
                width: calc(50% - 20px);
            }

            @include mq(medium) {
                width: 100%;
                margin-top: 20px;
            }

            
            @include mq(small) {
                min-height: 300px;
            }

            &:last-of-type {
                @include mq(large) {
                    width:  calc(100% - 20px);
                    margin-right: 0;
                    margin-top: 20px;
                }
            }
        }

        .projets__listing {
            margin-right: -20px;
        }
    }

    .wrapper {
        padding: 10rem 0 0;
    }

    h2 {
        color: $white;

    }

    .projets__listing {
        display: flex;
        flex-flow: row wrap;

        h3 {
            font-size: 3.2rem;
            line-height: 3.4rem;
            margin-bottom: 10px;
        }
    }

    .projets__item {
        width: 50%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        min-height: 450px;
        display: flex;
        flex-flow: column wrap;
        justify-content: flex-end;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        color: $white;

        @include mq(large) {
            width: calc(50% - 20px);
        }

        @include mq(medium) {
            width: 100%;
            margin-top: 0;
        }

        
        @include mq(small) {
            min-height: 300px;
        }

        &:before {
            content: '';
            background: $black;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            opacity: 0.5;
            transition: all 200ms ease;
        }

        &:hover {

            .projets__details {
                margin-bottom: 0;
            }

            &:before {
                opacity: 0.7;
            }
        }

        .play-icon {
            position: absolute;
            top: 30px;
            left: 30px;
            border: 2px solid $white;
            border-radius: 100%;
            width: 30px;
            height: 30px;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;
            
            i {
                font-size: 10px;
                color: $white;
                margin-right: -2px;
            }
        }
    }

    .projets__content {
        padding: 30px 30px 20px;
        position: relative;
        z-index: 999;

        h3 {
            font-size: 2.5rem;
            line-height: 3rem;
            margin-bottom: 0;
            color: $white;
            text-transform: initial;

            @include mq(small) {
                font-size: 2rem;
                line-height: 2.5rem;
            }
        }
    }

    .projets__details {
        padding: 25px 30px;
        background: $orange;
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 1.4rem;
        margin-bottom: -70px;
        transition: all 200ms ease;
        position: relative;
        z-index: 999;

        i {
            font-size: 14px;
            margin-left: 10px; 
        }
    }

    .projets__cat {
        margin-bottom: 0;
        text-transform: initial;
        color: $white;
        font-size: 1.5rem;
        font-weight: 400;
    }
}