.faq {
    background: $white;
    color: $black;
    padding-bottom: 10rem; 
    
    .wrapper {
        padding: 10rem 0 5rem;
    }

    .content {
        padding-bottom: 0;
    }   
}