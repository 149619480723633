.commercial__banner {
    height: 100%;
    background-color: $black;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left:0;
        background: rgba($black, 0.7);
    }

    .container {
        height: 100%;

        h1 {
            text-align: center;
        }

        .pre-title {
            display: block;
            font-size: 2.4rem;
            line-height: 3rem;
            font-weight: 700;
            margin-bottom: 20px;
            color: $grey;
            text-align: center;

            @include mq(medium) {
                font-size: 2rem;
                line-height: 2.5rem;
            }
        }

        button {
            position: absolute;
            bottom: 6rem;
            left: 0;
            right: 0;
            margin: 0 auto;
            width: 40px;
            height: 40px;
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            align-items: center;
            outline: 0;
            border: 0;
            background: transparent;
            cursor: pointer;
        }
    }
}

.commercial-10-years {
    background: $white;
    color: $black;

    .wrapper {
        padding-bottom: 10rem;
    }
}

.commercial-carousel {
    background: $white;
    color: $black;

    .content {
        padding: 5rem 10rem;

        @include mq('medium') {
            padding: 5rem 0;
        }
    }

    .carousel__dots {

        ul {
            justify-content: flex-end;

            li {
                margin-right: 0;
                margin-left: 10px;

                &.slick-active {

                    button {
                        color: $orange;
                    }
                }
            }
        }
    }
}

.commercial-partenaires {
    padding: 10rem 0 20rem;
    background: $white;
    color: $black;

    .content {
        padding-bottom: 5rem;
    }

    &__list {
        list-style-type: none;
        padding: 0;
        margin: 0 -30px 0 0;
        display: flex;
        flex-flow: row wrap;
        align-items: center;
        
        @include mq(xsmall) {
            margin: 0;
            flex-flow: column wrap;
            align-items: center; 
        }

        li {
            width: calc(20% - 30px);
            margin-right: 30px;
            margin-bottom: 20px;

            @include mq(medium) {
                width: calc(33.333% - 30px);
            }

            @include mq(small) {
                width: calc(50% - 30px);
            }

            @include mq(xsmall) {
                width: 100%;
                margin-right: 0;
            }
        }
    }
}