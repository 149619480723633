.c-form {
	padding: 15rem 20rem 0;
	background-color: $white;
	color: #000;

	@media (max-width: 1300px) {
		padding: 15rem;
	}

	@media (max-width: 768px) {
		padding: 10rem 2rem;
	}

	h5 {
		margin-bottom: 30px;
		opacity: 0.5;
		color: #1d1d1d;
	}
}

.c-form_section {
	padding-top: 5rem;

	p {
		color: $black;
		font-size: 18px;
		font-weight: 400;
	}
}

.form-wrapper {
	@media (max-width: 1300px) {
		padding-right: 0;
	}

	.form__row {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		width: 100%;
		margin: 60px 0;
		gap: 60px;

		&:first-of-type {
			margin-top: 0;
		}

		@media (max-width: 768px) {
			flex-direction: column;
		}

		&.no-translate {
			label {
				transform: none;
			}

			.wpcf7-form-control-wrap:after {
				display: none;
			}
		}

		.wpcf7-form-control-wrap {
			&.active {
				&:after {
					height: 3px;
					background: $orange;
				}
			}
		}

		.wpcf7-form-control-wrap {
			position: relative;
			display: block;
			width: 100%;
			&.message {
				&:after {
					display: none;
				}
			}
			&:after {
				content: "";
				position: absolute;
				right: 0;
				bottom: 0;
				left: 0;
				height: 2px;
				background: rgba($grey, 0.2);
				transition: all 300ms ease;
			}
			textarea {
				padding: 20px;
				border: 2px solid rgba($grey, 0.2);
				resize: none;
			}

			&.domaineDuProjet,
			&.typeDeProjet {
				position: relative;

				&:before {
					content: "";
					position: absolute;
					right: 30px;
					bottom: 30px;
					display: block;
					width: 10px;
					height: 10px;
					transform: rotate(45deg);
					border-right: 2px solid $orange;
					border-bottom: 2px solid $orange;
					pointer-events: none;
				}
			}
		}

		label {
			display: block;
			width: 45%;
			transform: translateY(30px) scale(1);
			transform-origin: top left;
			transition: all 300ms ease;
			font-family: $main-font;
			font-size: 2rem;
			font-weight: 700;

			@media (max-width: 768px) {
				width: 100%;
			}
			&.active {
				transform: translateY(0) translateX(0px) scale(0.8);
				color: $grey-dark;
			}
		}
		&.label-textarea {
			label {
				transform: translateY(30px) translateX(12px) scale(1);
				&.active {
					transform: translateY(0) translateX(0px) scale(0.8);
				}
			}
		}

		.c-text-field {
			width: 45%;

			@media (max-width: 930px) {
				width: 100%;
			}
		}

		input:not([type="submit"]),
		textarea {
			width: 100%;
			padding: 10px 0;
			outline: 0;
			border: 0;
			background: transparent;
			font-family: $main-font;
			font-size: 1.8rem;
			font-weight: 400;
			line-height: 2.4rem;
			appearance: none;

			&::placeholder {
				color: #000;
			}
		}

		textarea {
			height: 150px;
		}

		select {
			width: 100%;
			margin-top: 20px;
			padding: 20px 30px;
			outline: 0;
			border: 2px solid rgba($grey, 0.2);
			background: transparent;
			font-family: $main-font;
			font-size: 1.8rem;
			font-weight: 700;
			line-height: 2.4rem;
			appearance: none;
		}

		input[type="file"],
		input[type="date"] {
			margin-top: 20px;
			padding: 20px 30px;
			border: 2px solid rgba($grey, 0.2);
		}

		&.-single {
			flex-direction: row;
			justify-content: center;
			margin-bottom: 0;
		}
	}
}

.wpcf7-response-output {
	margin: 2em 0.5em 0em !important;
}

.contact__form {
	input,
	textarea {
		color: $white;
	}
}

.form__checkbox {
	.wpcf7-checkbox {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;

		@media (max-width: 768px) {
			flex-direction: column;
		}

		.wpcf7-list-item {
			display: flex;
			width: 45%;
			margin-left: 0;
			padding: 2rem 0;
			font-size: 20px;

			@media (max-width: 768px) {
				width: 100%;
			}

			input[type="checkbox"] {
				margin-bottom: 15px;
				margin-left: 0;
			}

			.wpcf7-list-item-label {
				padding-left: 15px;
			}
		}

		label {
			position: relative;
			cursor: pointer;
		}

		input[type="checkbox"] {
			position: relative;
			visibility: hidden;
		}

		input[type="checkbox"] + span:before {
			content: "";
			position: absolute;
			top: 0px;
			display: block;
			width: 18px;
			height: 18px;
			border: 1px solid $orange;
		}

		input[type="checkbox"] + span:after {
			content: url("../images/check.svg");
			position: absolute;
			top: 0px;
			left: 0px;
			display: block;
			width: 10px;
			height: 10px;
			visibility: hidden;
			border-radius: 100%;
			color: #fff;
			font-size: 16px;
		}

		input[type="checkbox"]:checked + span:before {
			background: $orange;
		}

		input[type="checkbox"]:checked + span:after {
			visibility: visible;
		}
	}
}

.form__date {
	display: flex;
	justify-content: space-between;
	margin-top: 2rem;

	@media (max-width: 1000px) {
		flex-direction: column;
	}

	.date {
		display: flex;
		flex-direction: column;
		width: 45%;

		@media (max-width: 768px) {
			width: 100%;
		}

		p > span {
			input[type="date"] {
				padding: 17px 20px;

				&::-webkit-datetime-edit {
					font-family: $main-font;
					font-size: 18px;
				}

				&::-webkit-calendar-picker-indicator {
					width: 23px;
					height: 23px;
					margin-left: 0.5rem;
					opacity: 1;
					border-radius: 50%;
					color: rgba(0, 0, 0, 0);
					background-image: url(../images/calendar.svg);
					cursor: pointer;
				}
			}
		}
	}

	.file {
		width: 45%;

		@media (max-width: 768px) {
			width: 100%;
		}
	}

	span {
		width: 45%;

		@media (max-width: 1000px) {
			width: 100%;
		}

		input {
			width: 100%;
			padding: 20px;
			border: 1px solid $grey-dark;
			background: transparent;
		}
	}

	.c-file {
		display: flex;

		.c-file_label {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 30%;
			background-color: $orange;

			@media (min-width: 769px) and (max-width: 1200px) {
				width: 80%;
			}
		}
	}
}

.form__textarea {
	textarea {
		width: 100%;
		padding: 15px;
		border: none;
		background-color: #f5f5f5;
		font-family: "futura-pt";
		font-size: 18px;

		&::placeholder {
			color: $black;
		}
	}
}

.submit_btn {
	position: relative;

	input[type="submit"] {
		padding: 15px 170px;
		transform: skewX(-25deg);
		outline: 0;
		border: 1px solid $orange;
		background-color: $orange;
		cursor: pointer;
		transition: 0.2s;
		text-indent: -99999px;
		font-family: "futura-pt";
	}

	button {
		position: absolute;
		top: 15px;
		left: 42%;
		border: none;
		background-color: transparent;
		color: $white;
		font-family: "futura-pt";
		font-size: 1.5rem;
		font-weight: 500;
		pointer-events: none;
	}
}

.wpcf7-spinner {
	display: none;
}

.c-file {
	input,
	.file {
		position: absolute;
		width: 1px;
		height: 1px;
		margin: -1px;
		padding: 0;
		border: 0;
		overflow: hidden;
		white-space: nowrap;
		clip: rect(0, 0, 0, 0);
	}
}

.c-file_btn {
	width: 100%;
	padding: 20px 15px;
	color: $white;
	cursor: pointer;
	text-align: center;
}

.c-file_name {
	display: block;
	max-width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.c-file_size {
	white-space: nowrap;
}

.c-file_clear {
	cursor: pointer;
}

.c-file {
	input,
	.file {
		position: absolute;
		width: 1px;
		height: 1px;
		margin: -1px;
		padding: 0;
		border: 0;
		overflow: hidden;
		white-space: nowrap;
		clip: rect(0, 0, 0, 0);
	}
}

.c-file_document {
	display: flex;
	align-items: center;
	width: 100%;
	border: 1px solid $grey-dark;

	.c-file_meta {
		width: 100%;
		margin-left: 15px;
	}
}

.c-file_name {
	display: block;
	max-width: 100%;
	color: $grey;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}

.c-file_size {
	display: none;
	white-space: nowrap;
}

.c-file_clear {
	display: flex;
	justify-content: flex-end;
	margin-right: 15px;
	cursor: pointer;

	&::after {
		content: url("../images/clear.svg");
	}
}

.wpcf7-not-valid-tip {
	position: absolute;
	bottom: -4px;
	transform: translateY(100%);
}
