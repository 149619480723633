.single-projet-banner {
    p {
        max-width: 750px;
        margin-bottom: 0;
        margin-top: 20px;
    }

    .category {
        color: $grey;
        font-weight: 700;
        font-size: 1.8rem;
        line-height: 2.4rem;
        display: block;
        margin-bottom: 10px;
    }
}

.single-projet {

    &__bar {
        display: flex;
        flex-flow: row wrap;
        margin: 15rem -20px 5rem 0;

        &-item {
            width: calc(25% - 20px);
            margin-right: 20px;
            display: flex;
            flex-flow: column wrap;
            position: relative;
            padding: 0px 20px 120px;
            display: flex;
            flex-flow: column wrap;
            align-items: center;

            @include mq(large) {
                width: calc(50% - 20px);
                margin-bottom: 100px;
            }

            &:first-of-type {

                &:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 100%;
                    width: 2500px;
                    background: $grey;
                    opacity: 0.1;
                    transform: skewX(-10deg);

                    @include mq(large) {
                        display: none;
                    }
                }
            }

            span {
                display: block;

                &.item-title {
                    margin-top: -60px;
                    text-align: center;
                }

                &.item-stat {
                    font-size: 80px;
                    line-height: 80px;
                    font-weight: 700;
                }

                &.item-subtitle {
                    font-weight: bold;
                    color: $orange;
                }

                &.play-icon {
                    position: absolute;
                    top: 0;
                    right: 0;
                    bottom: 0;
                    left: 0;
                    width: 50px;
                    height: 50px;
                    margin: auto;
                    border: 2px solid $white;
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100%;

                    i {
                        color: $white;
                        margin-right: -2px;
                        margin-bottom: -1px;
                    }
                }

            }

            &.video {

                &:before {
                    background: $orange;
                    opacity: 1;
                }
            }

            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                background: $grey;
                opacity: 0.1;
                transform: skew(-10deg);
                z-index: -1;
            }
        }
    }

    &__slider {
        background: $black;
        padding-bottom: 50px;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 200px;
            background: $white;
        }

        .carousel__dots {

            ul {
                justify-content: flex-end;

                @include mq(small) {
                    justify-content: flex-start;
                }

                li {
                    margin-right: 0;
                    margin-left: 10px;

                    @include mq(small) {
                        margin-right: 10px;
                        margin-left: 0;
                        width: calc(33.33% - 10px);
                        margin-bottom: 20px;
                    }
                    
                    button {
                        @include mq(small) {
                            width: 100%;
                        }
                    }

                    &.slick-active {

                        button {
                            color: $orange;
                        }
                    }
                }
            }


        }
    }
    
}