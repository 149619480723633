/* =======================================================================
Responsive
========================================================================== */
$breakpoints: (
	'xsmall': 		(max-width: 375px),
	'small': 		(max-width: 480px),
	'medium': 		(max-width: 768px),
	'large': 		(max-width: 1024px),
	'xlarge': 		(max-width: 1200px)
);

/* =======================================================================
Font-face
========================================================================== */

@import url('https://use.typekit.net/aaf0krl.css');

/* =======================================================================
Variables
========================================================================== */

$main-font: 'futura-pt';

$orange: #FF5205;
$black: #090C10;
$grey: #707070;
$grey-dark: #373B41;
$white: #FAFAFA;
$white-transparent: rgba($white, 0.4);

/* =======================================================================
z-index
========================================================================== */
$z-front: 2;
$z-middle: 1;
$z-back: -1;
