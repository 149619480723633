.about {

    &-mission {
        padding-top: 10rem;

        .wrapper {
            padding-bottom: 0;
        }
    }

    &-ten-years {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        position: relative;
        overflow: hidden;
        
        &:before {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: radial-gradient(ellipse at center, rgba($black,0) 0%,rgba($black,1) 100%);
        }

        .wrapper {
            padding: 0;
        }

        .content {
            text-align: center;
            position: relative;            

            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                height: 200px;
                background: $white;
                opacity: 0.1;
                width: 1500px;                
                margin: auto 0;
                transform: skewX(8deg);             
            }

            &:before {
                left: calc( 100% - 5rem);
            }

            &:after {
                right: calc( 100% - 5rem);            
            }
        }
    }

    &-carousel {
        padding-top: 10rem;

        .container {
            padding-left: 0;

            @include mq(large) {
                padding-left: 30px;
            }
        }

        .carousel__dots {

            ul {
                justify-content: flex-end;

                li {
                    margin-right: 0;
                    margin-left: 8px;
                }
            }
        }
    }

    &-team {
        background: $white;
        color: $black;

        .wrapper {
            padding-bottom: 10rem;
        }

        .content {
            padding: 0;
        }

        .has-orange-separator {

            &:before {
                border-color: $white;
            }
        }

        .skewed-images {
            width: 100%;
            max-width: 1100px;
            margin-left: auto;
            margin-right: -50px;
            padding-bottom: 10rem;
            position: relative;
            z-index: 3;

            @include mq(large) {
                margin-right: 0;
                padding-bottom: 2rem;
            }
        }
    }

    &-testimonials {
        background: $white;
        color: $black;

        .content {
            padding-bottom: 20rem;
            width: 100%;
        }

        .has-orange-separator {

            &:before {
                border-color: $white;
            }
        }
    }
}