#wpadminbar {
	z-index: 9999999;
}

html {
	font-size:10px;
	overflow-x:hidden;
}

body {
	background-color: $black;
	font-family: $main-font;
	font-size: 1.5rem;
	line-height: 2rem;
	font-weight: 500;	
	color: $white;
	margin:0px;
	
	&.no-scroll {
		//overflow: hidden;
		//position: relative;
		//height: 100%;
	}

	&.black-bg {
		
		.page-header {
			background: $black;
			border-bottom: 1px solid $grey;
		}
	}
}

html, body {
	height: 100%;
}

section {
	position: relative;
}

html, body {
	height: 100%;
}

* {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

img {
	max-width: 100%;
}

textarea {
	resize: vertical;
}

.h2 {
	font-weight: 700;
}

h1, h2, .h2, h3, h4, h5, h6 {
	line-height: 1.1em;
	margin-top: 0;
	margin-bottom: 20px;

	span {
		color: $orange;
	}

	&.has-separator {
		position: relative;
		margin-bottom: 0;

		&:after {
			content: '';
			display: block;			
			width: 25px;
			height: 2px;
			background: $white;
			margin: 20px 0;
		}
	}
	
	&.has-orange-separator {
		position: relative;
		margin-bottom: 0;

		&:before {
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			left: 24px;
			z-index: 3;
			width: 6px;
			height: 3px;
			border-left: 2px solid $black;
			border-right: 2px solid $black;
			background: transparent;
			transform: skewX(-10deg);
		}

		&:after {
			content: '';
			display: block;			
			width: 40px;
			height: 3px;
			background: $orange;
			margin: 20px 0;
			transform: skewX(-10deg);
		}
	}

	&.has-orange-separator--white {
		position: relative;
		margin-bottom: 0;

		&:before {
			content: '';
			display: block;
			position: absolute;
			bottom: 0;
			left: 24px;
			z-index: 3;
			width: 6px;
			height: 3px;
			border-left: 2px solid $white;
			border-right: 2px solid $white;
			background: transparent;
			transform: skewX(-10deg);
		}

		&.bottom-20 {

			&:before {
				bottom: 20px;
			}
			
		}

		&:after {
			content: '';
			display: block;			
			width: 40px;
			height: 3px;
			background: $orange;
			margin: 20px 0;
			transform: skewX(-10deg);
		}
	}

}

h1,
h4.h1 {
	font-size: 7.5rem;
	text-transform: uppercase;

	@include mq('xlarge') {
		font-size: 6.5rem;
	}

	@include mq('large') {
		font-size: 5.5rem;
	}

	@include mq('medium') {
		font-size: 5rem;
	}

	@include mq('small') {
		font-size: 4rem;
	}

	@include mq('xsmall') {
		font-size: 3rem;
	}
}

h2,
.h2,
h4.h2
h3.h2,
h5.h2 {
	font-size: 5.6rem;
	line-height: 1.2em;
	text-transform: uppercase;

	@include mq('xlarge') {
		font-size: 5rem;
	}

	@include mq('large') {
		font-size: 4.5rem;
	}

	@include mq('medium') {
		font-size: 3.5rem;
	}

	@include mq('small') {
		font-size: 3rem;
	}

	@include mq('xsmall') {
		font-size: 2.5rem;
	}
}

h3,
h2.h3 {
	font-size: 4.6rem;

	@include mq('xlarge') {
		font-size: 4.2rem;
	}

	@include mq('large') {
		font-size: 3.8rem;
	}

	@include mq('medium') {
		font-size: 3.2rem;
	}

	@include mq('small') {
		font-size: 2.8rem;
	}
}

h4 {
	font-size: 2.5rem;
	line-height: 1.5em;

	@include mq('xlarge') {
		font-size: 2.2rem;
	}

	@include mq('large') {
		font-size: 2rem;
	}
}

h5 {
	font-size: 2.1rem;

	&:not(:first-of-type) {
		margin-top: 80px;
	}
}

h6 {
	font-size: 1.8rem;	
}

p {
	font-size: 2rem;
	line-height: 1.8em;
	margin-top: 0;
	margin-bottom: 30px;

	@include mq('small') {
		font-size: 1.6rem;
	}
}

a {
	text-decoration: none;
}