.accordion {
    list-style-type: none;
    padding: 0;
    margin: 6rem 0;

    &--careers {

        h3 {
            font-size: 35px;
            line-height: 40px;

            @include mq(medium) {
                font-size: 25px;
                line-height: 30px;
            }
            
            &:after {
                content: '';
                display: block;
                width: 40px;
                height: 1px;
                border-bottom: 5px solid $orange;
                margin-top: 20px;
            }
            
            a {
                color: $black;   
            }
        }

        h4 {
            font-size: 20px;
            line-height: 25px;
        }

        p {
            @include mq(medium) {
                font-size: 16px;
                line-height: 24px;
            }
        }

        & > li {
            border: 1px solid rgba($grey, 0.5);
            padding: 50px !important;

            @include mq(medium) {
                padding: 40px 30px !important;
            }

            @include mq(small) {
                border-top: 0px;
                border-left: 0px;
                border-right: 0px;
                padding: 0px 0px 40px !important;
            }

            &:after {
                display: none;
            }

            ul {
                list-style-type: none;
                padding: 0 0 0 30px;

                li {
                    padding: 0px;
                    margin: 15px 0;
                    font-size: 16px;
                    line-height: 24px;

                    &:before {
                        content: '•';
                        color: $orange;
                        margin-left: -26px;
                        margin-right: 10px;
                    }

                    &:after {
                        display: none;
                    }
                }
            }
        }
    }

    li {
        color: $black;
        position: relative;
        padding-bottom: 30px;
        margin-bottom: 30px;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 2px;
            background: rgba($grey, 0.2);
            transition: all 300ms ease;
        }

        &.active {

            &:after {
                height: 3px;
                background: $orange;
            }

            .accordion-more-details {
                .plus-icon {
                    &:after {
                        transform: rotate(0deg);
                    }
                }
            }

        }

        h4 {
            
            a {
                color: $black;
            }
        }
        
        div.accordion-content {
            display: none;

            p {
                color: $grey-dark;
                
            }
        }

        .accordion-more-details {
            display: flex;
            flex-flow: row wrap;
            align-items: center;
            cursor: pointer;
            font-weight: 600;

            .plus-icon {
                display: flex;
                flex-flow: row wrap;
                justify-content: center;
                align-items: center;
                position: relative;
                width: 10px;
                height: 10px;
                margin-left: 10px;

                &:before,
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    background: $orange;
                }

                &:after {
                    transform: rotate(90deg);
                    transition: all 300ms ease;
                }
            }
        }
    }

    &__close {

    }
}